import moment from 'moment'
import React, {useState} from 'react'
import TextInput from '../../../components/TextInput'

import { FiCheck } from 'react-icons/fi'

import RecordPayment from './RecordPayment'
import AddDueDate from './AddDueDate'

function JobSupportPaymentDetails({handleIncomeData, incomeInformation, handleInstallmentData, installmentInformation, type, error}) {
    const [isUpdate, setIsUpdate] = useState(type==='update');
    const [dueDateInformation, setDueDateInformation] = useState({})

    const handleInstallmentInformation = (event) => {
        setDueDateInformation(prevState => ({...prevState, [event.target.name]: event.target.value}))
    }

    const saveDueDate = (info) => {
            handleInstallmentData(info)
            setDueDateInformation({})
    }
    
    const getInstallmentValue = (installment) => {
        let value = "";
        if(installment?.target_amount)
            value += `Due: ${installment.target_amount}$ `
        if(installment?.paid_amount)
            value += `Paid ${installment.paid_amount}$`
        return value
    }

    const paidIcon = (installment) => (
        (installment?.paid_amount !== undefined && installment?.paid_amount !== null) && <FiCheck style={{position:"absolute", top:"1rem", right:"1rem", zIndex: "10"}} size="20" color="green"/> 
    )
    
    return (
        <div className="information-block">
            <p className="information-block-header">Payment Details</p>
           { console.log(incomeInformation)}
            <div className="information-row">
                {/* { isUpdate ? 
                    <RecordPayment recordInstallment={saveDueDate} installmentupdate={() => setIsUpdate(false)} />
                        : 
                    <AddDueDate recordInstallment={saveDueDate} installmentupdate={() => setIsUpdate(true)} type={type}/>
                } */}
                <div>
                <TextInput id="first_installment" name="first_installment" placeholder="First Installment" value={incomeInformation.first_installment} type="number" 
                        error={error.first_installment || false} errorMessage={error.first_installment && error.first_installment[0]}  onChangeHandler={handleIncomeData} size="small" />
                <TextInput id="second_installment" name="second_installment" placeholder="Second Installment" value={incomeInformation.second_installment} type="number" 
                        onChangeHandler={handleIncomeData} size="small" />
                <TextInput id="third_installment" name="third_installment" placeholder="Third Installment" value={incomeInformation.third_installment} type="number" 
                        onChangeHandler={handleIncomeData} size="small" />
                </div>
                <div>
                <TextInput id="fourth_installment" name="fourth_installment" placeholder="Fourth Installment" value={incomeInformation.fourth_installment} type="number" 
                        onChangeHandler={handleIncomeData} size="small" />
                <TextInput id="first_payment_date" name="first_payment_date" placeholder="First Installment Date" value={incomeInformation.first_payment_date} type="text" 
                        onChangeHandler={handleIncomeData} size="small" />
                <TextInput id="second_payment_date" name="second_payment_date" placeholder="Second Installment Date" value={incomeInformation.second_payment_date} type="text" 
                        onChangeHandler={handleIncomeData} size="small" />
                
                </div>
                <div>
                <TextInput id="third_payment_date" name="third_payment_date" placeholder="Third Installment Date" value={incomeInformation.third_payment_date} type="text" 
                        onChangeHandler={handleIncomeData} size="small" />
                <TextInput id="fourth_payment_date" name="fourth_payment_date" placeholder="Fourth Installment Date" value={incomeInformation.fourth_payment_date} type="text" 
                        onChangeHandler={handleIncomeData} size="small" />
                        <TextInput id="deposit_payment_date" name="deposit_payment_date" placeholder="Deposit Payment Date" value={incomeInformation.deposit_payment_date} type="text" 
                        onChangeHandler={handleIncomeData} size="small" />
                </div>
                <div>
                    <TextInput id="hourly_rate" name="hourly_rate" placeholder="Hourly Rate" value={incomeInformation.hourly_rate} type="number" 
                        error={error.hourly_rate || false} errorMessage={error.hourly_rate && error.hourly_rate[0]} onChangeHandler={handleIncomeData} size="small"/>
                </div>
                <div>
                    <TextInput id="gross_salary" name="gross_salary" placeholder="Gross Salary" value={incomeInformation.gross_salary} type="number" 
                        error={error.gross_salary || false} errorMessage={error.gross_salary && error.gross_salary[0]} onChangeHandler={handleIncomeData} size="small"/>
                </div>
                <div>
                    <TextInput id="qatek_percentage" name="qatek_percentage" placeholder="QATEK %" value={incomeInformation.qatek_percentage} type="number"
                        error={error.qatek_percentage || false} errorMessage={error.qatek_percentage && error.qatek_percentage[0]} onChangeHandler={handleIncomeData} size="small"/>
                </div>
                <div>
                    <TextInput id="target_amount" name="target_amount" placeholder="Target Amount" value={incomeInformation.target_amount} type="number" 
                        error={error.target_amount || false} errorMessage={error.target_amount && error.target_amount[0]} onChangeHandler={handleIncomeData} size="small"/>
                </div>
                <div>
                    <TextInput id="refund_amount" name="refund_amount" placeholder="Refund Amount" value={incomeInformation.refund_amount} type="number" 
                        error={error.refund_amount || false} errorMessage={error.refund_amount && error.refund_amount[0]} onChangeHandler={handleIncomeData} size="small"/>
                </div>
                <div>
                    <TextInput id="paid_amount" name="paid_amount" placeholder="Paid Amount" value={incomeInformation.paid_amount} type="number" 
                        error={error.paid_amount || false} errorMessage={error.paid_amount && error.paid_amount[0]} onChangeHandler={handleIncomeData} size="small"/>
                </div>
                <div>
                    <TextInput id="remaining_amount" name="remaining_amount" placeholder="Remaining" value={incomeInformation.remaining_amount} 
                        error={error.remaining_amount || false} errorMessage={error.remaining_amount && error.remaining_amount[0]} type="number" onChangeHandler={handleIncomeData} size="small"/>
                </div>
                <div>
                    <TextInput id="deposit" name="deposit" placeholder="Deposit (Non Refundable)" value={incomeInformation.deposit} type="text" size="large" 
                    onChangeHandler={handleIncomeData} error={error.deposit || false} errorMessage={error.deposit && error.deposit[0]}/>
                </div>
                { installmentInformation.first_installment && <div>
                    <TextInput id="first_installment" name="first_installment"  readOnly={true}
                        placeholder={`1st Installment Fee (Due: ${installmentInformation.first_installment !== undefined ? moment(installmentInformation.first_installment.due_date).format("MMM Do YYYY") :""}) $`} 
                        value={getInstallmentValue(installmentInformation.first_installment)} type="text" onChangeHandler={handleIncomeData} size="large" icon={() => paidIcon(installmentInformation.first_installment)}
                        healperText={installmentInformation.first_installment.paid_date && `Paid on - ${moment(installmentInformation.first_installment.paid_date).format("MMM Do YYYY")}`}/>
                </div>}
                {installmentInformation.second_installment && <div>
                    <TextInput id="second_installment" name="second_installment"  readOnly={true}
                        placeholder={`2nd Installment Fee (Due: ${installmentInformation.second_installment !== undefined ? moment(installmentInformation.second_installment.due_date).format("MMM Do YYYY") :""}) $`} 
                        value={getInstallmentValue(installmentInformation.second_installment)} type="text" onChangeHandler={handleIncomeData} size="large" icon={() => paidIcon(installmentInformation.second_installment)}
                        healperText={installmentInformation.second_installment.paid_date && `Paid on - ${moment(installmentInformation.second_installment.paid_date).format("MMM Do YYYY")}`}/>
                </div>}
                {installmentInformation.third_installment && <div>
                    <TextInput id="third_installment" name="third_installment"  readOnly={true}
                    placeholder={`3rd Installment Fee (Due: ${installmentInformation.third_installment !== undefined ? moment(installmentInformation.third_installment.due_date).format("MMM Do YYYY") :""}) $`} 
                        value={getInstallmentValue(installmentInformation.third_installment)} type="text" onChangeHandler={handleIncomeData} size="large" icon={() => paidIcon(installmentInformation.third_installment)}/>
                </div>}
            </div>
        </div>
    )
}

export default JobSupportPaymentDetails