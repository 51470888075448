import React, {useState, useEffect, useRef} from 'react'
import './comment.css'
import { useDispatch, useSelector } from 'react-redux'

import FilledRoundedButton from "../../components/Button/FilledRoundedButton"

import {FiSend, FiMoreVertical} from "react-icons/fi"
import {userTime} from "../../utility"
import {getComments, postComment, deleteComment} from "../../actions/comments"

function Comment({id, query, query_params, log=false}) {
    const dispatch = useDispatch()
    const ref = useRef(null)
    const user = useSelector((state) => state.auth.user)

    const [comments, setComments] = useState([])
    const [newComment, setNewComment] = useState("")
    const [commentActionOpen, setCommentActionOpen] = useState(false)

    const scrollToBottom = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {scrollToBottom()}, [comments])

    useEffect(() => {
        dispatch(getComments(`${query}/${id}/comments/`))
        .then(response =>{
            if(response.status === 200){
                setComments(response.data)
            }
        })
    }, [id, query])

    const handleCommentSubmit = (event) => {
        event.preventDefault()
        dispatch(postComment(`${query}/${id}/comments/`, {message: newComment}))
        .then((response) => {
            if(response.status === 201)
                setComments([...comments, response.data])
        })
        setNewComment("")
    }

    const handleDeleteComment = () => {
        dispatch(deleteComment(commentActionOpen))
        .then((response) => {
            if(response.status === 200){
                setComments(prevState => prevState.filter(comment => comment.id !== commentActionOpen))
                setCommentActionOpen(false)
            }
        })
    }

    return (
        <div className="container">
            <div className="chat_box">
                <div className="head">
                    <div className="user">
                        <div className="name">Comments</div>
                    </div>
        
                </div>
                <div className="body">
                    {comments.map(comment => (
                        <div className={comment.user === user.email ? "incoming" : "outgoing"}>
                            <div className="bubble">
                                <div style={{display: 'flex', justifyContent: 'space-between', gap:'1rem'}}>
                                    <span className="chat-log_author">
                                        {comment.user.split("@")[0]}
                                    </span>
                                    <span className="chat-log_author">{userTime(comment.time, 'DD MMM YYYY')}</span>
                                    {comment.user === user.email && <FiMoreVertical style={{cursor:"pointer"}} onClick={() => setCommentActionOpen(prevState => !prevState ? comment.id :false)}/>}
                                </div>
                                <p>{comment.message}</p>
                            </div>
                        </div>
                    ))}
                
                    {/* <div className="typing">
                        <div className="bubble">
                            <div className="ellipsis dot_1"></div>
                            <div className="ellipsis dot_2"></div>
                            <div className="ellipsis dot_3"></div>
                        </div>
                    </div> */}
                </div>

                 {!log && (commentActionOpen ? <div className="foot msg-action">
                    <FilledRoundedButton name="Delete" color="red" size="small" onClickHandler={handleDeleteComment}/>
                </div>
                :
                <div className="foot" ref={ref}>
                    <input type="text" className="msg msg-input" value={newComment}  onKeyPress={event => event.key==="Enter" && handleCommentSubmit(event)} onChange={event => setNewComment(event.target.value)} placeholder="Type a comment..." />
                    <button className="send-btn" type="submit" onClick={handleCommentSubmit}><FiSend size={20}/></button>
                </div>)}
            </div>
	</div>

  )
}

export default Comment