import axios from "axios";
import { getHeader } from "./../utility"
import { ADD_MESSAGE } from './../actions/types'

export const getCashFlow = (year) => (dispatch, getState) => {
    let config = getHeader(getState)
    if (year) config['params'] = { year: year }

    return axios
        .get('/transaction/cash-flow/', config)
        .then(response => {
            if (response.status === 200) {
                return { status: response.status, data: response.data }
            }
        })
        .catch(err => {
            console.error("##### Failed to load cash flow data #####")
        })
}

export const expenseBreakdown = (params = { range: "quarterly" }) => (dispatch, getState) => {
    let config = getHeader(getState)
    config['params'] = params
    return axios
        .get('/transaction/expense-breakdown/', config)
        .then(response => {
            if (response.status === 200) {
                return { status: response.status, data: response.data }
            }
        })
        .catch(err => {
            console.error("##### Failed to load expense breakdown #####")
        })

}

export const getOverdueList = (page) => (dispatch, getState) => {
    let config = getHeader(getState)
    config['params'] = { page: page }

    return axios
        .get('/transaction/incoming-amount/', config)
        .then(response => {
            if (response.status === 200) {
                return { status: response.status, data: response.data }
            }
        })
        .catch(err => {
            console.error("##### Failed to load overdue data #####")
        })
}