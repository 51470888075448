import axios from 'axios';

const cache = {
    allData: null
};

export async function fetchAllData() {
    try {
        // Check if data is already cached
        if (cache.allData) {
            return cache.allData;
        }

        const allData = {};

        // Fetch data from the EMS API
        const transactionResponse = await axios.get('https://ems-api.qatekinternal.com/transaction/', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
                'Content-Type': 'application/json',
            },
        });
        const allTransactions = transactionResponse.data;
        allData.allTransactions = allTransactions;

        // Fetch data from the job support API
        const jobSupportResponse = await axios.get('https://stms.qatekinternal.com/job-support/partner-api/job-support-list/', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
                'Content-Type': 'application/json',
            },
        });
        allData.jobSupportData = jobSupportResponse.data.records;

        // Fetch data from the enrolled API
        const enrolledResponse = await axios.get('https://stms.qatekinternal.com/records/partner-api/enrolled-student-list/', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
                'Content-Type': 'application/json',
            },
        });
        allData.enrolledData = enrolledResponse.data.records;

        // Fetch data from the other income API
        const otherIncomeResponse = await axios.get('https://ems-api.qatekinternal.com/income/other-incomes/', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
                'Content-Type': 'application/json',
            },
        });
        allData.otherIncomeData = otherIncomeResponse.data;

        // Cache the fetched data
        cache.allData = allData;

        return allData;
    } catch (error) {
        console.error('Error fetching all data:', error);
        throw error;
    }
}


