import React, { useState, useEffect } from 'react'
import "./roleManagement.css"

import { FiSquare, FiCheckSquare } from "react-icons/fi"
import Modal from '../../../components/Modal'
import TextInput from '../../../components/TextInput'
import { getRoles, updateRole, createNewRole, deleteRole } from './../../../actions/role'
import { ADD_MESSAGE } from './../../../actions/types'

import { useDispatch } from 'react-redux'

import FilledRoundedButton from "./../../../components/Button/FilledRoundedButton"
import Accordion from "./../../../components/Accordion"
import ButtonOulined from "./../../../components/Button/ButtonOulined"
import Dropdown from "../../../components/Dropdown"


function RoleManagement({ role }) {
    const dispatch = useDispatch()
    const [roles, setRoles] = useState([])
    const [newRole, setNewRole] = useState({
        role_name: "", level: 0, can_add_expense: true, can_modify_expense: false, can_add_income: false,
        can_delete_expense: false, can_manage_user: false, can_manage_role: false, can_invite_user: false,
        can_modify_income: false, can_delete_income: false, can_manage_vendor: false, can_manage_account: false,
        can_manage_branch: false, can_see_income: false, can_manage_employee: false,
        can_review_expense: false, can_see_expense: false, can_see_transaction: false,
    })
    const [roleInfoError, setRoleInfoError] = useState({ role_name: false })
    const [openRoleModal, setOpenRoleModal] = useState(false)
    const [openHierarchy, setOpenHierarchy] = useState(false)

    useEffect(() => {
        dispatch(getRoles())
            .then((response) => {
                if (response.status === 200) {
                    setRoles(response.roles)
                }
            })
    }, [])

    useEffect(() => {
        setNewRole(prev_role => ({ ...prev_role, level: 0 }))
    }, [openHierarchy])

    const createRole = () => {
        if (newRole.role_name !== false) {
            if (newRole.level === 0 || newRole.level === false)
                newRole.level = roles[roles.length - 1].level
            newRole.level += 1

            dispatch(createNewRole(newRole))
                .then(response => {
                    if (response.status === 201) {
                        setRoles(prevState => [...prevState, response.role])
                        setOpenRoleModal(false)
                        Object.keys(newRole).forEach((i) => newRole[i] = false);
                    }
                })
        } else {
            setRoleInfoError(prevState => ({ ...prevState, role_name: "Please enter name" }))
        }
    }

    const handleDeleteRole = (role) => {
        dispatch(deleteRole(role))
            .then(response => {
                if (response.status === 204) {
                    setRoles(prevState => prevState.filter(r => r.id !== role))
                }
            })
    }

    const handleUpdateRole = () => {
        dispatch(updateRole(newRole))
            .then(response => {
                if (response.status === 200) {
                    setRoles(prevState => prevState.map(role => role.id === response.role.id ? response.role : role));
                    setNewRole({
                        role_name: "", can_add_expense: true, can_modify_expense: false, level: 0,
                        can_delete_expense: false, can_manage_user: false, can_manage_role: false, can_invite_user: false
                    })
                }
            })
    }

    const checkBox = (perm) => (
        <>
            {perm ? <FiCheckSquare color="#006CB0" /> : <FiSquare color="#006CB0" />}
        </>
    )

    const handlePermissionChange = (prev_role, perm, e = null) => {
        if (perm === "role_name") setNewRole(prev_role => ({ ...prev_role, [perm]: e.target.value }))
        else if (perm === "level") setNewRole(prev_role => ({ ...prev_role, [perm]: parseInt(e.target.value) }))
        else {
            prev_role.id === newRole.id ? setNewRole({ ...newRole, [perm]: !newRole[perm] }) :
                setNewRole({ ...prev_role, [perm]: !prev_role[perm] })
        }

    }

    const capitalizeString = (word) => {
        let string = word.replaceAll("_", " ")
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const rolePermissions = (small = "", roleDetails) => {
        let { id, role_name, level, ...role } = roleDetails

        return (
            <div className={`role-content ${small}`}>
                {Object.keys(role).map((r, k) => (
                    <div className="role-permissioins">
                        <div className="role-permissioin-body">
                            <p style={{ cursor: 'pointer' }} onClick={() => handlePermissionChange(roleDetails, r)}>
                                {checkBox(role[r])}
                            </p>
                            <p>{capitalizeString(r)}</p>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    const rolePermissionsWithAction = (role) => (
        <>
            {rolePermissions("", role.id === newRole.id ? newRole : role)}
            <div className="align-right">
                <ButtonOulined name="Delete" color="red" onClickHandler={() => handleDeleteRole(role.id)} />   {/* btn1 */}
                {role.id === newRole.id ? <ButtonOulined name="Save" color="green" onClickHandler={handleUpdateRole} /> : <></>}  {/* btn2 */}
            </div>
        </>
    )

    const changeHierarchy = () => (
        <Dropdown id="level" name="level" placeholder="After -"
            onChangeHandler={(event) => handlePermissionChange(newRole, 'level', event)}
            options={roles.map(role => ({ value: role.level, name: role.role_name }))}
            defaultValue={newRole.level || "DEFAULT"} />
    )

    const createUserRoleModal = () => (
        <>
            <p style={{ width: '25rem', fontSize: '.85rem' }}>By default this role will have the lowest priority.
                <span style={{ color: "#006CB0", cursor: "pointer" }} onClick={() => setOpenHierarchy(!openHierarchy)}>Change Position</span>
            </p>
            {openHierarchy ? changeHierarchy() : <></>}
            <div>
                <TextInput id="role_name" name="role_name" placeholder="Name" value={newRole.role_name ? newRole.role_name : ""}
                    onChangeHandler={(event) => handlePermissionChange(newRole, 'role_name', event)}
                    size="large" error={roleInfoError.role_name && true} errorMessage={roleInfoError.role_name} />
            </div>
            {rolePermissions("small-role-content", newRole)}
            <FilledRoundedButton onClickHandler={createRole} name="Create" color="green" size="large" />
        </>
    )

    return role.can_manage_role ? (
        <>
            <Modal header="Create New User Role" body={createUserRoleModal} isOpen={openRoleModal} modalHandler={() => setOpenRoleModal(false)} />
            <div className="align-right">
                <FilledRoundedButton onClickHandler={() => {
                    Object.keys(newRole).forEach((i) => newRole[i] = false);
                    setOpenRoleModal(true)
                }} name="Add Role" color="green" size="small" />
            </div>
            <Accordion roleList={roles} content={rolePermissionsWithAction} />
        </>
    ) : <></>
}

export default RoleManagement