import axios from "axios";
import {getHeader} from "./../utility"
import {ADD_MESSAGE, ADD_BRANCH} from './../actions/types'

export const getRoles = () => (dispatch, getState) => {
    return axios
        .get(`/auth/role/`, getHeader(getState))
        .then(response => {
            return {status: response.status, roles: response.data}
        })
        .catch(err => {
            console.error("###### Failed to load roles ############")
        })
}

export const updateRole = (role) => (dispatch, getState) => {
    let {id, ...body_role} = role
    const body = JSON.stringify(body_role)

    return axios
        .put(`/auth/role/${role.id}/`, body, getHeader(getState))
        .then(response => {
            dispatch({
                type: ADD_MESSAGE,
                payload: {msg: "Update role info", status: response.status}
            })
            return {status: response.status, role: response.data}
        })
        .catch(err => {
            console.error("###### Failed to update role ############")
            dispatch({
                type: ADD_MESSAGE,
                payload: {msg: "Failed to update. Please try again.", status: err.response.status}
            })
        })
}

export const createNewRole = (role) => (dispatch, getState) => {
    let {id, ...body_role} = role
    const body = JSON.stringify(body_role)

    return axios
        .post(`/auth/role/`, body, getHeader(getState))
        .then(response => {
            dispatch({
                type: ADD_MESSAGE,
                payload: {msg: "Role successfully created", status: response.status}
            })
            return {status: response.status, role: response.data}
        })
        .catch(err => {
            console.error("###### Failed to create new role #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: {msg: err.response.data, status: err.response.status}
            })
        })
}

export const deleteRole = (id) => (dispatch, getState) => {
    return axios
        .delete(`/auth/role/${id}/`, getHeader(getState))
        .then(response => {
            dispatch({
                type: ADD_MESSAGE,
                payload: {msg:"Role successfully deleted", status: response.status}
            })
            return {status: response.status, role:"Delete Successfully"}
        })
        .catch(err => {
            console.error("##### Failed to delete role #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: {msg:"Failed to delete role", status:err.response.status}
            })
        })
}

//                                    Branch APi Call

export const getBranches = () => (dispatch, getState) => {
    return axios
        .get(`/auth/branch/`, getHeader(getState))
        .then(response => {
            return {status:response.status, data:response.data}
        })
        .catch(err => {
            console.error("##### Failed to get Branch #####")
        })
}

export const updateBranch = (branch) => (dispatch, getState) => {
    let {id, ...body} = branch
    body = JSON.stringify(body)

    return axios
        .put(`/auth/branch/${id}/`, body, getHeader(getState))
        .then(response => {
            dispatch({
                type: ADD_MESSAGE,
                payload: {msg: "Branch successfully updated", status: response.status}
            })
            return {status: response.status, data: response.data}
        })
        .catch(err => {
            console.error("###### Failed to update branch #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: {msg: "Failed to update. Please try again with valid data.", status: err.response.status}
            })
            return {status: err.response.status, data: err.response.data}
        })
}

export const createNewBranch = (branch) => (dispatch, getState) => {

    const body = JSON.stringify(branch)

    return axios
        .post("/auth/branch/", body, getHeader(getState))
        .then(response => {
            dispatch({
                type: ADD_MESSAGE,
                payload: {msg: "Branch successfully created", status: response.status}
            })
            return {status: response.status, data: response.data}
        })
        .catch(err => {
            console.error("###### Failed to create new branch #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: {msg: "Failed to create. Please try again.", status: err.response.status}
            })
            return {status: err.response.status, data: err.response.data}
        })
}

export const deleteBranch = (id) => (dispatch, getState) => {
    return axios
        .delete(`/auth/branch/${id}/`, getHeader(getState))
        .then(response => {
            dispatch({
                type: ADD_MESSAGE,
                payload: {msg:"Branch successfully deleted", status: response.status}
            })
            return {status: response.status, role:"Delete Successfully"}
        })
        .catch(err => {
            console.error("##### Failed to delete branche #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: {msg:"Failed to delete branch", status:err.response.status}
            })
        })
}