import React from 'react'
import './chip.css'
function Chip({header, onCancel, onPress, active}) {
    return (
        <div className={`chip ${active ? 'active' : 'hide'}-chip`}>
            <p onClick={() => onPress(header)}>{header.name}</p>
            {active && <span onClick={() => onCancel(header)} className="closebtn">&times;</span>}
        </div>
    )
}

export default Chip