import React, { useState, useEffect, useRef } from 'react'

import { createExpense, getSingleExpense, updateExpense, deleteFile } from "../../../actions/expense"
import { useDispatch, useSelector } from 'react-redux'

import CircularProgress from '@mui/material/CircularProgress';

import Modal from '../../../components/Modal'
import TextInput from '../../../components/TextInput'
import Dropdown from '../../../components/Dropdown'
import TextArea from '../../../components/TextArea'
import FilledRoundedButton from "./../../../components/Button/FilledRoundedButton"
import ButtonOulined from '../../../components/Button/ButtonOulined'
import Chip from "../../../components/Chip"

const transaction_type = [{ name: "Transaction", value: "transaction" }, { name: "Salary", value: "salary" }]

const payment_method = [
    { name: "Cash Payment", value: "cash" },
    { name: "Debit Card - QA TEK SOLUTION INC.", value: "Debit Card - QA TEK SOLUTION INC." },
    { name: "SIGNIFY BUSINESS ESSENTIAL CARD...3415", value: "SIGNIFY BUSINESS ESSENTIAL CARD...3415" },
    { name: "SIGNIFY BUSINESS ESSENTIAL CARD...5390", value: "SIGNIFY BUSINESS ESSENTIAL CARD...5390" }
];


function AddExpense({ isOpen, setIsOpen, branches, vendors, updateExpenses, view, update, classification = [],
    payType, changePayType, updateList }) {
    const ref = useRef()
    const role = useSelector((state) => state.auth.role)
    const user = useSelector((state) => state.auth.user)
    const [error, setError] = useState({})
    const [loading, setLoading] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)

    const dispatch = useDispatch()
    const [addExpenseData, setAddExpenseData] = useState({ transaction_type: "transaction", paid_by: user.email })
    const [splitData, setSplitData] = useState({ amount1: 0, amount2: 0 })
    const [files, setFiles] = useState([])

    const expenseDataHandler = (event) => {
        if (event.target.name === 'file') {
            if (parseInt(event.target.files[0].size) <= 5000000) {
                setAddExpenseData(prevState => ({ ...prevState, [event.target.name]: event.target.files[0] }))
                setError(prevState => ({ ...prevState, file: false }));
            } else {
                setError(prevState => ({ ...prevState, file: true }));
                ref.current.value = ""
            }
        }
        else {
            setAddExpenseData(prevState => ({ ...prevState, [event.target.name]: event.target.value }))
        }

        if (event.target.name === "transaction_type")
            changePayType(event.target.value)
    }

    //useEffect(() => {
    //     if(addExpenseData.split){
    //         if(parseInt(splitData.amount1) + parseInt(splitData.amount2) !== parseInt(addExpenseData.amount)){
    //             if(!disabledButton){
    //                 setDisabledButton(true)
    //                 setError(prevState => ({...prevState, error:`Total Amount should equal to ${addExpenseData.amount}`}))
    //             }
    //         }
    //         else setDisabledButton(false)
    //     }
    // }, [splitData])

    useEffect(() => {
        if (update) {
            dispatch(getSingleExpense(update, view))
                .then((response) => {
                    if (response.status === 200) {
                        let { expense_receipt, ...expense } = response.data
                        console.log('Here: ' + expense);
                        setAddExpenseData(expense)
                        setFiles(expense_receipt)
                        expense.split && setSplitData(expense.split)
                        changePayType(expense.transaction_type)
                    }
                })
        }
    }, [])

    const saveExpense = () => {
        if (view === "employee" || role.can_add_expense) {
            if (addExpenseData.paid_to === undefined) {
                setError(prevState => ({ ...prevState, paid_to: true }))
                return
            }
            if (addExpenseData.transaction_type === "salary") {
                console.log(addExpenseData);
                addExpenseData.paid_to_salary = addExpenseData.paid_to
                delete addExpenseData.paid_to
            }

            if (!splitVerification()) return

            setLoading(true);
            dispatch(createExpense(addExpenseData, view))
                .then((response) => {
                    if (response.status === 201) {
                        updateExpenses(response.data)
                        makeModalClose()
                    } else {
                        setError(prevState => ({ ...prevState, ...response.data }))
                    }
                    setLoading(false)
                })
        }
    }

    const splitVerification = () => {
        if (addExpenseData.split) {
            if (parseInt(addExpenseData.split.amount1) + parseInt(addExpenseData.split.amount2) !== parseInt(addExpenseData.amount) + (parseInt(addExpenseData.amount1) || 0)) {
                setError(prevState => ({
                    ...prevState, error: `Total amount should be equal to ${addExpenseData.amount}`,
                    amount1: true, amount2: true
                }))
                return false
            }
            if (!addExpenseData.split.classification1 || !addExpenseData.split.classification2) {
                if (!addExpenseData.split.classification1) {
                    setError({ classification1: ["This field is required"] })
                } if (!addExpenseData.split.classification2) {
                    setError(prevState => ({ ...prevState, classification2: ["This field is required"] }))
                }
                return false
            }
            addExpenseData.classification = addExpenseData.split.classification1 + " | " + addExpenseData.split.classification2
        }
        return true
    }

    const handleUpdateExpense = () => {
        if (view === "employee" || role.can_modify_expense) {
            if (addExpenseData.transaction_type === "salary") {
                if (addExpenseData.paid_to) {
                    addExpenseData.paid_to_salary = addExpenseData.paid_to
                    delete addExpenseData.paid_to
                }
            }

            if (!splitVerification()) return

            setLoading(true)

            dispatch(updateExpense(update, addExpenseData, { view: view }))
                .then(response => {
                    if (response.status === 200) {
                        ref.current.value = ""
                        let { expense_receipt, ...expense } = response.data
                        setAddExpenseData(expense)
                        setFiles(expense_receipt)
                        updateList(response.data)
                        setError({})
                        makeModalClose()
                    }
                    setLoading(false)
                })
        }
    }

    const makeModalClose = () => {
        setIsOpen(false)
        setAddExpenseData({})
        window.location.reload();
    }

    const downloadFile = (file) => {
        let url = String(process.env.REACT_APP_BASE_URL)
        window.open(`${url.slice(0, -1)}${file.file}`, "_blank")
    }

    const handleDeleteFile = (file) => {
        if (view === "employee" || role.can_delete_expense) {
            dispatch(deleteFile(file.id))
                .then(response => {
                    if (response.status === 204) {
                        let newFiles = files.filter(f => f.id !== file.id)
                        setFiles(newFiles)
                    }
                })
        }
    }

    const splitDataHandler = (event) => {
        setAddExpenseData(prevState => ({ ...prevState, split: { ...prevState.split, [event.target.name]: event.target.value } }))
        // setSplitData(prevState => ({...prevState, [event.target.name]: event.target.value}))
    }

    const handleSplitExpense = () => {
        // setSplitData(prevState => ({...prevState, classification1:addExpenseData.classification, amount1:addExpenseData.amount}))
        setAddExpenseData(prevState => ({ ...prevState, split: { classification1: prevState.classification, amount1: parseInt(prevState.amount), amount2: 0 } }))
    }

    const addExpenseModalBody = () => (
        <>
            <div className="add-expense-body">
                <div className="add-expense-row">
                    <TextInput id="branch" name="branch" placeholder={"Branch"}
                        disabled={true} size="medium" value={user.branch} error={error.branch || false} />
                    <TextInput id="date" name="date" placeholder="Date" type="date" size="medium" onChangeHandler={expenseDataHandler} value={addExpenseData.date}
                        error={error.date || false} errorMessage={error.date && error.date[0]} />

                </div>
                <div className="add-expense-row">
                    <TextInput id="amount" name="amount" placeholder="Amount" type="number" step="0.01" value={parseFloat(addExpenseData.amount) + (parseFloat(addExpenseData.amount1) || 0)} size="medium"
                        onChangeHandler={expenseDataHandler} error={error.amount || false} errorMessage={error.amount && error.amount[0]} />
                    {/* <Dropdown id="account" name="account" placeholder="Account" onChangeHandler={expenseDataHandler}
                            options={[]} size="medium" error={error.account || false}/> */}
                    <TextInput id="classification" name="classification" placeholder="Classification" error={error.classification || false}
                        errorMessage={error.classification && error.classification[0]} onChangeHandler={expenseDataHandler} size="medium" list={true}
                        value={addExpenseData.split ? (addExpenseData.split.classification1 + " | " + (addExpenseData.split.classification2 || "")) : addExpenseData.classification}
                    // value={addExpenseData.classification}
                    />
                    <datalist id="options">
                        {classification.map(option => (
                            <option value={option.classification}>{option.classification}</option>
                        ))}
                    </datalist>
                </div>

                {addExpenseData.split && <div className="split-container">
                    <div className="add-expense-row">
                        <TextInput id="amount1" name="amount1" placeholder="Split Amount" type="number" step="0.01" value={addExpenseData.split.amount1} size="medium"
                            onChangeHandler={splitDataHandler} error={error.amount1 || false} errorMessage={error.amount1 && error.amount1[0]} />
                        {/* <Dropdown id="account" name="account" placeholder="Account" onChangeHandler={expenseDataHandler}
                                options={[]} size="medium" error={error.account || false}/> */}
                        <TextInput id="classification1" name="classification1" placeholder="Classification" error={error.classification1 || false}
                            errorMessage={error.classification1 && error.classification1[0]} onChangeHandler={splitDataHandler}
                            value={addExpenseData.split.classification1} size="medium" list={true} />
                        <datalist id="options">
                            {classification.map(option => (
                                <option value={option.classification}>{option.classification}</option>
                            ))}
                        </datalist>
                    </div>

                    <div className="add-expense-row">
                        <TextInput id="amount2" name="amount2" placeholder="Split Amount" type="number" value={addExpenseData.split.amount2} size="medium"
                            onChangeHandler={splitDataHandler} error={error.amount2 || false} errorMessage={error.amount2 && error.amount2[0]} />
                        {/* <Dropdown id="account" name="account" placeholder="Account" onChangeHandler={expenseDataHandler}
                                options={[]} size="medium" error={error.account || false}/> */}
                        <TextInput id="classification2" name="classification2" placeholder="Classification" error={error.classification2 || false}
                            errorMessage={error.classification2 && error.classification2[0]} onChangeHandler={splitDataHandler}
                            value={addExpenseData.split.classification2} size="medium" list={true} />
                        <datalist id="options">
                            {classification.map(option => (
                                <option value={option.classification}>{option.classification}</option>
                            ))}
                        </datalist>
                    </div>
                </div>}
                {/* <div className="add-expense-row">
                    <Dropdown id="vendor" name="vendor" placeholder="Vendors" onChangeHandler={expenseDataHandler} size="medium" options={[]}/>
                </div> */}
                <div className="add-expense-row">
                    <TextArea id="description" name="description" rows="1" placeholder="Description" value={addExpenseData.description} onChangeHandler={expenseDataHandler} size="extra-large" />
                </div>
                {view === "company" && <div className="add-expense-row">
                    <Dropdown id="transaction_type" name="transaction_type" placeholder="Transaction Type" onChangeHandler={expenseDataHandler}
                        size="medium" options={transaction_type} defaultValue={payType || "DEFAULT"} />

                    <Dropdown
                        id="payment_method"
                        name="payment_method"
                        placeholder="Select Payment Method"
                        onChangeHandler={expenseDataHandler}
                        size="medium"
                        options={payment_method}
                        defaultValue={addExpenseData.payment_method || "DEFAULT"}
                    />

                </div>}
                <div className="add-expense-row">
                    <TextInput id="paid_by" name="paid_by" placeholder="Paid By" onChangeHandler={expenseDataHandler}
                        size="medium" value={view === "employee" ? addExpenseData.paid_by : "QA Tek Solutions Inc."} readOnly={true} />

                    <Dropdown id="paid_to" name="paid_to" placeholder="Paid To" onChangeHandler={expenseDataHandler} error={error.paid_to || false}

                        size="medium" options={vendors} defaultValue={addExpenseData.paid_to || addExpenseData.paid_to_salary || "DEFAULT"} />
                </div>
                {files.length ? <div className="header-edit-container expense-file-container">
                    {files.map((file, key) => (
                        <Chip header={file} onCancel={handleDeleteFile} onPress={downloadFile} active={true} />
                    ))}
                </div> : <></>}
                <div className="add-expense-row">
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <input className={`text-input extra-large-inp`} ref={ref} type="file" id="file" name="file"
                            onChange={expenseDataHandler} />
                        {error.file && <span className="error-message">large File</span>}
                    </div>
                </div>
                <div className="add-expense-row center-item">
                    <ButtonOulined name="Split" onClickHandler={handleSplitExpense} />
                    <ButtonOulined name="Cancel" onClickHandler={makeModalClose} />
                    {(role.can_add_expense || role.can_modify_expense || view === "employee") &&
                        (!loading ? <FilledRoundedButton name={update ? "Update" : "Save"} disable={disabledButton} color="green" size="small" onClickHandler={update ? handleUpdateExpense : saveExpense} /> :
                            <CircularProgress size={27} />)}
                </div>
                {error.error && <span className="error-message">{error.error}</span>}
            </div>
        </>
    )

    return (
        <Modal
            header={`${update ? "Update" : "Add a New"} ${view.charAt(0).toUpperCase() + view.slice(1)} Expense`}
            body={addExpenseModalBody}
            isOpen={isOpen}
            modalHandler={makeModalClose} />
    )
}

export default AddExpense