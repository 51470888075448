import { Box, Button, Container, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useParams } from 'react-router-dom'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Select = styled.select`
    width: 500px; /* Set the desired width */
    background-color: #f2f2f2;
    font-size: 16px;
    padding: 8px;
    border-radius: 8px; 
    border: 1px solid #ccc;
`

const Input = styled.input`
    width: 300px; /* Set the desired width */
    background-color: #f2f2f2;
    font-size: 16px;
    padding: 8px;
    border-radius: 8px; 
    border: 1px solid #ccc;
`

const IncomeNew = () => {
    const [studentType, setStudentType] = useState('stms')
    const { incomeId } = useParams()
    
    const handleChangeStudentType = (event) => {
        setStudentType(event.target.value);
    }

    const [batchList, setBatchList] = useState([])
    const [studentList, setStudentList] = useState([])
    const [employeeList, setEmployeeList] = useState([])
    const [studentInformation, setStudentInformation] = useState()
    const [age, setAge] = React.useState('');
    const [selectBatch, setSelectBatch] = useState('')

    const [studentId, setStudentId] = useState('')
    const [firstInstallmentDate, setFirstInstallmentDate] = useState('Not Given')
    const handleChangeFirstInstallmentDate = (event) => {
        setFirstInstallmentDate(event.target.value)
    }
    const [secondInstallmentDate, setSecondInstallmentDate] = useState('Not Given')
    const handleChangeSecondInstallmentDate = (event) => {
        setSecondInstallmentDate(event.target.value)
    }
    const [thirdInstallmentDate, setThirdInstallmentDate] = useState('Not Given')
    const handleChangeThirdInstallmentDate = (event) => {
        setThirdInstallmentDate(event.target.value)
    }
    const [fourthInstallmentDate, setFourthInstallmentDate] = useState('Not Given')
    const handleChangeFourthInstallmentDate = (event) => {
        setFourthInstallmentDate(event.target.value)
    }

    const [enrollmentDate, setEnrollmentDate] = useState('Not Given')
    const handleChangeEnrollmentDate = (event) => {
        setEnrollmentDate(event.target.value)
    }

    const [firstInstallment, setFirstInstallment] = useState(0)
    const handleChangeFirstInstallment = (event) => {
        setFirstInstallment(event.target.value)
    }
    const [secondInstallment, setSecondInstallment] = useState(0)
    const handleChangeSecondInstallment = (event) => {
        setSecondInstallment(event.target.value)
    }
    const [thirdInstallment, setThirdInstallment] = useState(0)
    const handleChangeThirdInstallment = (event) => {
        setThirdInstallment(event.target.value)
    }
    const [fourthInstallment, setFourthInstallment] = useState(0)
    const handleChangeFourthInstallment = (event) => {
        setFourthInstallment(event.target.value)
    }
    const [targetAmount, setTargetAmount] = useState(0)
    const handleChangeTargetAmount = (event) => {
        setTargetAmount(event.target.value)
    }
    const [paidAmount, setPaidAmount] = useState(0)
    const handleChangePaidAmount = (event) => {
        setPaidAmount(event.target.value)
    }
    const [remainingAmount, setRemainingAmount] = useState(0)
    const handleChangeRemainingAmount = (event) => {
        setRemainingAmount(event.target.value)
    }
    const [previousAmount, setPreviousAmount] = useState('')
    const [enrollmentFee, setEnrollmentFee] = useState(0)
    const handleChangeEnrollmentFee = (event) => {
        setEnrollmentFee(event.target.value)
    }
    const [refundAmount, setRefundAmount] = useState(0)
    const handleChangeRefundAmount = (event) => {
        setRefundAmount(event.target.value)
    }
    const [source, setSource] = useState('')
    const handleChangeSource = (event) => {
        setSource(event.target.value)
    }
    const [course, setCourse] = useState('')
    const handleChangeCourse = (event) => {
        setCourse(event.target.value)
    }
    const [referredBy, setReferredBy] = useState('')
    const handleChangeReferredBy = (event) => {
        setReferredBy(event.target.value)
    }
    const [remarks, setRemarks] = useState('')
    const handleChangeRemarks = (event) => {
        setRemarks(event.target.value)
    }
    const [name, setName] = useState('')
    const handleChangeName = (event) => {
        setName(event.target.value)
    }
    const [phone, setPhone] = useState('')
    const handleChangePhone = (event) => {
        setPhone(event.target.value)
    }
    const [branch, setBranch] = useState('')
    const handleChangeBranch = (event) => {
        setBranch(event.target.value)
    }
    const [email, setEmail] = useState('')
    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
    }
    const [batch, setBatch] = useState('')
    const handleChangeBatch = (event) => {
        setBatch(event.target.value)
    }
    const [batchType, setBatchType] = useState('')
    const handleChangeBatchType = (event) => {
        setBatchType(event.target.value)
    }
    const [country, setCountry] = useState('')
    const handleChangeCountry = (event) => {
        setCountry(event.target.value)
    }
    const [state, setState] = useState('')
    const handleChangeState = (event) => {
        setState(event.target.value)
    }
    const [city, setCity] = useState('')
    const handleChangeCity = (event) => {
        setCity(event.target.value)
    }
    const [postCode, setPostCode] = useState('')
    const handleChangePostCode = (event) => {
        setPostCode(event.target.postCode)
    }
    const [street, setStreet] = useState('')
    const handleChangeStreet = (event) => {
        setStreet(event.target.value)
    }

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleSelectBatch = (event) => {
        // setSelectBatch(event.target.value);
        getStudents(event.target.value, "training");
    }

    const [open, setOpen] = React.useState(false);


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSelectStudent = (event) => {
        setStudentInformation(studentList[parseInt(event.target.value)])
        var studentInfo = studentList[parseInt(event.target.value)];
        setStudentId(studentInfo.id)
        setName(studentInfo.name)
        setPhone(studentInfo.phone_number)
        setBranch(studentInfo.location)
        setEmail(studentInfo.email)
        setBatch(studentInfo.serialized_batch.batch_name)
        setBatchType(studentInfo.serialized_batch.batch_type)
        setCountry(studentInfo.student_address.country)
        setState(studentInfo.student_address.state)
        setCity(studentInfo.student_address.city)
        setPostCode(studentInfo.student_address.post_code)
        setStreet(studentInfo.student_address.street_address)
        setFirstInstallment(studentInfo.payment_information.first_payment_status ? studentInfo.payment_information.first_payment_status : 0);
        setSecondInstallment(studentInfo.payment_information.second_payment_status ? studentInfo.payment_information.second_payment_status : 0)
        setThirdInstallment(studentInfo.payment_information.third_payment_status ? studentInfo.payment_information.third_payment_status : 0)
        setFourthInstallment(studentInfo.payment_information.fourth_payment_status ? studentInfo.payment_information.fourth_payment_status : 0)
        setFirstInstallmentDate(studentInfo.payment_information.first_payment_date ? studentInfo.payment_information.first_payment_date : 'Not Given')
        setSecondInstallmentDate(studentInfo.payment_information.second_payment_date ? studentInfo.payment_information.second_payment_date : 'Not Given')
        setThirdInstallmentDate(studentInfo.payment_information.third_payment_date ? studentInfo.payment_information.third_payment_date : 'Not Given')
        setFourthInstallmentDate(studentInfo.payment_information.fourth_payment_date ? studentInfo.payment_information.fourth_payment_date : 'Not Given')
        setEnrollmentDate(studentInfo.payment_information.enrollment_payment_date ? studentInfo.payment_information.enrollment_payment_date : 'Not Given')
        setTargetAmount(studentInfo.payment_information.target_amount ? studentInfo.payment_information.target_amount : 0)
        setPaidAmount(studentInfo.payment_information.paid_amount ? studentInfo.payment_information.paid_amount : 0)
        setRemainingAmount(studentInfo.payment_information.balance_remaining ? studentInfo.payment_information.balance_remaining : 0)
        setEnrollmentFee(studentInfo.payment_information.enrollment_payment ? studentInfo.payment_information.enrollment_payment : 0)
        setSource(studentInfo.source)
        setReferredBy(studentInfo.referred_by)
        setCourse(studentInfo.enrolled_program)
        setRefundAmount(studentInfo.payment_information.refund_amount ? studentInfo.payment_information.refund_amount : 0)
    }

    const getBatch = () => {
        axios({
            method: 'GET',
            url: 'https://stms.qatekinternal.com/records/partner-api/batch-list/',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('access')
            },
        })
            .then((response) => {
                if (response.status) {
                    setBatchList(response.data);
                }
            })
    }

    const getStudents = (batch, incomeType) => {
        const jobSupport = 'https://stms.qatekinternal.com/job-support/partner-api/job-support-list/'
        const softwareTraining = 'https://stms.qatekinternal.com/records/partner-api/enrolled-student-list/'

        axios({
            method: 'GET',
            url: incomeType === "training" ? softwareTraining : jobSupport,
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('access')
            },
            params: {
                batch_name: batch
            }
        })
            .then((response) => {
                if (response.status) {
                    setStudentList(response.data);
                }
            })
    }

    const getEmployees = () => {
        axios({
            method: 'GET',
            url: '/auth/employee/',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('access')
            },
        })
            .then((response) => {
                if (response.status) {
                    setEmployeeList(response.data);
                }
            })
    }

    const getBranchName = (branchId) => {
        axios({
            method: 'GET',
            url: '/auth/branch-name/',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('access')
            },
            params: {
                branch_id: branchId
            }
        }).then((response) => {
            if (response.status) {
                setBranch(response.data);
            }
        })
    }

    const getIncomeDetails = () => {
       console.log(incomeId);
        axios({
            method: 'GET',
            url: '/income/submit-software-training/',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('access')
            },
            params: {
                income_id: incomeId
            }
        })
            .then((response) => {
                if (response.status) {
                    console.log(response.data);
                    setStudentId(response.data.student.id)
                    setName(response.data.student.name)
                    setPhone(response.data.student.phone_number)
                    // setBranch(studentInfo.location)
                    getBranchName(response.data.student.branch);
                    setEmail(response.data.student.email)
                    setBatch(response.data.student.batch)
                    setBatchType(response.data.student.batch_type)
                    setCountry(response.data.student.country)
                    setState(response.data.student.state)
                    setCity(response.data.student.city)
                    setPostCode(response.data.student.post_code)
                    setStreet(response.data.student.street_address)
                    setFirstInstallment(response.data.first_installment.paid_amount ? response.data.first_installment.paid_amount : 0);
                    setSecondInstallment(response.data.second_installment.paid_amount ? response.data.second_installment.paid_amount : 0)
                    setThirdInstallment(response.data.third_installment.paid_amount ? response.data.third_installment.paid_amount : 0)
                    setFourthInstallment(response.data.fourth_installment.paid_amount ?response.data.fourth_installment.paid_amount : 0)
                    setFirstInstallmentDate(response.data.first_installment.transaction_date ? response.data.first_installment.transaction_date : 'Not Given')
                    setSecondInstallmentDate(response.data.second_installment.transaction_date ? response.data.second_installment.transaction_date : 'Not Given')
                    setThirdInstallmentDate(response.data.third_installment.transaction_date ? response.data.third_installment.transaction_date : 'Not Given')
                    setFourthInstallmentDate(response.data.fourth_installment.transaction_date ? response.data.fourth_installment.transaction_date : 'Not Given')
                    setEnrollmentDate(response.data.enrollment_date ? response.data.enrollment_date : 'Not Given')
                    setTargetAmount(response.data.target_amount ?response.data.target_amount : 0)
                    setPaidAmount(response.data.paid_amount ? response.data.paid_amount : 0)
                    setRemainingAmount(response.data.remaining_amount ? response.data.remaining_amount : 0)
                    setEnrollmentFee(response.data.enrollment_fee ? response.data.enrollment_fee : 0)
                    setSource(response.data.student.source)
                    setReferredBy(response.data.referred_by)
                    setCourse(response.data.course_name)
                    setRefundAmount(response.data.refund_amount ? response.data.refund_amount : 0)
                }
            })

    }

    const submitIncome = () => {
        axios({
            method: 'POST',
            url: 'income/submit-software-training/',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('access')
            },
            data: {
                stms_id: studentId,
                batch_type: batchType,
                batch: batch,
                name: name,
                phone_number: phone,
                email: email,
                street_address: street,
                city: city,
                country: country,
                state: state,
                post_code: postCode,
                branch: branch,
                source: source,
                course_name: course,
                referred_by: referredBy,
                enrollment_fee: enrollmentFee,
                remaining_amount: remainingAmount,
                paid_amount: paidAmount,
                target_amount: targetAmount,
                first_installment: firstInstallment,
                second_installment: secondInstallment,
                third_installment: thirdInstallment,
                fourth_installment: fourthInstallment,
                first_payment_date: firstInstallmentDate,
                second_payment_date: secondInstallmentDate,
                third_payment_date: thirdInstallmentDate,
                fourth_payment_date: fourthInstallmentDate,
                enrollment_date: enrollmentDate,
                refund_amount: refundAmount
            }
        }).then((response) => {
            if (response.status == 201) {
                setOpen(true)
            }
        })
    }

    useEffect(() => {
        getBatch();
        getEmployees();
        getIncomeDetails();
    }, [])

    return (
        <div>
            <Typography variant='h5'>
                Update Income (Software Training)
            </Typography>
            <Container>
                <RadioGroup row
                    aria-label="gender"
                    defaultValue="female"
                    name="radio-buttons-group"
                >
                    <FormControlLabel value="new" control={<Radio checked={studentType == "new" ? true : false} onChange={handleChangeStudentType} />} label="New Student" />
                    <FormControlLabel value="stms" control={<Radio checked={studentType == "stms" ? true : false} onChange={handleChangeStudentType} />} label="Existing Student (From STMS)" />
                </RadioGroup>
                {/* <Grid container spacing={2}>
                    <Grid item lg="6" md="6" sm="12">
                        <InputLabel>Select Batch</InputLabel>
                        <Select onChange={handleSelectBatch}>
                            <option>-- Select Batch --</option>
                            {
                                batchList.map((batch) =>
                                    (<option value={batch.batch_name}>{batch.batch_name}</option>)
                                )
                            }
                        </Select>
                    </Grid>
                    <Grid item lg="6" md="6" sm="12">
                        <InputLabel>Select Student</InputLabel>
                        <Select onChange={handleSelectStudent}>
                            <option>-- Select Student --</option>
                            {
                                studentList.map((student, index) =>
                                    (<option value={index}>{student.student_id + ": " + student.name}</option>))
                            }
                        </Select>
                    </Grid>
                </Grid> */}

                <Grid container spacing={2}>
                    <Grid item lg="4" md="4" sm="12">
                        <InputLabel>Name</InputLabel>
                        <Input value={name} onChange={handleChangeName}></Input>

                    </Grid>
                    <Grid item lg="4" md="4" sm="12">
                        <InputLabel>Phone</InputLabel>
                        <Input value={phone} onChange={handleChangePhone}></Input>

                    </Grid>
                    <Grid item lg="4" md="4" sm="12">
                        <InputLabel>Branch</InputLabel>
                        <Input value={branch} onChange={handleChangeBranch}></Input>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item lg="4" md="4" sm="12">
                        <InputLabel>Email</InputLabel>
                        <Input value={email} onChange={handleChangeEmail}></Input>

                    </Grid>
                    <Grid item lg="4" md="4" sm="12">
                        <InputLabel>Batch Type</InputLabel>
                        <Input value={batchType} onChange={handleChangeBatchType}></Input>

                    </Grid>
                    <Grid item lg="4" md="4" sm="12">
                        <InputLabel>Batch</InputLabel>
                        <Input value={batch} onChange={handleChangeBatch}></Input>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item lg="4" md="4" sm="12">
                        <InputLabel>Country</InputLabel>
                        <Input value={country} onChange={handleChangeCountry}></Input>

                    </Grid>
                    <Grid item lg="4" md="4" sm="12">
                        <InputLabel>State</InputLabel>
                        <Input value={state} onChange={handleChangeState}></Input>

                    </Grid>
                    <Grid item lg="4" md="4" sm="12">
                        <InputLabel>City</InputLabel>
                        <Input value={city} onChange={handleChangeCity}></Input>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item lg="4" md="4" sm="12">
                        <InputLabel>Post Code</InputLabel>
                        <Input value={postCode} onChange={handleChangePostCode}></Input>

                    </Grid>
                    <Grid item lg="4" md="4" sm="12">
                        <InputLabel>Street</InputLabel>
                        <Input value={street} onChange={handleChangeStreet}></Input>

                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item lg='4' md='4' sm='12'>
                        <InputLabel>First Installment</InputLabel>
                        <Input value={firstInstallment} onChange={handleChangeFirstInstallment}></Input>
                        <InputLabel>Fourth Installment</InputLabel>
                        <Input value={fourthInstallment} onChange={handleChangeFourthInstallment}></Input>
                        <InputLabel>Paid Amount</InputLabel>
                        <Input value={paidAmount} onChange={handleChangePaidAmount}></Input>
                    </Grid>
                    <Grid item lg='4' md='4' sm='12'>
                        <InputLabel>Second Installment</InputLabel>
                        <Input value={secondInstallment} onChange={handleChangeSecondInstallment}></Input>
                        <InputLabel>Target Amount</InputLabel>
                        <Input value={targetAmount} onChange={handleChangeTargetAmount}></Input>
                        <InputLabel>Remaining Amount</InputLabel>
                        <Input value={remainingAmount} onChange={handleChangeRemainingAmount}></Input>
                    </Grid>
                    <Grid item lg='4' md='4' sm='12'>
                        <InputLabel>Third Installment</InputLabel>
                        <Input value={thirdInstallment} onChange={handleChangeThirdInstallment}></Input>
                        <InputLabel>Previous Paid</InputLabel>
                        <Input value={previousAmount}></Input>
                        <InputLabel>Enrollment Amount</InputLabel>
                        <Input value={enrollmentFee} onChange={handleChangeEnrollmentFee}></Input>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item lg='4' md='4' sm='12'>
                        <InputLabel>Refund Amount</InputLabel>
                        <Input value={refundAmount} onChange={handleChangeRefundAmount}></Input>
                    </Grid>
                    <Grid item lg='4' md='4' sm='12'>
                        <InputLabel>First Installment Date</InputLabel>
                        <Input value={firstInstallmentDate} onChange={handleChangeFirstInstallmentDate}></Input>
                    </Grid>
                    <Grid item lg='4' md='4' sm='12'>
                        <InputLabel>Second Installment Date</InputLabel>
                        <Input value={secondInstallmentDate} onChange={handleChangeSecondInstallmentDate}></Input>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item lg='4' md='4' sm='12'>
                        <InputLabel>Third Installment Date</InputLabel>
                        <Input value={thirdInstallmentDate} onChange={handleChangeThirdInstallmentDate}></Input>
                    </Grid>
                    <Grid item lg='4' md='4' sm='12'>
                        <InputLabel>Fourth Installment Date</InputLabel>
                        <Input value={fourthInstallmentDate} onChange={handleChangeFourthInstallmentDate}></Input>
                    </Grid>
                    <Grid item lg='4' md='4' sm='12'>
                        <InputLabel>Enrollment Date</InputLabel>
                        <Input value={enrollmentDate} onChange={handleChangeEnrollmentDate}></Input>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item lg="4" md="4" sm="12">
                        <InputLabel>Source</InputLabel>
                        <Input value={source} onChange={handleChangeSource}></Input>

                    </Grid>
                    <Grid item lg="4" md="4" sm="12">
                        <InputLabel>Course</InputLabel>
                        <Input value={course} onChange={handleChangeCourse}></Input>

                    </Grid>
                    <Grid item lg="4" md="4" sm="12">
                        <InputLabel>Referred By</InputLabel>
                        <Input value={referredBy} onChange={handleChangeReferredBy}></Input>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        m: 2
                    }}
                >
                    <Button color='success'
                        sx={{
                            background: '#ECFFDC'
                        }} onClick={submitIncome}>Update</Button>
                </Box>
            </Container>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    You have updated an income!
                </Alert>
            </Snackbar>
        </div>
    )
}

export default IncomeNew