import axios from "axios";
import { getHeader } from "./../utility"
import { ADD_MESSAGE } from './../actions/types'

export const getEmployeesContructor = (query) => (dispatch, getState) => {
    let config = getHeader(getState)
    config['params'] = query
    return axios
        .get(`auth/contractor/`, config)
        .then(response => {
            if (response.status === 200) {
                return { status: response.status, totalPage: response.data.total_pages, data: response.data.results }
            }
        })
        .catch(err => {
            console.error("##### Failed to get employee data #####")
        })
}

export const getEmployees = (query) => (dispatch, getState) => {
    let config = getHeader(getState)
    return axios
        .get(`auth/employees/`, config)
        .then(response => {
            if (response.status === 200) {
                console.log(response.data);
                return { status: response.status, data: response.data }
            }
        })
        .catch(err => {
            console.error("##### Failed to get employee data #####")
        })
}


export const createEmployee = (data) => (dispatch, getState) => {
    let body = JSON.stringify(data)

    return axios
        .post(`/auth/employees/`, body, getHeader(getState))
        .then((response) => {
            if (response.status === 201) {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Successfully created", status: 200 }
                })
                return { status: response.status, data: response.data }
            } else {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Failed to create Employee", status: 400 }
                })
            }
        })
        .catch((err) => {
            console.log("##### Failed to create Employee #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Failed to create Employee", status: 400 }
            })
        })
}

export const deleteEmployee = (id) => (dispatch, getState) => {

    return axios
        .delete(`auth/contractor/${id}/`, getHeader(getState))
        .then((response) => {
            if (response.status === 204) {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Successfully deleted", status: 200 }
                })
                return { status: response.status, data: response.data }
            } else {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Failed to delete Employee", status: 400 }
                })
            }
        })
        .catch((err) => {
            console.log("##### Failed to delete Employee #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Failed to delete Employee", status: 400 }
            })
        })
}

export const retrieveEmployee = (id) => (dispatch, getState) => {

    return axios
        .get(`auth/contractor/${id}/`, getHeader(getState))
        .then((response) => {
            if (response.status === 200) {
                return { status: response.status, data: response.data }
            } else {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Failed to get Employee data", status: 400 }
                })
            }
        })
        .catch((err) => {
            console.log("##### Failed to retrieve Employee #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Failed to get Employee data", status: 400 }
            })
        })
}

export const updateEmployee = (data, id) => (dispatch, getState) => {
    let body = JSON.stringify(data)
    return axios
        .put(`auth/contractor/${id}/`, body, getHeader(getState))
        .then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Successfully Updated", status: response.status }
                })
                return { status: response.status, data: response.data }
            } else {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Failed to update Employee data", status: 400 }
                })
            }
        })
        .catch((err) => {
            console.log("##### Failed to update Employee #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Failed to update Employee data", status: 400 }
            })
        })
}