import React, {useState, useEffect} from 'react'
import "./employee.css"

import { getEmployees, deleteEmployee, getRoles, inviteNewUser, updateEmployee } from "../../../actions/auths"
import { getBranches } from '../../../actions/role'
import { useDispatch, useSelector } from "react-redux"
import { ADD_MESSAGE } from '../../../actions/types'

import CircularProgress from '@mui/material/CircularProgress';


import FilledRoundedButton from "./../../../components/Button/FilledRoundedButton"
import SimpleTable from "./../../../components/Table/SimpleTable"
import Modal from '../../../components/Modal'
import TextInput from "../../../components/TextInput"
import Dropdown from "../../../components/Dropdown"
import Loading from "../../../components/Loading"

function Employees({}) {
  const dispatch = useDispatch()
  const {role} = useSelector(state => state.auth)

  const [tableHeader, setTableHeader] = useState([
    {name:"Action", size:"medium"},
    {name:"ID", size:"medium"},
    {name:"Name", size:"large"}, 
    {name:"Email", size:"large"}, 
    {name:"Role", size:"large"}, 
    {name:"Branch", size:"large"},
    {name:"Designation", size:"large"},
  ])

  const [openInviteModal, setOpenInviteModal] = useState(false)
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [loading, setLoading] = useState(false)
  
  const [invitedUserData, setInvitedUserData] = useState({})
  const [invitedUserDataError, setInvitedUserDataError] = useState({email: false})
  const [updateUserData, setUpdateUserData] = useState({})
  const [value, setValue] = useState([])
  const [roles, setRoles] = useState([])
  const [branches, setBranches] = useState([])
  

  useEffect(() => {
    if(role.can_manage_user){
      dispatch(getEmployees())
      .then((data) => setValue(data))
    }
  }, [])

  useEffect(() => {
    if(roles.length === 0){
      dispatch(getRoles(0))
        .then((data) => {
          if(data.status === 200)
            setRoles(data.data)
        })
    }
    if(branches.length === 0){
      dispatch(getBranches())
        .then(response => {
            if(response.status === 200){
                setBranches(response.data)
            }
        })
    }
  }, [])

  const inviteUserData = (event) => {
    setInvitedUserData({...invitedUserData, [event.target.name]: event.target.value})
  }

  const inviteUser = (event) => {
    event.preventDefault()
    setLoading(true)
    dispatch(inviteNewUser({...invitedUserData}))
      .then(response => {
        if(response.status === 201){
          setOpenInviteModal(false)
          setInvitedUserData({})
          setInvitedUserDataError({})
        } else {
          setInvitedUserDataError(response.data)
        }
      setLoading(false)
      })
  }

  const editUser = (user) => {
    if(role.can_manage_user){
      let r = "DEFAULT", b = "DEFAULT"
      if(user.role.name !== "") r = parseInt(roles.filter(r => r.name===user.role.name)[0].value)
      if('branch' in user) b = parseInt(branches.filter(branch => branch.name===user.branch)[0].id)
      setUpdateUserData({...user, role: r, branch:b})
      setOpenUpdateModal(true)
    } else {
      dispatch({
        type: ADD_MESSAGE,
        payload: {status: 400, msg:"You are not allowed to edit this user."}
      })
    }
  }

  const deleteUser = (user_id) => {
    if(role.can_manage_user){
      setLoading(true);
      dispatch(deleteEmployee(user_id.id))
      .then((response) => {
        console.log(response);
        if (response.status === 204) {
          const new_emp = value.filter(emp => emp.id !== user_id.id);
          setValue(new_emp)
          setLoading(false);
        }
        // window.location.reload(); 
      })
    } 
    else {
      dispatch({
        type: ADD_MESSAGE,
        payload: {status: 400, msg:"You are not allowed to delete this user."}
      })
    }
  }

  const updateUserDataHandeler = () => {
    setLoading(true);
    dispatch(updateEmployee({role:updateUserData.role, branch:updateUserData.branch, designation:updateUserData.designation}, updateUserData.id))
    .then(response => {
      if(response.status === 200){
        let t = value.map(emp => emp.id === response.data.id ? response.data : emp);
        setValue(t)
        setOpenUpdateModal(false)
      }
      setLoading(false)
    })
  }

  const tableBody = (user) => (
    <>
      {Object.keys(user).map((v, j) => <td key={j}>{v==="role" ?user[v]?.name : user[v]}</td>)}
    </>
  )

  const handleUpdateUserData = (event) => {
    setUpdateUserData(prevState => ({...prevState, [event.target.name]: event.target.value}))
  }

  const inviteUserModalBody = () => (
    <>
      <TextInput id="email" name="email" placeholder="Email" onChangeHandler={inviteUserData} size="large"
        error={invitedUserDataError.email && true} errorMessage={invitedUserDataError.email && invitedUserDataError.email[0]} value={invitedUserData.email}/>
      <Dropdown id="role" name="role" placeholder="Enter User Role" onChangeHandler={inviteUserData} options={roles}
        defaultValue={invitedUserData.role || "DEFAULT"} error={invitedUserDataError.role && true} errorMessage={invitedUserDataError.role && invitedUserDataError.role[0]}/>
      <Dropdown id="branch" name="branch" placeholder="Select Branch" onChangeHandler={inviteUserData} defaultValue={invitedUserData.branch || "DEFAULT"}
        options={branches.map(branch => ({value:branch.id, name:branch.name}))}
        error={invitedUserDataError.branch && true} errorMessage={invitedUserDataError.branch && invitedUserDataError.branch[0]}/>
        {loading ? <CircularProgress size={27}/> :
      <FilledRoundedButton onClickHandler={inviteUser} name="Send Invitation" color="green" size="large"/>}
    </>
  )

  const updateUserModalBody = () => (
    <>
      <TextInput id="email" name="email" placeholder="Email" onChangeHandler={handleUpdateUserData} size="large" readOnly={true}
        value={updateUserData.email}/>
      <Dropdown id="role" name="role" placeholder="Enter User Role" onChangeHandler={handleUpdateUserData} options={roles} defaultValue={updateUserData.role}/>
      <Dropdown id="branch" name="branch" placeholder="Select Branch" onChangeHandler={handleUpdateUserData} options={branches.map(branch => ({value:branch.id, name:branch.name}))} 
        defaultValue={updateUserData.branch}/>
      <TextInput id="designation" name="designation" placeholder="Enter Designation" onChangeHandler={handleUpdateUserData} size="large" value={updateUserData.designation || ""}/>
      {loading ? <CircularProgress size={27}/> :
        <FilledRoundedButton onClickHandler={updateUserDataHandeler} name="Update User" color="green" size="large"/>}
    </>
  )

  const openModalHandler = () => {
    dispatch(getBranches())
      .then(response => {
          if(response.status === 200){
              setBranches(response.data)
          }
      })
      setOpenInviteModal(true)
      setInvitedUserDataError({})
  }

  return (
    <div>
        {roles.length ? <Modal header="Send Invitation Link" body={inviteUserModalBody} isOpen={openInviteModal} modalHandler={() => setOpenInviteModal(false)}/> : <></>}
        <Modal header="Update User Information" body={updateUserModalBody} isOpen={openUpdateModal} modalHandler={() => setOpenUpdateModal(false)}/>
        {role.can_invite_user && <div className="align-right">
          <FilledRoundedButton onClickHandler={openModalHandler} name="Invite User" color="green" size="small"/>
        </div>}

        {role.can_manage_user  && (
          loading ? <Loading/> : <SimpleTable header={tableHeader} values={value} onDelete={deleteUser} onEdit={editUser} tableBody={tableBody}/>
          )}

    </div>
  )
}

export default Employees