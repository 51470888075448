import React, { useState } from 'react';
import axios from 'axios';

const AddOtherIncome = ({ handleAddIncome }) => {
    const [formData, setFormData] = useState({
        payment_method: '',
        date: '',
        detail: '',
        category: '',
        target_amount: '',
        paid_amount: '',
        remaining_amount: '',
        customer_name: '',
        receipt: null,
    });

    const handleChange = (e) => {
        if (e.target.name === 'receipt') {
            setFormData({ ...formData, [e.target.name]: e.target.files[0] });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const accessToken = localStorage.getItem('access');
            const formDataToSend = new FormData();
            formDataToSend.append('payment_method', formData.payment_method);
            formDataToSend.append('date', formData.date);
            formDataToSend.append('detail', formData.detail);
            formDataToSend.append('category', formData.category);
            formDataToSend.append('target_amount', formData.target_amount);
            formDataToSend.append('paid_amount', formData.paid_amount);
            formDataToSend.append('remaining_amount', formData.remaining_amount);
            formDataToSend.append('customer_name', formData.customer_name);
            if (formData.receipt) {
                formDataToSend.append('receipt', formData.receipt);
            }

            // Log the form data before sending
            console.log("Form data to be sent:", formDataToSend);

            const config = {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data',
                }
            };

            const res = await axios.post('https://ems-api.qatekinternal.com/income/other-incomes/', formDataToSend, config);
            console.log('OtherIncome added:', res.data);
            handleAddIncome();
        } catch (error) {
            console.error('Error adding OtherIncome:', error);
        }
    };


    const inputStyle = {
        backgroundColor: '#EAEFF4',
        boxSizing: 'border-box',
        border: '2px solid #CDCDCD',
        borderRadius: '5px',
        padding: '0.5rem',
        margin: '0.5rem',
        fontSize: '1rem',
        zIndex: 0,
        position: 'relative'
    };

    const labelStyle = {
        // position: 'absolute',
        top: '0px',
        left: '10px',
        padding: '0 1px',
        color: '#DADCE0',
        fontSize: '14px',
        transition: '0.5s',
        borderRadius: '4px',
        zIndex: 0,
        fontWeight: 600,
        color: '#006CB0'
    };

    return (
        <div>
            {/* <h2>Add Other Income</h2> */}
            <hr />
            <form onSubmit={handleSubmit} style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Payment Method:</label>
                    <input type="text" name="payment_method" value={formData.payment_method} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Date:</label>
                    <input type="date" name="date" value={formData.date} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Detail:</label>
                    <input type="text" name="detail" value={formData.detail} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Category:</label>
                    <input type="text" name="category" value={formData.category} onChange={handleChange} style={inputStyle} />
                </div>

                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Target Amount:</label>
                    <input type="number" name="target_amount" value={formData.target_amount} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Paid Amount:</label>
                    <input type="number" name="paid_amount" value={formData.paid_amount} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Remaining Amount:</label>
                    <input type="number" name="remaining_amount" value={formData.remaining_amount} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Customer Name:</label>
                    <input type="text" name="customer_name" value={formData.customer_name} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Receipt:</label>
                    <input type="file" name="receipt" onChange={handleChange} style={inputStyle} />
                </div>
                <button type="submit" style={{ ...inputStyle, gridColumn: 'span 2', justifySelf: 'center' }}>Save</button>
            </form>
        </div>
    );
};

export default AddOtherIncome;