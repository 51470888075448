import React from 'react'
import "./dropdown.css"

function Dropdown({size="large", id, name, placeholder, onChangeHandler, options=[], defaultValue="DEFAULT",
                    disabled=false, withID=false, loading=false, error=false, errorMessage, required=false, stmsJob=false}) {

    return (
    <div style={{display: 'flex', flexDirection:"column", alignItems:"center"}}>
        <div style={{position: 'relative'}}>
            <select className={`select-style ${size}-select ${error ? "error" : ""}`} id={id} name={name} onChange={onChangeHandler} disabled={disabled}
                defaultValue={"DEFAULT"} value={defaultValue} required>
                    {loading && <option value={"DEFAULT"}>Loading...</option>}
                    <option value="DEFAULT" disabled hidden>{placeholder}</option>
                    {
                        stmsJob ?
                        <>
                            {options.map((opt, i) => (
                            <option key={i} value={opt.id}>{opt.is_internal ? opt.student_record.first_name + " " + opt.student_record.middle_name + " " + opt.student_record.last_name: opt.name}</option>
                        ))}
                        </> :
                        <>
                            {options.map((opt, i) => (
                            <option key={i} value={opt.value}>{withID ? ` ${opt.value} - ${opt.name}` : opt.name}</option>
                        ))}
                        </>
                    }
            </select>
            <label className="select-lebel" htmlFor={id}>{placeholder}</label>
            { required ? <p className="required">*</p> : <></>}
        </div>
            {error && <span className="error-message">{errorMessage}</span>}
        </div>
    )
}

export default Dropdown