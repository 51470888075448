import React, {useState, useEffect} from 'react'
import {Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import "./index.css"
import CircularProgress from '@mui/material/CircularProgress';

import { FiEye } from "react-icons/fi"
import TextInput from '../../components/TextInput'
import FilledSquareButton from '../../components/Button/FilledSquareButton'
import { login } from '../../actions/auths'

import logo from "./../../images/logo.png"


function Login() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    const message = useSelector((state) => state.message)
    const [passwordType, setPasswordType] = useState(true)
    const [loginCredentials, setLoginCredentials] = useState({username:"", password:""})
    const [loading, setLoading] = useState(false)

    const onLogin = (event) => {
        setLoading(true)
        event.preventDefault()
        dispatch(login(loginCredentials.username, loginCredentials.password))
        .then(response => {
            if(response === 200) navigate('/setting')
            setLoading(false)
        })
    }

    const loginCredentialsHandler = (event) => {
        setLoginCredentials({...loginCredentials, [event.target.name]:event.target.value})
    }

    // if(isAuthenticated){
    //     navigate("/setting")
    // }

    const eyeIcon = () => (
        <FiEye style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer", zIndex: "10"}} size="18" onClick={() => setPasswordType(!passwordType)}/> 
    )
    
    return (
        <div className="login-page">
            <div className="login-body">
                <img src={logo} alt="QATEK Logo"/>
                <p style={{fontSize:'1.25rem', margin:'1rem 0rem'}}>QATEK Expense Management System</p>
                <div>
                    <TextInput id="username" name="username" placeholder="Username" onChangeHandler={loginCredentialsHandler} 
                    error={message.status>400} value={loginCredentials.username}/>
                    <TextInput id="password" name="password" placeholder="Password" value={loginCredentials.password}
                                onChangeHandler={loginCredentialsHandler} type={passwordType ? "password" : "text" } icon={eyeIcon}
                                error={message.status>400} errorMessage={message.msg}/>
                    <p className="normal-text" style={{fontSize:".8rem"}}>Forget Password? <Link to="/reset-password-request" style={{color: "#006CB0", textDecoration: 'none'}}>Reset Password</Link></p>
                    {loading ? 
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress size={27}/>
                        </div> :
                        <FilledSquareButton onClickHandler={onLogin} name="Login" size="large" />}
                    <p className="normal-text" style={{fontSize:".8rem"}}>Don't have an account? 
                        <span>
                            <Link to="/register" style={{color: "#006CB0",textDecoration: "none"}}>Register here.</Link>
                        </span>
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Login