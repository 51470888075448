import React from 'react'
import "./textInput.css"

function TextInput({id, name, placeholder, onChangeHandler, errorMessage, size="medium", type="text", step, healperText=false,
                    error=false, value="", readOnly=false, icon, required=false, list=false, onKeyPress }) {
  return (
    <div style={{display: 'flex', flexDirection:"column", alignItems:"center"}}>
      {/* <FiSearch style={{position:"absolute", top:"1rem", right:"1rem", zIndex: "10"}} size="20"/>  */}
      <div style={{position:"relative"}}>
        {icon && icon()}
        <input 
              type={type} 
              className={`text-input ${size}-inp ${error ? "error" : ""}`} 
              id={id} 
              name={name} 
              value={value}
              step={step}
              readOnly={readOnly}
              // placeholder={placeholder}
              required={required}
              onKeyPress={onKeyPress}
              list={list ? "options" : ""}
              onChange={onChangeHandler}/>
        <label className="label" htmlFor={id}>{placeholder}</label>
        { required && <p className="required">*</p>}
      </div>
      {healperText && <span className="error-message healp-text">{healperText}</span>}
      {error && <span className="error-message">{errorMessage}</span>}
    </div>
  )
}

export default TextInput