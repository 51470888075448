import React, { useEffect, useState } from 'react'
import TextInput from '../../../components/TextInput'
import Dropdown from '../../../components/Dropdown'

function Address({handleIncomeData, incomeInformation, readOnly, error}) {
    const [isReadOnly, setIsReadOnly] = useState({})

    useEffect(() => {
        if(readOnly === true) {
            let x = {}
            x['country'] = incomeInformation.country ? true : false
            x['state'] =incomeInformation.state ? true : false
            x['city'] =incomeInformation.city ? true : false
            x['post_code'] = incomeInformation.post_code ? true : false
            x['street_address'] = incomeInformation.street_address ? true : false

            setIsReadOnly(x)
        }
    }, [])

    return (
        <div className="information-block">
        <p className="information-block-header">Address</p>
            <div className="information-row">
                <div>
                    <TextInput id="country" name="country" placeholder="Country" value={incomeInformation.country} type="text" size="medium" 
                        onChangeHandler={handleIncomeData} readOnly={isReadOnly.country} error={error.country || false} errorMessage={error.country && error.country[0]}/>
                </div>
                <div>
                    <TextInput id="state" name="state" placeholder="State" value={incomeInformation.state} type="text" onChangeHandler={handleIncomeData} 
                        size="medium" readOnly={isReadOnly.state} error={error.state || false} errorMessage={error.state && error.state[0]}/>
                </div>
                <div>
                    <TextInput id="city" name="city" placeholder="City" value={incomeInformation.city} type="text" size="medium" 
                        onChangeHandler={handleIncomeData} readOnly={isReadOnly.city} error={error.city || false} errorMessage={error.city && error.city[0]}/>
                </div>
                <div>
                    <TextInput id="post_code" name="post_code" placeholder="Post Code" value={incomeInformation.post_code} type="number"  size="medium"
                        onChangeHandler={handleIncomeData} readOnly={isReadOnly.post_code} error={error.post_code || false} errorMessage={error.post_code && error.post_code[0]}/>
                </div>
                <div>
                    <TextInput id="street_address" name="street_address" placeholder="Street" value={incomeInformation.street_address} size="extra_large"
                        type="text" onChangeHandler={handleIncomeData} readOnly={isReadOnly.street_address} error={error.street_address || false} errorMessage={error.street_address && error.street_address[0]}/>
                </div>
                
            </div>
        </div>      
    )
}

export default Address