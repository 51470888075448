import {
    LOGIN_SUCCESS, LOGIN_FAIL, USER_LOADED_SUCCESS,
    USER_LOADED_FAIL, AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL, REFRESH_TOKEN_USED_SUCCESS,
    REFRESH_TOKEN_USED_FAIL, PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL, PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL, SIGNUP_SUCCESS,
    SIGNUP_FAIL, ACTIVATION_SUCCESS, ACTIVATION_FAIL,
    LOGOUT, NETWORK_ERROR, AUTHORIZED_FAIL, ADD_MESSAGE, REMOVE_MESSAGE
} from '../actions/types';

const initialState = {
    msg: null,
    status: 0
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case LOGIN_FAIL:
            return {
                ...state,
                msg: payload.msg, status:payload.status
            }
        case ADD_MESSAGE:
            return {
                ...state,
                msg: payload.msg, status: payload.status
            }
        case REMOVE_MESSAGE:
            return {
                ...state,
                msg:null, status:0
            }
        default:
            return state
    }
}