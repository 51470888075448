import React, {useState, useEffect} from 'react'
import Dropdown from '../../components/Dropdown'
import TextInput from '../../components/TextInput'
import Chart from "react-apexcharts";

import {expenseBreakdown} from '../../actions/dashboard'
import { useDispatch, useSelector } from "react-redux"


function ExpenseBreakdown() {
    const [years, setYears] = useState([])
    const [year, setYear] = useState(new Date().getFullYear())
    const [series, setSeries] = useState({})
    const [labels, setLabels] = useState({})
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(expenseBreakdown())
        .then((response) => {
            if(response.status === 200){
                let label = []
                setSeries(prevState => ({...prevState, quarterly: response.data.per}))
                for (const data in response.data.cat)
                    label.push(data)
                setLabels(prevState => ({...prevState, quarterly: label}))
            }
        })

        dispatch(expenseBreakdown({range: 'half_yearly'}))
        .then((response) => {
            if(response.status === 200){
                let label = []
                setSeries(prevState => ({...prevState, half_yearly: response.data.per}))
                for (const data in response.data.cat)
                    label.push(data)
                setLabels(prevState => ({...prevState, half_yearly: label}))
            }
        })

        dispatch(expenseBreakdown({range: 'yearly'}))
        .then((response) => {
            if(response.status === 200){
                let label = []
                setSeries(prevState => ({...prevState, yearly: response.data.per}))
                for (const data in response.data.cat)
                    label.push(data)
                setLabels(prevState => ({...prevState, yearly: label}))
            }
        })
    }, [])

    // const s = [44, 55, 41, 17, 15, 44, 55, 41, 17, 15, 89, 90]
    // const l = ["x", "y", "z", "a", "b", "x", "y", "z", "a", "b", "c", "d"]
    const options = {
        chart: {
            type: 'donut',
        },
        legend: {show: false},
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 300
                },
                legend: {
                    show: false,
                }
            }
        }]
    }

    useEffect(() => {
        if(year.from || year.to) {
            dispatch(expenseBreakdown(year))
            .then((response) => {
                if(response.status === 200){
                    let label = []
                    if (response.data.per.length === 0)
                        setSeries(prevState => {
                            const {range, ...rest} = prevState
                            return rest
                        })
                    else setSeries(prevState => ({...prevState, range: response.data.per}))
                    for (const data in response.data.cat)
                        label.push(data)
                    setLabels(prevState => ({...prevState, range: label}))
                }
            })
        }
    }, [year])

    useEffect(() => {
        let current= [];
        for(let i=0;i<10;i++)
            current.push({name:new Date().getFullYear()-i, value:new Date().getFullYear()-i})
        setYears(current)
    }, [])

    const changeYearHandler = (event) => {
        setYear(event.target.value)
    }

    const handleFilterData = (event) => {
        setYear(prevState => ({...prevState, [event.target.name]:event.target.value}))
    }

    return (
        <div className="information-block">
            <div style={{display: 'flex', justifyContent:"space-between", flexWrap: "wrap"}}>
                <b className="information-block-header">Expense Breakdown<small style={{ marginLeft:  8}}><i>(Please select the date range)</i></small></b>
                <div style={{display: 'flex', justifyContent:"flex-end", flexWrap: "wrap"}}>
                    <TextInput id="from" name="from" placeholder="From" type="date"  size="small" onChangeHandler={handleFilterData} 
                        value={year.from}/>
                    <TextInput id="to" name="to" placeholder="To" type="date"  size="small" onChangeHandler={handleFilterData} 
                        value={year.to}/>
                    {/* <Dropdown id="year" name="year" placeholder="Time Period" options={years} 
                                onChangeHandler={changeYearHandler} size="small" defaultValue={year || "DEFAULT"}/>
                    <Dropdown id="year" name="year" placeholder="Select Year" options={years} 
                                onChangeHandler={changeYearHandler} size="small" defaultValue={year || "DEFAULT"}/> */}
                </div>
            </div>
            <div className="information-row">
            
                {series.quarterly ? <div id="chart" style={{width:"30%"}}>
                    <Chart options={{...options, labels:labels.quarterly, title: {text:"Quarterly"}}} series={series.quarterly} type="donut" width="100%" />
                </div> : <></>}

                {series.half_yearly ? <div id="chart" style={{width:"30%"}}>
                    <Chart options={{...options, labels:labels.half_yearly, title: {text:"Half Yearly"}}} series={series.half_yearly} type="donut" width="100%" />
                </div> : <></>}

                {series.yearly ? <div id="chart" style={{width:"30%"}}>
                    <Chart options={{...options, labels:labels.yearly, title: {text:"Yearly"}}} series={series.yearly} type="donut" width="100%"/>
                </div> : <></>}

                {series.range ? <div id="chart" style={{width:"30%"}}>
                    <Chart options={{...options, labels:labels.range, title: {text:"Custom"}}} series={series.range} type="donut" width="100%" />
                </div> : <></>}
                
            </div>
        </div>
    )
}

export default ExpenseBreakdown