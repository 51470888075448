import React, {useState, useEffect} from 'react'
import './profile.css'

import { userProfileGet, userProfileUpdate } from '../../actions/auths'
import {useDispatch} from 'react-redux'

import TextInput from './../../components/TextInput'
import FilledSquareButton from './../../components/Button/FilledSquareButton'
import TextArea from './../../components/TextArea'
import Tab from './../../components/Tab';
import axios from 'axios'
import { getHeader } from '../../utility'

function Profile() {
    const dispatch = useDispatch()
    const [userCredintial, setUserCredintial] = useState({})
    const [userCredintialError, setUserCredintialError] = useState({password:false, confirm_password: false})
    const [tabs, setTabs] = useState([ {name: 'Information', active: true}, {name: 'Security', active: false}])
    const [isLoaded, setLoaded] = useState(false)

    useEffect(() => {
        dispatch(userProfileGet())
            .then(response => {
                if(response.status === 200){
                    setUserCredintial(response.data)
                    setLoaded(true)
                }
            })
    }, [])

    // useEffect(() => {
    //   const getStudentList = async () => {
    //     await axios({
    //         method: 'GET',
    //         url: 'http://127.0.0.1:8000/records/partner-api/student-list/',
    //         headers: getHeader()
    //     })
    //     .then((response) => {
    //         console.log(response.data);
    //     })
    //     .error((error) => {
    //         console.log(error);
    //     })
    //   }
    //   getStudentList()
    // }, [])
    

    const userCredentialsHandler = (event) => {
        const name = event.target.name
        if(name === "phone_number" || name === "address")
            setUserCredintial(prevState => ({...prevState, user_information:{...prevState.user_information, [name]: event.target.value}}))
        else setUserCredintial(prevState => ({...prevState, [event.target.name]: event.target.value}))
    }

    const onUpdate = () => {
        dispatch(userProfileUpdate(userCredintial))
            .then(response => {
                if(response.status === 200){
                    setUserCredintial(response.data)
                } else {
                    let errorMessage = {password:false, confirm_password: false}
                    if("password" in response.data)
                        errorMessage.password = response.data.password
                    if("confirm_password" in response.data)
                        errorMessage.confirm_password = response.data.confirm_password
                    setUserCredintialError(errorMessage)
                }
            })
    }

    const tabChangeHandler = (tab) => {
        const flag = tabs.map(t => t.name === tab.name ? {...t, active: true} : {...t, active: false})
        let copyUser = {...userCredintial}
        delete copyUser.password
        delete copyUser.new_password
        delete copyUser.confirm_password
        setUserCredintial(copyUser)
        setTabs(flag)
    }

    const userDetails = () => (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <TextInput id="username" name="username" placeholder="Username" onChangeHandler={() => console.log("Not allowed to edit")} 
                value={userCredintial.username} size="large" readOnly={true}/>
            <div className="inline-form">
                <TextInput id="first_name" name="first_name" placeholder="First Name" size="large-inline" onChangeHandler={userCredentialsHandler}
                    value={userCredintial.first_name}/>
                <TextInput id="last_name" name="last_name" placeholder="Last Name" size="large-inline" onChangeHandler={userCredentialsHandler}
                    value={userCredintial.last_name}/>
            </div>
            <TextInput id="email" name="email" placeholder="Email" onChangeHandler={userCredentialsHandler} 
                value={userCredintial.email} size="large"/>
            <TextInput id="phone_number" name="phone_number" placeholder="Phone Number" onChangeHandler={userCredentialsHandler} 
                value={userCredintial.user_information.phone_number} size="large"/>

            <TextInput id="branch" name="branch" placeholder="Branch" onChangeHandler={() => console.log("Not allowed to edit")} 
                value={userCredintial.user_information.branch} size="large" readOnly={true}/>
            <TextInput id="role" name="role" placeholder="Role" onChangeHandler={() => console.log("Not allowed to edit")} 
                value={userCredintial.user_information.role} size="large" readOnly={true}/>
            <TextInput id="designation" name="designation" placeholder="Designation" onChangeHandler={() => console.log("Not allowed to edit")} 
                value={userCredintial.user_information.designation} size="large"  readOnly={true}/>
            <TextArea id="address" name="address" placeholder="Address" onChangeHandler={userCredentialsHandler} 
                value={userCredintial.user_information.address} size="large"/>
            <FilledSquareButton onClickHandler={onUpdate} name="Update" size="large"/>
        </div>
    )

    const userSecurity = () => (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <TextInput id="password" name="password" placeholder="Current Password" value={userCredintial?.password} error={userCredintialError.password}
                    errorMessage={userCredintialError.password} type="password" onChangeHandler={userCredentialsHandler} size="large"/>
            <TextInput id="new_password" name="new_password" placeholder="New Password" type="password" value={userCredintial?.new_password}
                    onChangeHandler={userCredentialsHandler} size="large"/>
            <TextInput id="confirm_password" name="confirm_password" placeholder="Confirm Password" value={userCredintial.confirm_password} error={userCredintialError.confirm_password}
                    errorMessage={userCredintialError.confirm_password} type="password" onChangeHandler={userCredentialsHandler} size="large"/>
            
            <FilledSquareButton onClickHandler={onUpdate} name="Change Password" size="large"/>
        </div>
    )

    return (
        <div>
            <Tab tabList={tabs} onClickHandler={tabChangeHandler}/>
            <div className="user-profile">
                {isLoaded && (tabs[0].active ? userDetails() : userSecurity())}
            </div>
        </div>

    )
}

export default Profile