import React, { useState, useEffect } from 'react'
import './index.css'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"

import FilledRoundedButton from "../../components/Button/FilledRoundedButton"
import SimpleTable from "../../components/Table/SimpleTable"
import Modal from "../../components/Modal"
import TextInput from '../../components/TextInput'

import { getVendors, createVendor, deleteVendor, updateVendor, getSingleVendor } from "../../actions/comments"
import axios from 'axios'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { FiSearch } from 'react-icons/fi'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const tableHeader = [
    { name: "Action", size: "small" },
    { name: "ID", size: "medium", id: "vendor_id" },
    { name: "Name", size: "medium", id: "name" },
    { name: "Email", size: "medium", id: "email" },
    { name: "Phone", size: "medium", id: "phone_number" },
    { name: "Currency", size: "medium", id: "currency" },
    { name: "Address", size: "medium", id: "address" },
]

function Vendor() {
    const dispatch = useDispatch()
    const [openCreateModal, setOpenCreateModal] = useState(false)
    const [vendorInformation, setVendorInformation] = useState({})
    const [vendors, setVendors] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [error, setError] = useState({})
    const [searchKey, setSearchKey] = useState('')

    const navigate = useNavigate()
    const role = useSelector((state) => state.auth.role)

    const [open, setOpen] = React.useState(false);


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        !role.can_manage_vendor && navigate(-1)
        let query_params = {}
        query_params['page'] = page
        if (searchKey) {
            query_params['search'] = searchKey
        }
        dispatch(getVendors(query_params))
            .then((response) => {
                if (response.status === 200) {
                    setVendors(response.data.data)
                    const itemsPerPage = response.data.per_page || 10;
                    const totalPages = Math.ceil(response.data.total / itemsPerPage);
                    setTotalPage(totalPages);
                }
            })
    }, [page]);

    const handlePage = (event, value) => {
        setPage(value)
    }

    const onEnterPress = (event) => {
        if (event.key === 'Enter') {
            let query_params = {}
            query_params['page'] = page
            if (searchKey) {
                query_params['search'] = searchKey
            }
            console.log(query_params);
            dispatch(getVendors(query_params))
                .then((response) => {
                    if (response.status === 200) {
                        setVendors(response.data.data)
                        const itemsPerPage = response.data.per_page || 10;
                        const totalPages = Math.ceil(response.data.total / itemsPerPage);
                        setTotalPage(totalPages);
                    }
                })
        }
    }

    const handleVendorInformation = (event) => {
        setVendorInformation(prevState => ({ ...prevState, [event.target.name]: event.target.value }))
    }
    const searchIcon = () => (
        <FiSearch style={{ position: "absolute", top: "1rem", right: "1rem", zIndex: "10" }} size="20" />
    )
    const getVendor = (obj) => {
        dispatch(getSingleVendor(obj.id))
            .then(response => {
                if (response.status === 200) {
                    setIsUpdate(obj.id)
                    setOpenCreateModal(true)
                    setVendorInformation(response.data)
                }
            })
    }

    const handleDeleteVendor = (obj) => {
        dispatch(deleteVendor(obj.id))
            .then(response => {
                if (response.status === 204) {
                    let newVendor = vendors.filter(vendor => vendor.id !== obj.id)
                    setVendors(newVendor)
                }
            })
    }

    const handleEditVendor = (obj) => {
        dispatch(updateVendor(isUpdate, vendorInformation))
            .then(response => {
                if (response.status === 200) {
                    let x = {
                        ...response.data,
                        name: response.data.first_name + " " + response.data.last_name,
                        address: response.data.city + "-" + response.data.country
                    }
                    delete x.first_name
                    delete x.last_name
                    delete x.city
                    delete x.country
                    let newVendorList = vendors.map(vendor => vendor.id === response.data.id ? x : vendor)
                    setVendors(newVendorList)
                    setOpenCreateModal(false)
                } else {
                    setError(prevState => ({ ...prevState, ...response.data }))
                }
            })
    }


    const submitVendor = () => {
        axios({
            method: 'POST',
            url: 'expense/manage-vendor/',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('access')
            },
            data: {
                first_name: vendorInformation.first_name ? vendorInformation.first_name : null,
                last_name: vendorInformation.last_name ? vendorInformation.last_name : null,
                email: vendorInformation.email ? vendorInformation.email : null,
                currency: vendorInformation.currency ? vendorInformation.currency : null,
                phone_number: vendorInformation.phone_number ? vendorInformation.phone_number : null,
                website: vendorInformation.website ? vendorInformation.website : null,
                country: vendorInformation.country ? vendorInformation.country : null,
                state: vendorInformation.state ? vendorInformation.state : null,
                address: vendorInformation.address ? vendorInformation.address : null,
                city: vendorInformation.city ? vendorInformation.city : null,
                zip_code: vendorInformation.zip_code ? vendorInformation.zip_code : null
            }
        }).then((response) => {
            if (response.status === 201) {
                let x = {
                    ...response.data,
                    name: response.data.first_name + " " + response.data.last_name,
                    address: (response.data.city || "") + "-" + (response.data.country || "")
                }
                setVendors([x, ...vendors])
                setOpenCreateModal(false)
                setVendorInformation({})
                setError({})
                setOpen(true)
            }
        })
    }

    const tableBody = (data) => (tableHeader.map(obj => obj.id && <td>{data[obj.id] || ""}</td>))

    const createVendorModalBody = () => (
        <>
            <div className="align-center">
                <TextInput id="first_name" name="first_name" placeholder="First Name" valuetype="text" value={vendorInformation.first_name}
                    error={error.first_name || false} errorMessage={error.first_name && error.first_name[0]} onChangeHandler={handleVendorInformation} size="medium" />
                <TextInput id="last_name" name="last_name" placeholder="Last Name" valuetype="text" value={vendorInformation.last_name}
                    error={error.last_name || false} errorMessage={error.last_name && error.last_name[0]} onChangeHandler={handleVendorInformation} size="medium" />
            </div>
            <div className="align-center">
                <TextInput id="email" name="email" placeholder="Email" valuetype="email" value={vendorInformation.email} onChangeHandler={handleVendorInformation}
                    error={error.email || false} errorMessage={error.email && error.email[0]} size="medium" />
                <TextInput id="currency" name="currency" placeholder="Currency" valuetype="text" value={vendorInformation.currency} onChangeHandler={handleVendorInformation} size="medium" />
            </div>
            <div className="align-center">
                <TextInput id="phone_number" name="phone_number" placeholder="Phone Number" valuetype="text" value={vendorInformation.phone_number}
                    error={error.phone_number || false} errorMessage={error.phone_number && error.phone_number[0]} onChangeHandler={handleVendorInformation} size="medium" />
                <TextInput id="website" name="website" placeholder="Website" valuetype="text" value={vendorInformation.website} onChangeHandler={handleVendorInformation} size="medium" />
            </div>
            <div className="information-block">
                <p>Address</p>
                <div className="align-center">
                    <TextInput id="city" name="city" placeholder="City" type="text" value={vendorInformation.city} onChangeHandler={handleVendorInformation} size="small" />
                    <TextInput id="state" name="state" placeholder="State" type="text" value={vendorInformation.state} onChangeHandler={handleVendorInformation} size="small" />
                    <TextInput id="country" name="country" placeholder="Country" type="text" value={vendorInformation.country} onChangeHandler={handleVendorInformation} size="medium" />
                </div>
                <div className="align-center">
                    <TextInput id="address" name="address" placeholder="Street Address" type="text" value={vendorInformation.address} onChangeHandler={handleVendorInformation} size="large" />
                    <TextInput id="zip_code" name="zip_code" placeholder="Postal Code" type="number" value={vendorInformation.zip_code} onChangeHandler={handleVendorInformation} size="small" />
                </div>
            </div>
            <FilledRoundedButton onClickHandler={isUpdate ? handleEditVendor : submitVendor} name={isUpdate ? "Update Vendor" : "Create Vendor"} color="green" size="large" />
        </>
    )

    return (
        <div>
            <Modal header="Add a New Vendor" body={createVendorModalBody} isOpen={openCreateModal} modalHandler={() => {
                setOpenCreateModal(false)
                setVendorInformation({})
                setIsUpdate(false)
                setError({})
            }} />
            <div className="layout-title">
                <p>Vendors</p>
                <div className="align-right">
                    <FilledRoundedButton name="Add Vendor" color="green" size="small" onClickHandler={() => setOpenCreateModal(true)} />
                </div>
            </div>
            <div style={{ margin: "1rem" }}>
                <TextInput id="search" name="search" placeholder="Search" value={searchKey} onKeyPress={onEnterPress}
                    icon={searchIcon} onChangeHandler={(event) => setSearchKey(event.target.value)} />
                <SimpleTable header={tableHeader} values={vendors} onDelete={handleDeleteVendor} onEdit={getVendor}
                    tableBody={tableBody} page={page} handlePage={handlePage} totalPage={totalPage} />
            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    You have created a vendor!
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Vendor