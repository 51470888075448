import React, {useState} from 'react'
import "./modal.css"

function Modal({header, body, isOpen, modalHandler}) {
    return (
        <div id="myModal" className={`modal ${isOpen ? "modal-open" : ""}`}>
            <div className="modal-body">
                <div className="modal-header">
                    <h3>{header}</h3>
                    <span className="close" onClick={modalHandler}>&times;</span>
                </div>
                <div className="modal-content">
                    {body()}
                </div>
            </div>
        </div>
    )
}

export default Modal