// import React, { useState, useEffect, useRef } from 'react'

// import { getTransitions, exportFile } from '../../actions/transaction'
// import { getVendors } from '../../actions/comments'
// import { useDispatch, useSelector } from 'react-redux'
// import { getBranches } from '../../actions/role'
// import { getEmployeesContructor } from "../../actions/employee"
// import { userTime } from '../../utility/index'
// import Popover from '@mui/material/Popover';
// import Button from '@mui/material/Button';

// import ButtonOulined from '../../components/Button/ButtonOulined'

// import { CSVLink } from 'react-csv';
// import jsPDF from "jspdf";
// import "jspdf-autotable";

// import Filter from './Filter'
// import Comment from '../Comment'
// import AddExpense from '../Expense/AddExpense'
// import SimpleTable from '../../components/Table/SimpleTable'
// import { useNavigate } from 'react-router-dom'
// import axios from 'axios'

// import FilledRoundedButton from "../../components/Button/FilledRoundedButton"

// const tableHeader = [
//     { name: "Action", size: "small" },
//     { name: "Date", size: "medium" },
//     { name: "Description", size: "medium" },
//     { name: "Type", size: "medium" },
//     { name: "Amount", size: "medium" },
//     { name: "Branch", size: "medium" },
// ]

// const csv_headers = [
//     { label: 'Date', key: 'date' },
//     { label: 'Type', key: 'type' },
//     { label: 'Branch', key: 'branch' },
//     { label: 'Details', key: 'description' },
//     { label: "Amount", key: "amount" }
// ]

// function Transaction() {
//     const dispatch = useDispatch()
//     const navigate = useNavigate()
//     const ref = useRef()
//     const role = useSelector(state => state.auth.role)

//     const [anchorEl, setAnchorEl] = useState(null);
//     const [openExport, setOpenExport] = useState(false);

//     const [transactions, setTransactions] = useState([])
//     const [openLog, setOpenLog] = useState(false)
//     const [openModal, setOpenModal] = useState(false)
//     const [expense, setExpenses] = useState({})
//     const [branches, setBranches] = useState([])
//     const [employee, setEmployee] = useState([])
//     const [vendors, setVendors] = useState([])
//     const [exportData, setExportData] = useState([])
//     const [filterOpen, setOpen] = useState(false);
//     const [filterParameter, setFilterParameter] = useState({});
//     const [page, setPage] = useState(1)
//     const [totalPage, setTotalPage] = useState(0)

//     useEffect(() => {
//         !role.can_see_transaction && navigate(-1)
//     }, [])

//     useEffect(() => {
//         role.can_see_transaction && fetchTransactionsData()
//     }, [filterParameter])

//     // const fetchTransactionsData = (type = null) => {
//     //     let query_param = {}
//     //     if (type) query_param['page_size'] = totalPage * 50
//     //     else query_param['page'] = page

//     //     dispatch(getTransitions(filterParameter, query_param))
//     //         .then((response) => {
//     //             if (response.status === 200) {
//     //                 if (type === "csv") {
//     //                     setExportData(response.data.map(trans => {
//     //                         let { expense, income_job_support, income_software_training, ...data } = trans
//     //                         return { ...expense, ...income_job_support, ...income_software_training, ...data }
//     //                     }))
//     //                     ref.current.link.click()
//     //                 }
//     //                 else if (type === "pdf") {
//     //                     generatePDF(response.data.map(trans => {
//     //                         let { expense, income_job_support, income_software_training, ...data } = trans
//     //                         return { ...expense, ...income_job_support, ...income_software_training, ...data }
//     //                     }))
//     //                 }
//     //                 setTransactions(response.data)
//     //                 setTotalPage(response.totalPage)
//     //             } else setTransactions([])
//     //         })
//     // }

//     const fetchTransactionsData = (type = null) => {
//         let query_param = {};
//         if (type) query_param['page_size'] = totalPage * 50;
//         else query_param['page'] = page;

//         dispatch(getTransitions(filterParameter, query_param))
//             .then((response) => {
//                 console.log('Response:', response); // Add this line for logging

//                 if (response.status === 200) {
//                     if (type === "csv") {
//                         setExportData(response.data.map(trans => {
//                             let { expense, income_job_support, income_software_training, ...data } = trans;
//                             return { ...expense, ...income_job_support, ...income_software_training, ...data };
//                         }));
//                         ref.current.link.click();
//                     }
//                     else if (type === "pdf") {
//                         generatePDF(response.data.map(trans => {
//                             let { expense, income_job_support, income_software_training, ...data } = trans;
//                             return { ...expense, ...income_job_support, ...income_software_training, ...data };
//                         }));
//                     }
//                     setTransactions(response.data);
//                     setTotalPage(response.totalPage);
//                 } else {
//                     setTransactions([]);
//                 }
//             })
//             .catch((error) => {
//                 console.error('Error:', error); // Add this line for logging errors
//             });
//     };


//     useEffect(() => {
//         Object.keys(filterParameter).length && setFilterParameter({})
//     }, [filterOpen])

//     useEffect(() => {
//         setFilterParameter(prevState => ({ ...prevState, page: page }))
//     }, [page])

//     useEffect(() => {
//         if (expense.expense_type === "transaction" || filterOpen) {
//             if (branches.length === 0) {
//                 dispatch(getBranches())
//                     .then(response => {
//                         if (response.status === 200) {
//                             let x = response.data.map(branch => ({ name: branch.name, value: branch.id }))
//                             setBranches(x)
//                         }
//                     })
//             }
//         } else if (expense.expense_type === "salary") {
//             if (employee.length === 0) {
//                 dispatch(getEmployeesContructor('views=options'))
//                     .then(response => {
//                         if (response.status === 200) {
//                             setEmployee(response.data)
//                         }
//                     })
//             }
//         }

//         if (vendors.length === 0) {
//             dispatch(getVendors(`?options=${true}`))
//                 .then((response) => {
//                     if (response.status === 200) {
//                         setVendors(response.data)
//                     }
//                 })
//         }
//     }, [expense, filterOpen])

//     const handleFilterData = (event) => {
//         const name = event.target.name
//         let value = event.target.value
//         setFilterParameter(prevState => ({ ...prevState, [name]: value }))
//     }

//     const editTransaction = (data) => {
//         if (data.type === "Deposit") navigate(`/income/update/${data.income_software_training?.id || data.income_job_support.id}/?type=${data.income_software_training ? "training" : "support"}`, { state: { type: "update" } })
//         else if (data.type === "Withdrawal") {
//             setOpenModal(true)
//             // console.log({ id: data.expense.id, transaction_type: data.expense.transaction_type, expense_type: data.expense.expense_type })
//             setExpenses({ id: data.expense.id, transaction_type: data.expense.transaction_type, expense_type: data.expense.expense_type })
//         }
//     }
//     const onMore = (data) => { setOpenLog(data.id) }

//     const tableBody = (tran) => {
//         console.log(tran)
//         let data = { type: tran.type, amount: tran.amount, branch: tran.branch, ...tran.expense, ...tran.income_job_support, ...tran.income_software_training }
//         data['date'] = userTime(data.date, 'DD MMM YYYY')
//         // let data = {...tran, description: tran.expense.description || tran.income_job_support.description || tran.income_software_training.description}
//         console.log(data)
//         return (
//             <>
//                 {tableHeader.map((col, key, array) => 0 !== key &&
//                     <td key={key} onClick={() => setOpenLog(false)}>
//                         {data[col.name.replace(" ", "_").toLowerCase()]}
//                     </td>)}
//             </>
//         )
//     }

//     const handleUpdateExpense = () => { }

//     const changePayType = (value) => {
//         setExpenses(prevState => ({ ...prevState, transaction_type: value }))
//     }

//     const handlePage = (event, value) => {
//         setPage(value)
//     }

//     const generatePDF = tickets => {

//         const doc = new jsPDF();

//         const tableColumn = ["Id", "Date", "Type", "Branch", "Details", "Amount"];
//         const tableRows = [];

//         tickets.map(transaction => {
//             transaction.created_at = userTime(transaction.created_at, 'DD MMM YYYY')
//             const transaction_row = [
//                 transaction.id, transaction.created_at, transaction.type, transaction.branch, transaction.description, transaction.amount
//             ];
//             tableRows.push(transaction_row)
//         })

//         doc.autoTable(tableColumn, tableRows, { startY: 20 });
//         doc.text("Transaction Data.", 14, 15);
//         const date = Date().split(" ");
//         const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
//         doc.save(`report_${dateStr}.pdf`);
//     };

//     return (
//         <div>
//             {openModal && <AddExpense isOpen={openModal} setIsOpen={setOpenModal} branches={branches} update={expense.id}
//                 vendors={expense.transaction_type === "transaction" ? vendors : employee} updateExpenses={handleUpdateExpense}
//                 view={expense.expense_type === "C" ? "company" : "employee"} changePayType={changePayType} payType={expense.expense_type} />}
//             <div className="layout-title">
//                 <p>Transaction</p>
//                 <div className="align-right">
//                     <ButtonOulined name="Export" onClickHandler={(event) => {
//                         setAnchorEl(event.currentTarget)
//                         setOpenExport(true)
//                     }} />
//                     <Popover
//                         open={openExport}
//                         anchorEl={anchorEl}
//                         onClose={() => {
//                             setAnchorEl(null)
//                             setOpenExport(false)
//                         }}
//                         anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left',
//                         }}
//                         sx={{ p: 2 }}
//                     >
//                         <Button variant="outlined" onClick={() => fetchTransactionsData("csv")}>CSV</Button>
//                         <Button variant="outlined" onClick={() => fetchTransactionsData("pdf")}>PDF</Button>
//                     </Popover>
//                     <CSVLink ref={ref} headers={csv_headers} data={exportData} />
//                     {/* <FilledRoundedButton name="Add Income" color="green" size="small"
//             onClickHandler={() => navigate('/income')}/>
//         <FilledRoundedButton name="Add Expense" color="green" size="small"
//             onClickHandler={() => navigate('/expense')}/> */}
//                 </div>
//             </div>
//             <div style={{ margin: "1rem" }}>
//                 <Filter branches={branches} filterOpen={filterOpen} setOpen={setOpen} handleFilterData={handleFilterData} filterParameter={filterParameter} />
//                 <div style={{ display: 'flex' }}>
//                     <div style={{ flex: 3, overflowX: "auto" }}>
//                         <SimpleTable header={tableHeader} values={transactions} onMore={onMore} onEdit={editTransaction} tableBody={tableBody}
//                             page={page} handlePage={handlePage} totalPage={totalPage} />
//                     </div>

//                     {openLog && <div style={{ flex: 1 }}>
//                         <Comment id={openLog} query={`transaction`} log={true} />
//                     </div>}
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Transaction



















// import React, { useEffect, useMemo, useState } from 'react';
// import axios from 'axios';
// import { useDispatch } from 'react-redux';
// import { getTransitions } from '../../actions/transaction';
// import FilledRoundedButton from "../../components/Button/FilledRoundedButton"
// import TextInput from '../../components/TextInput';
// import Loading from '../../components/Loading';
// import Pagination from '@mui/material/Pagination';
// import { CSVLink } from 'react-csv';

// const tableHeader = [
//     { name: "Date", size: "medium" },
//     { name: "Description", size: "medium" },
//     { name: "Type", size: "medium" },
//     { name: "Amount", size: "medium" },
//     { name: "Branch", size: "medium" },
// ];


// function Transaction() {
//     const dispatch = useDispatch();
//     const [combinedData, setCombinedData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [startDate, setStartDate] = useState('');
//     const [endDate, setEndDate] = useState('');
//     const [filterOpen, setFilterOpen] = useState(false);
//     const [typeFilter, setTypeFilter] = useState(null);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [branches, setBranches] = useState([]);
//     const [branchFilter, setBranchFilter] = useState('');

//     const toggleFilter = () => {
//         setFilterOpen(!filterOpen);
//         setStartDate(null);
//         setEndDate(null);
//         setTypeFilter("")
//         setBranchFilter("")
//     }

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 setLoading(true);
//                 // Fetch transactions data
//                 let allTransactions = [];
//                 try {
//                     for (let page = 1; page <= 10; page++) {
//                         const response = await dispatch(getTransitions({ page }));
//                         if (response.status === 200) {
//                             allTransactions = [...allTransactions, ...response.data];
//                         } else if (response.status === 404) {
//                             console.warn(`No data found for page ${page}`);
//                             break;
//                         } else {
//                             console.error(`Failed to fetch data for page ${page}`);
//                         }
//                     }
//                 } catch (error) {
//                     console.error('Error fetching data:', error);

//                 }
//                 const uniqueBranches = [...new Set(allTransactions.map(transaction => transaction.branch))];
//                 setBranches(uniqueBranches);

//                 // Fetch data from the job support API
//                 const jobSupportResponse = await axios.get('https://stms.qatekinternal.com/job-support/partner-api/job-support-list/', {
//                     headers: {
//                         'Authorization': `Bearer ${localStorage.getItem('access')}`,
//                         'Content-Type': 'application/json',
//                     },
//                 });
//                 const jobSupportData = jobSupportResponse.data.records;

//                 // Fetch data from the enrolled API
//                 const enrolledResponse = await axios.get('https://stms.qatekinternal.com/records/partner-api/enrolled-student-list/', {
//                     headers: {
//                         'Authorization': `Bearer ${localStorage.getItem('access')}`,
//                         'Content-Type': 'application/json',
//                     },
//                 });
//                 const enrolledData = enrolledResponse.data.records;

//                 // Combine the data into a unified structure
//                 const combinedData = [];

//                 allTransactions.forEach(transaction => {
//                     combinedData.push({
//                         date: (transaction.expense?.date || transaction?.income_software_training?.date ? transaction.expense?.date || transaction?.income_software_training?.date.split(" ")[0] : null),
//                         amount: transaction.amount,
//                         description: transaction.expense?.description || transaction?.income_software_training?.description,
//                         type: transaction.type,
//                         branch: transaction.branch
//                     });
//                 });


//                 jobSupportData.forEach(jobSupport => {
//                     const name = jobSupport?.name || `${jobSupport?.student_record?.first_name} ${jobSupport?.student_record?.last_name}` || 'Unknown';
//                     const id = jobSupport?.id;
//                     const enrolledProgram = jobSupport?.student_record?.enrolled_program || 'Not Given';

//                     // Check if deposit exists and is not "0.00"
//                     if (jobSupport.job_support_record_payment?.deposit && jobSupport.job_support_record_payment?.deposit !== "0.00") {
//                         combinedData.push({
//                             date: jobSupport.job_support_record_payment?.deposit_payment_date,
//                             amount: jobSupport.job_support_record_payment?.deposit,
//                             description: `${name}, ID: ${id}, ${enrolledProgram}, Deposit Payment (Job Support)`,
//                             type: 'Deposit',
//                             branch: 'Online'
//                         });
//                     }
//                 });


//                 jobSupportData.forEach(jobSupport => {
//                     const name = jobSupport?.name || `${jobSupport?.student_record?.first_name} ${jobSupport?.student_record?.last_name}` || 'Unknown';
//                     const id = jobSupport?.id;
//                     const enrolledProgram = jobSupport?.student_record?.enrolled_program || 'Not Given';
//                     if (jobSupport.job_support_record_payment?.first_payment_date) {
//                         combinedData.push({
//                             date: jobSupport.job_support_record_payment.first_payment_date,
//                             amount: jobSupport.job_support_record_payment.first_payment_status,
//                             description: `${name}, ID: ${id}, ${enrolledProgram}, First Installment (Job Support)`,
//                             type: 'Deposit',
//                             branch: 'Online'
//                         });
//                     }
//                 });

//                 jobSupportData.forEach(jobSupport => {
//                     const name = jobSupport?.name || `${jobSupport?.student_record?.first_name} ${jobSupport?.student_record?.last_name}` || 'Unknown';
//                     const id = jobSupport?.id;
//                     const enrolledProgram = jobSupport?.student_record?.enrolled_program || 'Not Given';
//                     if (jobSupport.job_support_record_payment?.second_payment_date) {
//                         combinedData.push({
//                             date: jobSupport.job_support_record_payment.second_payment_date,
//                             amount: jobSupport.job_support_record_payment.second_payment_status,
//                             description: `${name}, ID: ${id}, ${enrolledProgram}, Second Installment (Job Support)`,
//                             type: 'Deposit',
//                             branch: 'Online'
//                         });
//                     }
//                 });
//                 jobSupportData.forEach(jobSupport => {
//                     const name = jobSupport?.name || `${jobSupport?.student_record?.first_name} ${jobSupport?.student_record?.last_name}` || 'Unknown';
//                     const id = jobSupport?.id;
//                     const enrolledProgram = jobSupport?.student_record?.enrolled_program || 'Not Given';
//                     if (jobSupport.job_support_record_payment?.third_payment_date) {
//                         combinedData.push({
//                             date: jobSupport.job_support_record_payment.third_payment_date,
//                             amount: jobSupport.job_support_record_payment.third_payment_status,
//                             description: `${name}, ID: ${id}, ${enrolledProgram}, Third Installment (Job Support)`,
//                             type: 'Deposit',
//                             branch: 'Online'
//                         });
//                     }
//                 });

//                 enrolledData.forEach(enrolled => {
//                     const name = enrolled?.name;
//                     const id = enrolled?.id;
//                     const enrolledProgram = enrolled?.enrolled_program || 'Not Given';
//                     if (enrolled.payment_information?.enrollment_payment) {
//                         combinedData.push({
//                             date: enrolled.payment_information?.enrollment_payment_date,
//                             amount: enrolled.payment_information?.enrollment_payment,
//                             description: `${name}, ID: ${id}, ${enrolledProgram}, Enrollment Payment (Enrolled Student)`,
//                             type: 'Deposit',
//                             branch: enrolled.location || 'Not Given'
//                         });
//                     }

//                     // Check if first payment data exists
//                     if (enrolled.payment_information?.first_payment_date) {
//                         combinedData.push({
//                             date: enrolled.payment_information.first_payment_date,
//                             amount: enrolled.payment_information.first_payment_status,
//                             description: `${name}, ID: ${id}, ${enrolledProgram}, First Installment (Enrolled Student)`,
//                             type: 'Deposit',
//                             branch: enrolled.location || 'Not Given'
//                         });
//                     }
//                     // Check if second payment data exists
//                     if (enrolled.payment_information?.second_payment_date) {
//                         combinedData.push({
//                             date: enrolled.payment_information.second_payment_date,
//                             amount: enrolled.payment_information.second_payment_status,
//                             description: `${name}, ID: ${id}, ${enrolledProgram}, Second Installment (Enrolled Student)`,
//                             type: 'Deposit',
//                             branch: enrolled.location || 'Not Given'
//                         });
//                     }
//                     // Check if third payment data exists
//                     if (enrolled.payment_information?.third_payment_date) {
//                         combinedData.push({
//                             date: enrolled.payment_information.third_payment_date,
//                             amount: enrolled.payment_information.third_payment_status,
//                             description: `${name}, ID: ${id}, ${enrolledProgram}, Third Installment (Enrolled Student)`,
//                             type: 'Deposit',
//                             branch: enrolled.location || 'Not Given'
//                         });
//                     }
//                     // Check if fourth payment data exists
//                     if (enrolled.payment_information?.fourth_payment_date) {
//                         combinedData.push({
//                             date: enrolled.payment_information.fourth_payment_date,
//                             amount: enrolled.payment_information.fourth_payment_status,
//                             description: `${name}, ID: ${id}, ${enrolledProgram}, Fourth Installment (Enrolled Student)`,
//                             type: 'Deposit',
//                             branch: enrolled.location || 'Not Given'
//                         });
//                     }
//                 });

//                 // Update state with the combined data sorted by date (latest to oldest)
//                 setCombinedData(
//                     combinedData.sort((a, b) => {
//                         const dateA = a.date ? new Date(a.date) : 0;
//                         const dateB = b.date ? new Date(b.date) : 0;
//                         return dateB - dateA;
//                     })
//                 );
//                 setLoading(false);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//                 setLoading(false);
//             }
//         };
//         fetchData();
//     }, [dispatch]);


//     // Filter data based on the date range, type filter, and branch filter
//     const filteredData = useMemo(() => {
//         let filtered = combinedData;
//         if (startDate && endDate) {
//             filtered = filtered.filter(item => {
//                 const itemDate = new Date(item.date);
//                 const start = new Date(startDate);
//                 const end = new Date(endDate);
//                 return itemDate >= start && itemDate <= end;
//             });
//         }
//         if (typeFilter) {
//             filtered = filtered.filter(item => item.type === typeFilter);
//         }
//         if (branchFilter) {
//             filtered = filtered.filter(item => item.branch === branchFilter);
//         }
//         return filtered;
//     }, [combinedData, startDate, endDate, typeFilter, branchFilter]);



//     // Pagination
//     const itemsPerPage = 10;
//     const totalPages = Math.ceil(filteredData.length / itemsPerPage);
//     const paginatedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

//     const handlePageChange = (event, value) => {
//         setCurrentPage(value);
//     };


//     // Function to handle CSV data
//     const handleCSVData = () => {
//         return filteredData.map(item => ({
//             Date: item.date,
//             Description: item.description,
//             Type: item.type,
//             Amount: item.amount,
//             Branch: item.branch
//         }));
//     };

//     const selectStyle = {
//         backgroundColor: '#EAEFF4',
//         boxSizing: 'border-box',
//         border: '2px solid #CDCDCD',
//         borderRadius: '5px',
//         padding: '0.5rem',
//         margin: '0.5rem',
//         fontSize: '1rem',
//         width: '20rem'
//     };


//     return (
//         <div>
//             <div className="layout-title">
//                 <p>Transaction</p>
//                 <div className="align-right">
//                     <CSVLink
//                         data={handleCSVData()}
//                         filename={"transaction_data.csv"}
//                         style={{
//                             border: '1px solid #1976d2',
//                             color: '#1976d2',
//                             padding: '10px 20px',
//                             textAlign: 'center',
//                             textDecoration: 'none',
//                             display: 'inline-block',
//                             fontSize: '15px',
//                             margin: '4px 2px',
//                             cursor: 'pointer',
//                             borderRadius: '15px',
//                             transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
//                         }}
//                         onMouseEnter={(e) => {
//                             e.target.style.backgroundColor = '#1976d2';
//                             e.target.style.color = '#fff';
//                         }}
//                         onMouseLeave={(e) => {
//                             e.target.style.backgroundColor = 'transparent';
//                             e.target.style.color = '#1976d2';
//                         }}
//                     >
//                         Export CSV
//                     </CSVLink>
//                 </div>
//             </div>

//             <div>
//                 <div className="align-left border border-radius">
//                     <FilledRoundedButton name="Filters" size="medium" onClickHandler={toggleFilter} color="secondary" />
//                 </div>
//                 {filterOpen && (
//                     <div>
//                         <div className="filter-body-left">
//                             <TextInput id="from" name="from" placeholder="From" type="date" size="small" onChangeHandler={(e) => setStartDate(e.target.value)} value={startDate} />
//                             <TextInput id="to" name="to" placeholder="To" type="date" size="small" onChangeHandler={(e) => setEndDate(e.target.value)} value={endDate} />

//                             <div>
//                                 <label htmlFor="typeFilter" >Type:</label>
//                                 <select
//                                     id="typeFilter"
//                                     value={typeFilter}
//                                     onChange={(e) => setTypeFilter(e.target.value)}
//                                     style={selectStyle}
//                                 >
//                                     <option value="">All</option>
//                                     <option value="Deposit">Deposit</option>
//                                     <option value="Withdrawal">Withdrawal</option>
//                                 </select>
//                             </div>

//                             <div>
//                                 <label htmlFor="branchFilter">Branch:</label>
//                                 <select id="branchFilter"
//                                     value={branchFilter}
//                                     onChange={(e) => setBranchFilter(e.target.value)}
//                                     style={selectStyle}
//                                 >
//                                     <option value="">All</option>
//                                     {branches.map((branch, index) => (
//                                         <option key={index} value={branch}>{branch}</option>
//                                     ))}
//                                     <option value="Online">Online</option>
//                                 </select>
//                             </div>
//                         </div>
//                     </div>
//                 )}
//             </div>

//             {loading ? (
//                 <Loading />
//             ) : (
//                 <div>
//                     <div className="table-body">
//                         <table style={{ width: '100%' }}>
//                             <thead className='thead'>
//                                 <tr>
//                                     {tableHeader.map((th, i) => (
//                                         <th className={`${th.size}-header`} key={i}>{th.name}</th>
//                                     ))}
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {paginatedData.map((item, i) => (
//                                     <tr key={i}>
//                                         <td>{item.date || "Not Given"}</td>
//                                         <td>{item.description}</td>
//                                         <td>{item.type}</td>
//                                         <td>${item.amount}</td>
//                                         <td>{item.branch}</td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </div>

//                     <Pagination
//                         style={{ marginRight: '1rem', display: 'flex', justifyContent: 'right' }} color="primary"
//                         count={totalPages} variant="outlined" shape="rounded" page={currentPage} onChange={handlePageChange}
//                     />
//                 </div>
//             )}
//         </div>
//     );
// }

// export default Transaction;


















import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import FilledRoundedButton from '../../components/Button/FilledRoundedButton';
import TextInput from '../../components/TextInput';
import Loading from '../../components/Loading';
import Pagination from '@mui/material/Pagination';
import { CSVLink } from 'react-csv';
import { fetchAllData } from './FetchAllData';
import Dashboard from '../Dashboard';

const tableHeader = [
    { name: 'Date', size: 'medium' },
    { name: 'Description', size: 'medium' },
    { name: 'Type', size: 'medium' },
    { name: 'Amount', size: 'medium' },
    { name: 'Branch', size: 'medium' },
];

function Transaction() {
    const dispatch = useDispatch();
    const [combinedData, setCombinedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filterOpen, setFilterOpen] = useState(false);
    const [typeFilter, setTypeFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [branches, setBranches] = useState([]);
    const [branchFilter, setBranchFilter] = useState('');

    const toggleFilter = () => {
        setFilterOpen(!filterOpen);
        setStartDate('');
        setEndDate('');
        setTypeFilter('');
        setBranchFilter('');
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                // Fetch all data
                const allData = await fetchAllData(dispatch);

                // Process the fetched data
                const { allTransactions, jobSupportData, enrolledData, otherIncomeData } = allData;
                const uniqueBranches = [...new Set(allTransactions.map(transaction => transaction.branch))];
                setBranches(uniqueBranches);

                const combinedData = [];

                // Process all transactions
                // allTransactions.forEach(transaction => {
                //     combinedData.push({
                //         date: (transaction.expense?.date || transaction?.income_software_training?.date) ? (transaction.expense?.date || transaction?.income_software_training?.date.split(' ')[0]) : null,
                //         amount: transaction.amount,
                //         description: transaction.expense?.description || transaction?.income_software_training?.description,
                //         type: transaction.type,
                //         branch: transaction.branch
                //     });
                // });

                // // Process job support data
                // jobSupportData.forEach(jobSupport => {
                //     const name = jobSupport?.name || `${jobSupport?.student_record?.first_name} ${jobSupport?.student_record?.last_name}` || 'Unknown';
                //     const id = jobSupport?.id;
                //     const enrolledProgram = jobSupport?.student_record?.enrolled_program || 'Not Given';

                //     // Check if deposit exists and is not "0.00"
                //     if (jobSupport.job_support_record_payment?.deposit && jobSupport.job_support_record_payment?.deposit !== "0.00") {
                //         combinedData.push({
                //             date: jobSupport.job_support_record_payment?.deposit_payment_date,
                //             amount: jobSupport.job_support_record_payment?.deposit,
                //             description: `${name}, ID: ${id}, ${enrolledProgram}, Deposit Payment (Job Support)`,
                //             type: 'Deposit',
                //             branch: 'Online'
                //         });
                //     }
                // });


                // jobSupportData.forEach(jobSupport => {
                //     const name = jobSupport?.name || `${jobSupport?.student_record?.first_name} ${jobSupport?.student_record?.last_name}` || 'Unknown';
                //     const id = jobSupport?.id;
                //     const enrolledProgram = jobSupport?.student_record?.enrolled_program || 'Not Given';
                //     if (jobSupport.job_support_record_payment?.first_payment_date) {
                //         combinedData.push({
                //             date: jobSupport.job_support_record_payment.first_payment_date,
                //             amount: jobSupport.job_support_record_payment.first_payment_status,
                //             description: `${name}, ID: ${id}, ${enrolledProgram}, First Installment (Job Support)`,
                //             type: 'Deposit',
                //             branch: 'Online'
                //         });
                //     }
                // });

                // jobSupportData.forEach(jobSupport => {
                //     const name = jobSupport?.name || `${jobSupport?.student_record?.first_name} ${jobSupport?.student_record?.last_name}` || 'Unknown';
                //     const id = jobSupport?.id;
                //     const enrolledProgram = jobSupport?.student_record?.enrolled_program || 'Not Given';
                //     if (jobSupport.job_support_record_payment?.second_payment_date) {
                //         combinedData.push({
                //             date: jobSupport.job_support_record_payment.second_payment_date,
                //             amount: jobSupport.job_support_record_payment.second_payment_status,
                //             description: `${name}, ID: ${id}, ${enrolledProgram}, Second Installment (Job Support)`,
                //             type: 'Deposit',
                //             branch: 'Online'
                //         });
                //     }
                // });
                // jobSupportData.forEach(jobSupport => {
                //     const name = jobSupport?.name || `${jobSupport?.student_record?.first_name} ${jobSupport?.student_record?.last_name}` || 'Unknown';
                //     const id = jobSupport?.id;
                //     const enrolledProgram = jobSupport?.student_record?.enrolled_program || 'Not Given';
                //     if (jobSupport.job_support_record_payment?.third_payment_date) {
                //         combinedData.push({
                //             date: jobSupport.job_support_record_payment.third_payment_date,
                //             amount: jobSupport.job_support_record_payment.third_payment_status,
                //             description: `${name}, ID: ${id}, ${enrolledProgram}, Third Installment (Job Support)`,
                //             type: 'Deposit',
                //             branch: 'Online'
                //         });
                //     }
                // });

                // jobSupportData.forEach(jobSupport => {

                //     if (jobSupport.new_job_support_payment.length > 0) {
                //         jobSupport.new_job_support_payment.forEach(paymentInfo => {
                //             if (paymentInfo.deposit) {
                //                 combinedData.push({
                //                     date: paymentInfo.deposit_payment_date,
                //                     amount: paymentInfo.deposit,
                //                     type: 'Deposit',
                //                 });
                //             }

                //             if (paymentInfo.first_payment_status) {
                //                 combinedData.push({
                //                     date: paymentInfo.first_payment_date,
                //                     amount: paymentInfo.first_payment_status,
                //                     type: 'Deposit',
                //                 });
                //             }

                //             if (paymentInfo.second_payment_status) {
                //                 combinedData.push({
                //                     date: paymentInfo.second_payment_date,
                //                     amount: paymentInfo.second_payment_status,
                //                     type: 'Deposit',
                //                 });
                //             }
                //             if (paymentInfo.third_payment_status) {
                //                 combinedData.push({
                //                     date: paymentInfo.third_payment_date,
                //                     amount: paymentInfo.third_payment_status,
                //                     type: 'Deposit',
                //                 });
                //             }
                //         });
                //     }
                // });

                jobSupportData.forEach(jobSupport => {
                    const name = jobSupport?.name || `${jobSupport?.student_record?.first_name} ${jobSupport?.student_record?.last_name}` || 'Unknown';
                    const id = jobSupport?.id;
                    const enrolledProgram = jobSupport?.student_record?.enrolled_program || 'Not Given';

                    if (jobSupport.job_support_record_payment) {
                        if (jobSupport.job_support_record_payment?.deposit && jobSupport.job_support_record_payment?.deposit !== "0.00") {
                            combinedData.push({
                                date: jobSupport.job_support_record_payment?.deposit_payment_date,
                                amount: jobSupport.job_support_record_payment?.deposit,
                                description: `${name}, ID: ${id}, ${enrolledProgram}, Deposit Payment (Job Support)`,
                                type: 'Deposit',
                                branch: 'Online'
                            });
                        }

                        if (jobSupport.job_support_record_payment?.first_payment_date) {
                            combinedData.push({
                                date: jobSupport.job_support_record_payment.first_payment_date,
                                amount: jobSupport.job_support_record_payment.first_payment_status,
                                description: `${name}, ID: ${id}, ${enrolledProgram}, First Installment (Job Support)`,
                                type: 'Deposit',
                                branch: 'Online'
                            });
                        }

                        if (jobSupport.job_support_record_payment?.second_payment_date) {
                            combinedData.push({
                                date: jobSupport.job_support_record_payment.second_payment_date,
                                amount: jobSupport.job_support_record_payment.second_payment_status,
                                description: `${name}, ID: ${id}, ${enrolledProgram}, Second Installment (Job Support)`,
                                type: 'Deposit',
                                branch: 'Online'
                            });
                        }

                        if (jobSupport.job_support_record_payment?.third_payment_date) {
                            combinedData.push({
                                date: jobSupport.job_support_record_payment.third_payment_date,
                                amount: jobSupport.job_support_record_payment.third_payment_status,
                                description: `${name}, ID: ${id}, ${enrolledProgram}, Third Installment (Job Support)`,
                                type: 'Deposit',
                                branch: 'Online'
                            });
                        }
                    }

                    if (jobSupport.new_job_support_payment.length > 0) {
                        jobSupport.new_job_support_payment.forEach(paymentInfo => {
                            if (paymentInfo.deposit && parseFloat(paymentInfo.deposit) > 0) {
                                const newEnrolledProgram = paymentInfo.job_support_count;
                                combinedData.push({
                                    date: paymentInfo.deposit_payment_date,
                                    amount: paymentInfo.deposit,
                                    description: `${name}, ID: ${id}, Job Support Count: ${newEnrolledProgram}, New Deposit Payment (Enrolled Student`,
                                    type: 'Deposit',
                                    branch: 'Online'
                                });
                            }

                            if (paymentInfo.first_payment_status && parseFloat(paymentInfo.first_payment_status) > 0) {
                                const newEnrolledProgram = paymentInfo.job_support_count;
                                combinedData.push({
                                    date: paymentInfo.first_payment_date,
                                    amount: paymentInfo.first_payment_status,
                                    description: `${name}, ID: ${id}, Job Support Count: ${newEnrolledProgram}, New First Installment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: 'Online'
                                });
                            }

                            if (paymentInfo.second_payment_status && parseFloat(paymentInfo.second_payment_status) > 0) {
                                const newEnrolledProgram = paymentInfo.job_support_count;
                                combinedData.push({
                                    date: paymentInfo.second_payment_date,
                                    amount: paymentInfo.second_payment_status,
                                    description: `${name}, ID: ${id}, Job Support Count: ${newEnrolledProgram}, New Second Installment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: 'Online'
                                });
                            }
                            if (paymentInfo.third_payment_status && parseFloat(paymentInfo.third_payment_status) > 0) {
                                const newEnrolledProgram = paymentInfo.job_support_count;
                                combinedData.push({
                                    date: paymentInfo.third_payment_date,
                                    amount: paymentInfo.third_payment_status,
                                    description: `${name}, ID: ${id}, Job Support Count: ${newEnrolledProgram}, New Third Installment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: 'Online'
                                });
                            }
                        });
                    }
                });


                // Process enrolled data
                enrolledData.forEach(enrolled => {
                    const name = enrolled?.name;
                    const id = enrolled?.id;
                    const enrolledProgram = enrolled?.enrolled_program || 'Not Given';

                    if (enrolled.payment_information?.enrollment_payment) {
                        combinedData.push({
                            date: enrolled.payment_information?.enrollment_payment_date,
                            amount: enrolled.payment_information?.enrollment_payment,
                            description: `${name}, ID: ${id}, ${enrolledProgram}, Enrollment Payment (Enrolled Student)`,
                            type: 'Deposit',
                            branch: enrolled.location || 'Not Given'
                        });
                    }

                    if (enrolled.payment_information?.first_payment_date) {
                        combinedData.push({
                            date: enrolled.payment_information.first_payment_date,
                            amount: enrolled.payment_information.first_payment_status,
                            description: `${name}, ID: ${id}, ${enrolledProgram}, First Installment (Enrolled Student)`,
                            type: 'Deposit',
                            branch: enrolled.location || 'Not Given'
                        });
                    }

                    if (enrolled.payment_information?.second_payment_date) {
                        combinedData.push({
                            date: enrolled.payment_information.second_payment_date,
                            amount: enrolled.payment_information.second_payment_status,
                            description: `${name}, ID: ${id}, ${enrolledProgram}, Second Installment (Enrolled Student)`,
                            type: 'Deposit',
                            branch: enrolled.location || 'Not Given'
                        });
                    }

                    if (enrolled.payment_information?.third_payment_date) {
                        combinedData.push({
                            date: enrolled.payment_information.third_payment_date,
                            amount: enrolled.payment_information.third_payment_status,
                            description: `${name}, ID: ${id}, ${enrolledProgram}, Third Installment (Enrolled Student)`,
                            type: 'Deposit',
                            branch: enrolled.location || 'Not Given'
                        });
                    }

                    if (enrolled.payment_information?.fourth_payment_date) {
                        combinedData.push({
                            date: enrolled.payment_information.fourth_payment_date,
                            amount: enrolled.payment_information.fourth_payment_status,
                            description: `${name}, ID: ${id}, ${enrolledProgram}, Fourth Installment (Enrolled Student)`,
                            type: 'Deposit',
                            branch: enrolled.location || 'Not Given'
                        });
                    }


                    if (enrolled.new_payment_information.length > 0) {
                        enrolled.new_payment_information.forEach(paymentInfo => {
                            if (paymentInfo.enrollment_payment && parseFloat(paymentInfo.enrollment_payment) > 0) {
                                const newEnrolledProgram = paymentInfo.enrolled_program;
                                combinedData.push({
                                    date: paymentInfo.enrollment_payment_date,
                                    amount: paymentInfo.enrollment_payment,
                                    description: `${name}, ID: ${id}, ${newEnrolledProgram}, New Enrollment Payment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: enrolled.location || 'Not Given'
                                });
                            }

                            if (paymentInfo.first_payment_status && parseFloat(paymentInfo.first_payment_status) > 0) {
                                const newEnrolledProgram = paymentInfo.enrolled_program;
                                combinedData.push({
                                    date: paymentInfo.first_payment_date,
                                    amount: paymentInfo.first_payment_status,
                                    description: `${name}, ID: ${id}, ${newEnrolledProgram}, New First Installment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: enrolled.location || 'Not Given'
                                });
                            }

                            if (paymentInfo.second_payment_status && parseFloat(paymentInfo.second_payment_status) > 0) {
                                const newEnrolledProgram = paymentInfo.enrolled_program;
                                combinedData.push({
                                    date: paymentInfo.second_payment_date,
                                    amount: paymentInfo.second_payment_status,
                                    description: `${name}, ID: ${id}, ${newEnrolledProgram}, New Second Installment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: enrolled.location || 'Not Given'
                                });
                            }
                            if (paymentInfo.third_payment_status && parseFloat(paymentInfo.third_payment_status) > 0) {
                                const newEnrolledProgram = paymentInfo.enrolled_program;
                                combinedData.push({
                                    date: paymentInfo.third_payment_date,
                                    amount: paymentInfo.third_payment_status,
                                    description: `${name}, ID: ${id}, ${newEnrolledProgram}, New Third Installment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: enrolled.location || 'Not Given'
                                });
                            }
                            if (paymentInfo.fourth_payment_status && parseFloat(paymentInfo.fourth_payment_status) > 0) {
                                const newEnrolledProgram = paymentInfo.enrolled_program;
                                combinedData.push({
                                    date: paymentInfo.fourth_payment_date,
                                    amount: paymentInfo.fourth_payment_status,
                                    description: `${name}, ID: ${id}, ${newEnrolledProgram}, New Fourth Installment (Enrolled Student)`,
                                    type: 'Deposit',
                                    branch: enrolled.location || 'Not Given'
                                });
                            }
                        });
                    }



                });


                // Process Other income data
                otherIncomeData.forEach(otherIncome => {
                    if (otherIncome.paid_amount) {
                        combinedData.push({
                            date: otherIncome.date,
                            amount: otherIncome.paid_amount,
                            description: otherIncome.detail,
                            type: 'Deposit',
                            branch: otherIncome.location || 'Not Given'
                        });
                    }

                });

                // Update state with the combined data sorted by date (latest to oldest)
                setCombinedData(
                    combinedData.sort((a, b) => {
                        const dateA = a.date ? new Date(a.date) : 0;
                        const dateB = b.date ? new Date(b.date) : 0;
                        return dateB - dateA;
                    })
                );
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    // Memoized filtered data
    const filteredData = useMemo(() => {
        let filtered = combinedData;

        if (startDate && endDate) {
            filtered = filtered.filter(item => {
                const itemDate = new Date(item.date);
                const start = new Date(startDate);
                const end = new Date(endDate);
                return itemDate >= start && itemDate <= end;
            });
        }

        if (typeFilter) {
            filtered = filtered.filter(item => item.type === typeFilter);
        }

        if (branchFilter) {
            filtered = filtered.filter(item => item.branch === branchFilter);
        }

        return filtered;
    }, [combinedData, startDate, endDate, typeFilter, branchFilter]);

    // Pagination
    const itemsPerPage = 10;
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginatedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // Function to handle CSV data
    const handleCSVData = () => {
        return filteredData.map(item => ({
            Date: item.date || 'Not Given',
            Description: item.description,
            Type: item.type,
            Amount: item.amount,
            Branch: item.branch
        }));
    };

    const selectStyle = {
        backgroundColor: '#EAEFF4',
        boxSizing: 'border-box',
        border: '2px solid #CDCDCD',
        borderRadius: '5px',
        padding: '0.5rem',
        margin: '0.5rem',
        fontSize: '1rem',
        width: '20rem'
    };

    return (
        <div>

            <div className="layout-title">
                <p>Transaction</p>
                <div className="align-right">
                    <CSVLink
                        data={handleCSVData()}
                        filename={"transaction_data.csv"}
                        style={{
                            border: '1px solid #1976d2',
                            color: '#1976d2',
                            padding: '10px 20px',
                            textAlign: 'center',
                            textDecoration: 'none',
                            display: 'inline-block',
                            fontSize: '15px',
                            margin: '4px 2px',
                            cursor: 'pointer',
                            borderRadius: '15px',
                            transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                        }}
                        onMouseEnter={(e) => {
                            e.target.style.backgroundColor = '#1976d2';
                            e.target.style.color = '#fff';
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'transparent';
                            e.target.style.color = '#1976d2';
                        }}
                    >
                        Export CSV
                    </CSVLink>
                </div>
            </div>

            <div>
                <div className="align-left border border-radius">
                    <FilledRoundedButton name="Filters" size="medium" onClickHandler={toggleFilter} color="secondary" />
                </div>
                {filterOpen && (
                    <div>
                        <div className="filter-body-left">
                            <TextInput id="from" name="from" placeholder="From" type="date" size="small" onChangeHandler={(e) => setStartDate(e.target.value)} value={startDate} />
                            <TextInput id="to" name="to" placeholder="To" type="date" size="small" onChangeHandler={(e) => setEndDate(e.target.value)} value={endDate} />

                            <div>
                                <label htmlFor="typeFilter">Type:</label>
                                <select
                                    id="typeFilter"
                                    value={typeFilter}
                                    onChange={(e) => setTypeFilter(e.target.value)}
                                    style={selectStyle}
                                >
                                    <option value="">All</option>
                                    <option value="Deposit">Deposit</option>
                                    <option value="Withdrawal">Withdrawal</option>
                                </select>
                            </div>

                            <div>
                                <label htmlFor="branchFilter">Branch:</label>
                                <select
                                    id="branchFilter"
                                    value={branchFilter}
                                    onChange={(e) => setBranchFilter(e.target.value)}
                                    style={selectStyle}
                                >
                                    <option value="">All</option>
                                    {branches.map((branch, index) => (
                                        <option key={index} value={branch}>{branch}</option>
                                    ))}
                                    <option value="Online">Online</option>
                                </select>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {loading ? (
                <Loading />
            ) : (
                <div>
                    <div className="table-body">
                        <table style={{ width: '100%' }}>
                            <thead className="thead">
                                <tr>
                                    {tableHeader.map((th, i) => (
                                        <th className={`${th.size}-header`} key={i}>{th.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedData.map((item, i) => (
                                    <tr key={i}>
                                        <td>{item.date || 'Not Given'}</td>
                                        <td>{item.description}</td>
                                        <td>{item.type}</td>
                                        <td>${item.amount}</td>
                                        <td>{item.branch}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <Pagination
                        style={{ marginRight: '1rem', display: 'flex', justifyContent: 'right' }}
                        color="primary"
                        count={totalPages}
                        variant="outlined"
                        shape="rounded"
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                </div>
            )}
        </div>
    );
}

export default Transaction;



