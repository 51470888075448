// import React, { useState, useEffect, useRef } from 'react'
// import './income.css'

// import { getIncomeList, deleteIncome, getEmployees } from '../../actions/income'
// import { userTime } from '../../utility'
// import { useDispatch, useSelector } from 'react-redux'
// import { Box, Popover, Button } from '@mui/material';
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import { CSVLink } from 'react-csv';

// import { useNavigate } from 'react-router-dom'

// import Comment from '../Comment'

// import { ADD_MESSAGE } from '../../actions/types'
// import Tab from '../../components/Tab';
// import Filter from './Filter'
// import Loading from '../../components/Loading';
// import SimpleTable from '../../components/Table/SimpleTable'
// import FilledRoundedButton from "../../components/Button/FilledRoundedButton"
// import ButtonOulined from '../../components/Button/ButtonOulined'
// import Modal from '../../components/Modal'


// const jobSupportTableHeader = [
//     { name: "Action", size: "small", active: true },
//     { name: "ID", size: "small", active: true, data_key: "id" },
//     { name: "Date", size: 'medium', active: true, data_key: "created_at" },
//     { name: "Name", size: "medium", active: true, data_key: "name" },
//     { name: "Email", size: "medium", active: true, data_key: "email" },
//     { name: "Branch", size: "medium", active: true, data_key: "branch" },
//     { name: "Phone", size: "medium", active: false, data_key: "phone_number" },
//     { name: "Country", size: "medium", active: false, data_key: "country" },
//     { name: "City", size: "medium", active: false, data_key: "city" },
//     { name: "State", size: "medium", active: false, data_key: "state" },
//     { name: 'Street Address', size: "medium", active: false, data_key: "street_address" },
//     { name: "Reference", size: "medium", active: true, data_key: "reference" },
//     { name: "Batch", size: "medium", active: true, data_key: "batch" },
//     { name: "Batch Type", size: "medium", active: false, data_key: "batch_type" },
//     { name: "Job Support Area", size: "medium", active: false, data_key: "job_support_area" },
//     { name: "Deposit", size: "small", active: true, data_key: "deposit" },
//     { name: "Hourly Rate", size: "small", active: false, data_key: "hourly_rate" },
//     { name: "Gross Salary", size: "small", active: false, data_key: "gross_salary" },
//     { name: "Target Amount", size: "small", active: true, data_key: "target_amount" },
//     { name: "Percentage", size: "small", active: false, data_key: "qatek_parcentage" },
//     { name: "Paid Amount", size: "small", active: true, data_key: "paid_amount" },
//     { name: "Refund Amount", size: "small", active: true, data_key: "refund_amount" },
//     { name: "Remaining", size: "small", active: true, data_key: "remaining_amount" },
//     { name: "Person to followup", size: "medium", active: true, data_key: "follow_up_person" },
//     { name: "1st Installment", size: "medium", active: false, data_key: "first_installment" },
//     { name: "2nd Installment", size: "medium", active: false, data_key: "second_installment" },
//     { name: "3rd Installment", size: "medium", active: false, data_key: "third_installment" },
// ]

// const softTrainingTableHeader = [
//     { name: "Action", size: "small", active: true },
//     { name: "ID", size: "small", active: true, data_key: "id" },
//     { name: "Date", size: 'medium', active: true, data_key: "created_at" },
//     { name: "Name", size: "medium", active: true, data_key: "name" },
//     { name: "Email", size: "medium", active: true, data_key: "email" },
//     { name: "Branch", size: "medium", active: true, data_key: "branch" },
//     { name: "Phone", size: "medium", active: false, data_key: "phone_number" },
//     { name: "Country", size: "medium", active: false, data_key: "country" },
//     { name: "City", size: "medium", active: false, data_key: "city" },
//     { name: "State", size: "medium", active: false, data_key: "state" },
//     { name: 'Street Address', size: "medium", active: false, data_key: "street_address" },
//     { name: "Referred By", size: "medium", active: true, data_key: "referred_by" },
//     { name: "Source", size: "medium", active: false, data_key: "source" },
//     { name: "Batch", size: "medium", active: true, data_key: "batch" },
//     { name: "Batch Type", size: "medium", active: false, data_key: "batch_type" },
//     { name: "Course Name", size: "medium", active: false, data_key: "course_name" },
//     { name: "Enrollment Fee", size: "small", active: true, data_key: "enrollment_fee" },
//     { name: "Target Amount", size: "small", active: true, data_key: "target_amount" },
//     { name: "Paid Amount", size: "small", active: true, data_key: "paid_amount" },
//     { name: "Paid Amount (Previous)", size: "small", active: true, data_key: "previous_paid_amount" },
//     { name: "Remaining", size: "small", active: true, data_key: "remaining_amount" },
//     { name: "Refund Amount", size: "small", active: true, data_key: "refund_amount" },
//     { name: "Person to followup", size: "medium", active: true, data_key: "follow_up_person" },
//     { name: "1st Installment", size: "medium", active: false, data_key: "first_installment" },
//     { name: "2nd Installment", size: "medium", active: false, data_key: "second_installment" },
//     { name: "3rd Installment", size: "medium", active: false, data_key: "third_installment" },
//     { name: "4th Installment", size: "medium", active: false, data_key: "fourth_installment" },
// ]

// const csv_headers = [
//     { label: 'ID', key: 'id' },
//     { label: 'Date', key: 'created_at' },
//     { label: 'Paid To', key: 'name' },
//     { label: 'Paid By', key: 'email' },
//     { label: 'Branch', key: 'batch' },
//     { label: 'Details', key: 'description' },
//     { label: "Amount", key: "target_amount" }
// ]


// function Income() {
//     const navigate = useNavigate()
//     const dispatch = useDispatch()
//     const ref = useRef()

//     const role = useSelector((state) => state.auth.role)
//     const [anchorEl, setAnchorEl] = useState(null);
//     const [openExport, setOpenExport] = useState(false);

//     const [tabs, setTabs] = useState([{ name: 'Software Training', active: true }, { name: 'Job Support', active: false }])
//     const [tableHeader, setTableHeader] = useState(tabs[0].active ? softTrainingTableHeader : jobSupportTableHeader)
//     const [openCommentBox, setOpenCommentBox] = useState(false)
//     const [openColumnBox, setOpenColumnBox] = useState(false)
//     const [jobSupportData, setJobSupportData] = useState([])
//     const [softwareTrainingData, setSoftwareTrainingData] = useState([])
//     const [filterData, setFilterData] = useState({})
//     const [exportData, setExportData] = useState([])
//     const [loading, setLoading] = useState(false)
//     const [filterOpen, setOpen] = useState(false);
//     const [totalAmount, setTotalAmount] = useState({})
//     const [markedItem, setMarkedItem] = useState([])
//     const [confirmDelete, setConfirmDelete] = useState(false);

//     const [page, setPage] = useState(1)
//     const [totalPage, setTotalPage] = useState(0)

//     useEffect(() => {
//         fetchIncomeData()
//         setOpenCommentBox(false)
//     }, [filterData, page, tabs])

//     useEffect(() => {
//         setTableHeader(tabs[0].active ? softTrainingTableHeader : jobSupportTableHeader)
//     }, [tabs])

//     const fetchIncomeData = (type = null) => {
//         let query_params = {}
//         if (type) query_params['page_size'] = totalPage * 15
//         else query_params['page'] = page

//         for (const key in filterData) {
//             if (key === "from" || key === "to")
//                 query_params[key] = new Date(filterData[key]).toUTCString()
//             else query_params[key] = filterData[key]
//         }
//         setLoading(true)
//         if (tabs[0].active && role.can_see_income) {
//             dispatch(getIncomeList('software-training', query_params))
//                 .then(response => {
//                     if (response.status === 200) {
//                         if (type === "csv") {
//                             let incomeData = response.data.data
//                             setExportData(incomeData.map(income => {
//                                 let { student, ...data } = income;
//                                 return { ...student, ...data }
//                             }))
//                             ref.current.link.click()
//                         }
//                         else if (type === "pdf") {
//                             generatePDF(response.data.data.map(income => {
//                                 let { student, ...data } = income;
//                                 return { ...student, ...data }
//                             }))
//                         }
//                         else {
//                             console.log(response.data.data);
//                             setSoftwareTrainingData(response.data.data)
//                             setTotalAmount(response.data.total)
//                             setTotalPage(response.totalPage)
//                         }
//                     }
//                     setLoading(false)
//                 })
//         }
//         else if (tabs[1].active && role.can_see_income) {
//             dispatch(getIncomeList('job-support', query_params))
//                 .then(response => {
//                     if (response.status === 200) {
//                         if (type === "csv") {
//                             setExportData(response.data.map(income => {
//                                 let { student, ...data } = income;
//                                 return { ...student, ...data }
//                             }))
//                             ref.current.link.click()
//                         }
//                         else if (type === "pdf") {
//                             generatePDF(response.data.map(income => {
//                                 let { student, ...data } = income;
//                                 return { ...student, ...data }
//                             }))
//                         }
//                         else {
//                             setTotalPage(response.totalPage)
//                             console.log(response.data.data);
//                             setJobSupportData(response.data.data)
//                             setTotalAmount(response.data.total)
//                         }
//                     }
//                     setLoading(false)
//                 })
//         }
//     }

//     const handlePage = (event, value) => {
//         setPage(value)
//     }

//     const tabChangeHandler = (tab) => {
//         const flag = tabs.map(t => t.name === tab.name ? { ...t, active: true } : { ...t, active: false })
//         setTabs(flag)
//         setOpenCommentBox(false)
//         setPage(1)
//         setJobSupportData([])
//         setSoftwareTrainingData([])
//         setMarkedItem([])
//     }

//     // const getFilterDatas = (data) => {
//     //     if(role.can_see_income){
//     //         // let query_param = "?"
//     //         let query_param = {page:page}

//     //         for (const key in data){
//     //             if(key === "from" || key === "to")
//     //                 query_param[key] = new Date(data[key]).toUTCString()
//     //                 // query_param = query_param + `${key}=${new Date(data[key]).toUTCString()}&&`
//     //             // else query_param = query_param + `${key}=${data[key]}&&`
//     //             else query_param[key] = data[key]
//     //         }

//     //         dispatch(getIncomeList(tabs[0].active ? "software-training" : "job-support", query_param))
//     //         .then(response => {
//     //             if(response.status === 200){
//     //                 if(tabs[0].active){
//     //                     setSoftwareTrainingData(response.data)
//     //                 } else {
//     //                     setJobSupportData(response.data )
//     //                 }
//     //             }
//     //         })
//     //     }
//     // }

//     const deleteExpense = (income) => {
//         if (role.can_delete_income) {
//             if (tabs[0].active) {
//                 dispatch(deleteIncome(income.id, 'software-training'))
//                     .then(response => {
//                         if (response.status === 204) {
//                             let newData = softwareTrainingData.filter(data => data.id !== income.id)
//                             setSoftwareTrainingData(newData)
//                         }
//                     })
//             } else {
//                 dispatch(deleteIncome(income.id, 'job-support'))
//                     .then(response => {
//                         if (response.status === 204) {
//                             let newData = jobSupportData.filter(data => data.id !== income.id)
//                             setJobSupportData(newData)
//                         }
//                     })
//             }
//         } else {
//             dispatch({
//                 type: ADD_MESSAGE,
//                 payload: { msg: "You don't have permission to perform this task", status: 400 }
//             })
//         }
//     }

//     const editExpense = (exp) => {
//         if (role.can_modify_income) tabs[0].active ? navigate(`edit-income-new/${exp.id}/`) : navigate(`edit-income-job-support-new/${exp.id}/`)
//         // if (role.can_modify_income) navigate(`update/${exp.id}/?type=${tabs[0].active ? "training" : "support"}`, { state: { type: "update" } })
//         else {
//             dispatch({
//                 type: ADD_MESSAGE,
//                 payload: { msg: "You dont have permissio to perform this task", status: 400 }
//             })
//         }
//     }

//     const tableBody = (exp) => {
//         let { student, ...income } = exp
//         let { id, follow_up_person, ...students } = student
//         income = { ...income, ...students }
//         income['created_at'] = userTime(income.created_at, 'DD MMM YYYY')
//         return (
//             <>
//                 {
//                     tableHeader.map(data => ((data.active && "data_key" in data) &&
//                         <td onClick={() => {
//                             setOpenCommentBox(prevState => prevState === income.id ? false : income.id)
//                             if (tabs[0].active) {
//                                 setSoftwareTrainingData(prevState => prevState.map(inc => inc.id === exp.id ? { ...inc, is_new_comment: false } : inc))
//                             } else {
//                                 setJobSupportData(prevState => prevState.map(inc => inc.id === exp.id ? { ...inc, is_new_comment: false } : inc))
//                             }

//                         }}
//                             style={{ cursor: "pointer" }}>{income[data.data_key]}</td>))}
//             </>
//         )
//     }

//     const showTotal = () => (
//         <>
//             {tableHeader.map((col, key) =>
//             (col.active && <td key={key}>
//                 {col.data_key === 'target_amount' ? <p className="highlight-text">{`Total Amount: ${totalAmount.target_amount__sum}`}</p> : ""}
//                 {col.data_key === 'paid_amount' ? <p className="highlight-text">{`Total Paid Amount: ${totalAmount.paid_amount__sum}`}</p> : ""}
//                 {col.data_key === 'remaining_amount' ? <p className="highlight-text">{`Total Remaining Amount: ${totalAmount.remaining_amount__sum}`}</p> : ""}
//             </td>)
//             )}
//         </>
//     )

//     const onMark = (exp) => {
//         const items = new Set(markedItem)
//         if (items.has(exp)) items.delete(exp)
//         else items.add(exp)
//         setMarkedItem([...items])
//     }

//     const confirmDeleteBody = () => (
//         <Box>
//             <p>Are you sure want to delete?</p>
//             <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//                 <ButtonOulined name="Cancel" size="medium" onClickHandler={() => setConfirmDelete(false)} />
//                 <FilledRoundedButton name="Delete" color="red" size="medium" onClickHandler={() => {
//                     deleteMultipleData()
//                     setConfirmDelete(false)
//                 }} />
//             </div>
//         </Box>
//     )

//     const deleteMultipleData = () => {
//         if (role.can_delete_income || role.is_admin) {
//             setLoading(true)
//             dispatch(deleteIncome(markedItem[0], tabs[0].active ? 'software-training' : 'job-support', { ids: JSON.stringify(markedItem) }))
//                 .then((response) => {
//                     if (response.status === 200) {
//                         if (tabs[0].active) {
//                             let newsoftwareTrainingData = softwareTrainingData.filter(i => !markedItem.includes(i.id))
//                             setSoftwareTrainingData(newsoftwareTrainingData)
//                         } else {
//                             let newJobSupportData = jobSupportData.filter(i => !markedItem.includes(i.id))
//                             setJobSupportData(newJobSupportData)
//                         }
//                         setMarkedItem([])
//                     }
//                     setLoading(false)
//                 })
//         } else {
//             dispatch({
//                 type: ADD_MESSAGE,
//                 payload: { msg: "You don't have permissio to perform this task", status: 400 }
//             })
//         }
//     }

//     const toggleTableHeader = (header) => {
//         if (header.name !== "Action")
//             setTableHeader(prevState => prevState.map(column => column.name === header.name ? { ...column, active: !header.active } : column))
//     }

//     const generatePDF = tickets => {
//         if (tableHeader.filter(header => header.active === true).length > 15) {
//             dispatch({
//                 type: ADD_MESSAGE,
//                 payload: { msg: "Maximum 15 column supported", status: 400 }
//             })
//         } else {
//             const doc = new jsPDF({
//                 orientation: "landscape",
//             });

//             const tableColumn = tableHeader.filter(header => (header.active === true) && (header.name !== "Action") && (header.name !== "ID"))
//                 .map(column => column.name);
//             const tableRows = [];

//             tickets.map(expense => {
//                 const expense_row = tableHeader.filter(header => (header.active === true) && ((header.name !== "Action") && (header.name !== "ID")))
//                     .map(column => column.data_key === "created_at" ? userTime(expense[column.data_key], 'DD MMM YYYY') : expense[column.data_key])
//                 tableRows.push(expense_row)
//             })

//             doc.autoTable(tableColumn, tableRows, { startY: 20 });
//             doc.text("Income Data.", 14, 15);
//             const date = Date().split(" ");
//             const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
//             doc.save(`report_${dateStr}.pdf`);
//         }
//     };

//     return (
//         <div>
//             <div className="layout-title">
//                 <p>Income</p>
//                 <Modal header="Confirm Delete" body={confirmDeleteBody} isOpen={confirmDelete || false} modalHandler={() => setConfirmDelete(false)} />
//                 <div className="align-right">
//                     <CSVLink
//                         ref={ref}
//                         headers={tableHeader.filter(header => (header.active === true) && (header.name !== "Action")).map(header => ({ label: header.name, key: header.data_key }))}
//                         data={exportData} />

//                     <ButtonOulined name="Export" onClickHandler={(event) => {
//                         setAnchorEl(event.currentTarget)
//                         setOpenExport(true)
//                     }} />

//                     <Popover
//                         open={openExport}
//                         anchorEl={anchorEl}
//                         onClose={() => {
//                             setAnchorEl(null)
//                             setOpenExport(false)
//                         }}
//                         anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left',
//                         }}
//                         sx={{ p: 2 }}
//                     >
//                         <Button variant="outlined" onClick={() => fetchIncomeData("csv")}>CSV</Button>
//                         <Button variant="outlined" onClick={() => fetchIncomeData("pdf")}>PDF</Button>
//                     </Popover>

//                     {role.can_add_income && <div className="align-right">
//                         {
//                             tabs[0].active ?
//                                 <FilledRoundedButton name="Add Income" color="green" size="small"
//                                     onClickHandler={() =>
//                                         navigate('income-new')} />
//                                 : <FilledRoundedButton name="Add Income" color="green" size="small"
//                                     onClickHandler={() =>
//                                         navigate(`create?type=support`,
//                                             { state: { type: "create" } })} />
//                         }
//                     </div>}

//                 </div>
//             </div>
//             <div style={{ margin: "1rem" }}>

//                 <div className="align-left border border-radius">
//                     <FilledRoundedButton name="Filters" size="medium" onClickHandler={() => setOpen(!filterOpen)} color="secondary" />
//                     <FilledRoundedButton name="Hidden Columns" size="medium" onClickHandler={() => setOpenColumnBox(!openColumnBox)} color="secondary" />
//                 </div>

//                 <Filter filterData={filterData} setFilterData={setFilterData} tab={tabs[0].active ? "training" : "support"} filterOpen={filterOpen} />
//                 <div className={`header-edit-container ${openColumnBox || `display-none`}`}>
//                     {/* <div className={`column-box ${openColumnBox && "border-bottom"}`} onClick={() => setOpenColumnBox(!openColumnBox)}>
//                         <b>Hidden Columns</b>
//                         {openColumnBox ? <FiChevronUp color="#006CB0" size="25" style={{margin: ".5rem"}}/> :<FiChevronDown color="#006CB0" size="25" style={{margin: ".5rem"}}/>}
//                     </div> */}

//                     <div className="align-space-between">
//                         {tableHeader.map((header, key) => (
//                             <div>
//                                 {header.active ?
//                                     <FilledRoundedButton name={header.name} onClickHandler={() => toggleTableHeader(header)} size="medium" /> :
//                                     <ButtonOulined name={header.name} size="medium" onClickHandler={() => toggleTableHeader(header)} />}
//                             </div>
//                         ))}
//                     </div>

//                     {/* <div>
//                         {tableHeader.map((header, key) => (
//                             <Chip header={header} onCancel={toggleTableHeader} onPress={toggleTableHeader} active={header.active}/>
//                         ))}
//                     </div> */}
//                 </div>

//                 <Tab tabList={tabs} onClickHandler={tabChangeHandler} />
//                 <div className="align-left">
//                     {markedItem.length ? <ButtonOulined name="Delete" size="small" onClickHandler={() => setConfirmDelete(true)} /> : <></>}
//                     {/* <ButtonOulined name="Filters" size="small" onClickHandler={filterBodyHandler} /> */}
//                 </div>
//                 <div style={{ display: 'flex' }}>
//                     {loading ? <Loading /> :
//                         <div style={{ flex: 3, overflowX: "auto", width: '50rem' }}>
//                             <SimpleTable header={tableHeader.filter(header => header.active === true)}
//                                 values={tabs[0].active ? softwareTrainingData : jobSupportData} onDelete={deleteExpense}
//                                 onEdit={editExpense} activeRow={openCommentBox} tableBody={tableBody} onMark={onMark} markedItem={markedItem}
//                                 page={page} handlePage={handlePage} totalPage={totalPage} total={Object.keys(filterData).length ? showTotal : null} />
//                         </div>}
//                     {openCommentBox && <div style={{ flex: 1 }}>
//                         <Comment id={openCommentBox} query={tabs[0].active ? "income/software-training" : "income/job-support"} />
//                     </div>}
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Income





















































// import React, { useState, useEffect, useMemo } from 'react';
// import './income.css';
// import { useSelector } from 'react-redux';
// import axios from 'axios';
// import Tab from '../../components/Tab';
// import Loading from '../../components/Loading';
// import SimpleTable from '../../components/Table/SimpleTable';
// import TextInput from '../../components/TextInput';
// import FilledRoundedButton from "../../components/Button/FilledRoundedButton"


// const filterByDateAndName = (data, start, end, searchQuery) => {
//     const filteredByDate = filterByDate(data, start, end);

//     if (!searchQuery) {
//         return filteredByDate;
//     }

//     // Filter data based on date range, name query, and ID query
//     return filteredByDate.filter((item) => {
//         const id = String(item.id);
//         const name = item.name ? item.name.toLowerCase() : '';
//         const firstName = item.student_record?.first_name ? item.student_record.first_name.toLowerCase() : '';
//         const email = item.email ? item.email.toLowerCase() : '';
//         const studentEmail = item.student_record?.email ? item.student_record.email.toLowerCase() : '';
//         const batchName = item.batch ? item.batch.toLowerCase() : '';

//         const itemName = id + name + firstName + email + studentEmail + batchName;

//         return itemName.includes(searchQuery.toLowerCase());
//     });
// };


// const filterByDate = (data, start, end) => {
//     if (!start && !end) {
//         return data;
//     }

//     // Filter data based on date range
//     return data.filter((item) => {
//         const itemDate = new Date(item.date || item.job_support_record_payment?.deposit_payment_date || item.job_support_record_payment?.first_payment_date || item.job_support_record_payment?.second_payment_date || item.job_support_record_payment?.third_payment_date || item.job_support_record_payment?.fourth_payment_date);
//         // const itemDate = new Date(item.date || item.job_support_record_payment?.deposit_payment_date);
//         return (
//             (!start || itemDate >= new Date(start)) &&
//             (!end || itemDate <= new Date(end))
//         );
//     });
// };

// const jobSupportTableHeader = [
//     { name: 'Action', size: 'small', active: true },
//     { name: 'ID', size: 'small', active: true, data_key: 'id' },
//     { name: 'Name', size: 'medium', active: true, data_key: 'name' },
//     { name: 'Email', size: 'medium', active: true, data_key: 'email' },
//     { name: 'Deposit', size: 'small', active: true, data_key: 'deposit' },
//     { name: 'Deposit Date', size: 'medium', active: false, data_key: 'created_at' },
//     { name: 'Target Amount', size: 'small', active: true, data_key: 'target_amount' },
//     { name: 'Paid Amount', size: 'small', active: true, data_key: 'paid_amount' },
//     { name: 'Remaining', size: 'small', active: true, data_key: 'remaining_amount' },
//     { name: 'Refund Amount', size: 'small', active: true, data_key: 'refund_amount' },
//     { name: "1st Installment", size: "medium", active: true, data_key: "first_installment" },
//     { name: "2nd Installment", size: "medium", active: true, data_key: "second_installment" },
//     { name: "3rd Installment", size: "medium", active: true, data_key: "third_installment" },
// ];

// const softTrainingTableHeader = [
//     { name: 'Action', size: 'small', active: true },
//     { name: 'ID', size: 'small', active: true, data_key: 'id' },
//     { name: 'Date', size: 'medium', active: true, data_key: 'created_at' },
//     { name: 'Name', size: 'medium', active: true, data_key: 'name' },
//     { name: 'Email', size: 'medium', active: true, data_key: 'email' },
//     { name: 'Referred By', size: 'medium', active: true, data_key: 'referred_by' },
//     { name: 'Batch', size: 'medium', active: true, data_key: 'batch' },
//     { name: 'Enrollment Fee', size: 'small', active: true, data_key: 'enrollment_fee' },
//     { name: 'Target Amount', size: 'small', active: true, data_key: 'target_amount' },
//     { name: 'Paid Amount', size: 'small', active: true, data_key: 'paid_amount' },
//     { name: 'Remaining', size: 'small', active: true, data_key: 'remaining_amount' },
//     { name: 'Refund Amount', size: 'small', active: true, data_key: 'refund_amount' },
//     { name: "1st Installment", size: "medium", active: true, data_key: "first_installment" },
//     { name: "2nd Installment", size: "medium", active: true, data_key: "second_installment" },
//     { name: "3rd Installment", size: "medium", active: true, data_key: "third_installment" },
//     { name: "4th Installment", size: "medium", active: true, data_key: "fourth_installment" },

//     { name: "Target Amount (new)", size: "medium", active: true },
//     { name: "Enrollment Fee (new)", size: "medium", active: true },
//     { name: "1st Installment(new)", size: "medium", active: true },
//     { name: "2nd Installment (new)", size: "medium", active: true },
//     { name: "3rd Installment (new)", size: "medium", active: true },
//     { name: "4th Installment (new)", size: "medium", active: true },
// ];



// function Income() {
//     const role = useSelector((state) => state.auth.role);
//     const [tabs, setTabs] = useState([
//         { name: 'Software Training', active: true },
//         { name: 'Job Support', active: false },
//     ]);
//     const [tableHeader, setTableHeader] = useState(
//         tabs[0].active ? softTrainingTableHeader : jobSupportTableHeader
//     );
//     const [openCommentBox, setOpenCommentBox] = useState(false);
//     const [jobSupportData, setJobSupportData] = useState([]);
//     const [softwareTrainingData, setSoftwareTrainingData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [totalCount, setTotalCount] = useState(1)
//     const itemsPerPage = 10;

//     const [softwareTrainingCurrentPage, setSoftwareTrainingCurrentPage] = useState(1);
//     const [jobSupportCurrentPage, setJobSupportCurrentPage] = useState(1);

//     const [startDate, setStartDate] = useState(null);
//     const [endDate, setEndDate] = useState(null);
//     const [nameQuery, setNameQuery] = useState('');
//     const [searchQuery, setSearchQuery] = useState('');
//     const [filterOpen, setFilterOpen] = useState(false); // State to manage filter visibility


//     const softwareTrainingStartIndex = (softwareTrainingCurrentPage - 1) * itemsPerPage;
//     const softwareTrainingEndIndex = softwareTrainingStartIndex + itemsPerPage;

//     const jobSupportStartIndex = (jobSupportCurrentPage - 1) * itemsPerPage;
//     const jobSupportEndIndex = jobSupportStartIndex + itemsPerPage;

//     const filteredSoftwareTrainingData = useMemo(() => {
//         return filterByDateAndName(
//             softwareTrainingData,
//             startDate,
//             endDate,
//             searchQuery
//         );
//     }, [softwareTrainingData, startDate, endDate, searchQuery]);

//     const filteredJobSupportData = useMemo(() => {
//         return filterByDateAndName(
//             jobSupportData,
//             startDate,
//             endDate,
//             searchQuery
//         );
//     }, [jobSupportData, startDate, endDate, searchQuery]);


//     useEffect(() => {
//         fetchIncomeData();
//         setOpenCommentBox(false);
//     }, [tabs]);

//     useEffect(() => {
//         setTableHeader(tabs[0].active ? softTrainingTableHeader : jobSupportTableHeader)
//     }, [tabs])



//     const [softwareTrainingCache, setSoftwareTrainingCache] = useState([]);
//     const [jobSupportCache, setJobSupportCache] = useState([]);

//     // Function to fetch data with caching
//     const fetchDataWithCache = async (apiUrl, setDataCallback, cacheSetter) => {
//         setLoading(true);
//         try {
//             // Check cache first
//             if (cacheSetter === setSoftwareTrainingCache && softwareTrainingCache.length > 0) {
//                 setDataCallback(softwareTrainingCache);
//             } else if (cacheSetter === setJobSupportCache && jobSupportCache.length > 0) {
//                 setDataCallback(jobSupportCache);
//             } else {
//                 const response = await axios.get(apiUrl, {
//                     headers: {
//                         'Authorization': `Bearer ${localStorage.getItem('access')}`,
//                         'Content-Type': 'application/json',
//                     },
//                 });

//                 const result = response.data;
//                 setDataCallback(result.records);
//                 cacheSetter(result.records);
//             }
//         } catch (error) {
//             console.error('Error during data fetching:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const fetchIncomeData = () => {

//         const softwareTrainingApiUrl = 'https://stms.qatekinternal.com/records/partner-api/enrolled-student-list/';
//         const jobSupportApiUrl = 'https://stms.qatekinternal.com/job-support/partner-api/job-support-list/';

//         if (tabs[0].active && role.can_see_income) {
//             fetchDataWithCache(softwareTrainingApiUrl, setSoftwareTrainingData, setSoftwareTrainingCache);
//         } else if (tabs[1].active && role.can_see_income) {
//             fetchDataWithCache(jobSupportApiUrl, setJobSupportData, setJobSupportCache);
//         }
//     }


//     const toggleFilter = () => {
//         setFilterOpen(!filterOpen);
//         // Reset filter values
//         setStartDate(null);
//         setEndDate(null);
//         setNameQuery('');
//     }

//     const tabChangeHandler = (tab) => {
//         const flag = tabs.map(t => t.name === tab.name ? { ...t, active: true } : { ...t, active: false });
//         setTabs(flag);
//         setOpenCommentBox(false);
//         setJobSupportData([]);
//         setSoftwareTrainingData([]);
//         setSoftwareTrainingCurrentPage(1);
//         setJobSupportCurrentPage(1);
//     }

//     const softwareTrainingTableBody = (value) => {
//         return (
//             <>
//                 <td>{value.id}</td>
//                 <td>{value.date.slice(0, 10)}</td>
//                 <td>{value.name}</td>
//                 <td>{value.email}</td>
//                 <td style={{ color: value.referred_by ? 'black' : 'red' }}>{value.referred_by || 'Not Given'}</td>
//                 <td>{value.batch}</td>
//                 <td>{value.payment_information?.enrollment_payment ? `$${value.payment_information.enrollment_payment}` : 'Not Given'}</td>
//                 <td>{value.payment_information?.target_amount ? `$${value.payment_information.target_amount}` : 'Not Given'}</td>
//                 <td>{value.payment_information?.paid_amount ? `$${value.payment_information.paid_amount}` : 'Not Given'}</td>
//                 <td style={{ color: value.payment_information?.balance_remaining ? 'black' : 'red' }}>{value.payment_information?.balance_remaining ? `$${value.payment_information.balance_remaining}` : 'Not Given'}</td>
//                 <td style={{ color: value.payment_information?.refund_amount ? 'black' : 'red' }}>{value.payment_information?.refund_amount ? `$${value.payment_information.refund_amount}` : 'Not Given'}</td>
//                 <td style={{ color: value.payment_information?.first_payment_status ? 'black' : 'red' }}>{value.payment_information?.first_payment_status ? `$${value.payment_information.first_payment_status}` : 'Not Given'}</td>
//                 <td style={{ color: value.payment_information?.second_payment_status ? 'black' : 'red' }}>{value.payment_information?.second_payment_status ? `$${value.payment_information.second_payment_status}` : 'Not Given'}</td>
//                 <td style={{ color: value.payment_information?.third_payment_status ? 'black' : 'red' }}>{value.payment_information?.third_payment_status ? `$${value.payment_information.third_payment_status}` : 'Not Given'}</td>
//                 <td style={{ color: value.payment_information?.fourth_payment_status ? 'black' : 'red' }}>{value.payment_information?.fourth_payment_status ? `$${value.payment_information.fourth_payment_status}` : 'Not Given'}</td>



//                 <td style={{ color: value.new_payment_information.some(paymentInfo => parseFloat(paymentInfo.target_amount) > 0) ? 'black' : 'red' }}>
//                     {value.new_payment_information.some(paymentInfo => parseFloat(paymentInfo.target_amount) > 0) ? `$${value.new_payment_information.find(paymentInfo => parseFloat(paymentInfo.target_amount) > 0).target_amount}` : 'Not Given'}
//                 </td>

//                 <td style={{ color: value.new_payment_information.some(paymentInfo => parseFloat(paymentInfo.enrollment_payment) > 0) ? 'black' : 'red' }}>
//                     {value.new_payment_information.some(paymentInfo => parseFloat(paymentInfo.enrollment_payment) > 0) ? `$${value.new_payment_information.find(paymentInfo => parseFloat(paymentInfo.enrollment_payment) > 0).enrollment_payment}` : 'Not Given'}
//                 </td>

//                 <td style={{ color: value.new_payment_information.some(paymentInfo => parseFloat(paymentInfo.first_payment_status) > 0) ? 'black' : 'red' }}>
//                     {value.new_payment_information.some(paymentInfo => parseFloat(paymentInfo.first_payment_status) > 0) ? `$${value.new_payment_information.find(paymentInfo => parseFloat(paymentInfo.first_payment_status) > 0).first_payment_status}` : 'Not Given'}
//                 </td>

//                 <td style={{ color: value.new_payment_information.some(paymentInfo => parseFloat(paymentInfo.second_payment_status) > 0) ? 'black' : 'red' }}>
//                     {value.new_payment_information.some(paymentInfo => parseFloat(paymentInfo.second_payment_status) > 0) ? `$${value.new_payment_information.find(paymentInfo => parseFloat(paymentInfo.second_payment_status) > 0).second_payment_status}` : 'Not Given'}
//                 </td>

//                 <td style={{ color: value.new_payment_information.some(paymentInfo => parseFloat(paymentInfo.third_payment_status) > 0) ? 'black' : 'red' }}>
//                     {value.new_payment_information.some(paymentInfo => parseFloat(paymentInfo.third_payment_status) > 0) ? `$${value.new_payment_information.find(paymentInfo => parseFloat(paymentInfo.third_payment_status) > 0).third_payment_status}` : 'Not Given'}
//                 </td>

//                 <td style={{ color: value.new_payment_information.some(paymentInfo => parseFloat(paymentInfo.fourth_payment_status) > 0) ? 'black' : 'red' }}>
//                     {value.new_payment_information.some(paymentInfo => parseFloat(paymentInfo.fourth_payment_status) > 0) ? `$${value.new_payment_information.find(paymentInfo => parseFloat(paymentInfo.fourth_payment_status) > 0).fourth_payment_status}` : 'Not Given'}
//                 </td>

//             </>
//         );
//     };

//     const jobSupportTableBody = (value) => (
//         <>
//             <td>{value.id}</td>
//             <td>{value.name} {value.student_record?.first_name} {value.student_record?.last_name}</td>
//             <td>{value.email} {value.student_record?.email}</td>
//             <td>
//                 {value.job_support_record_payment?.deposit ? `$${value.job_support_record_payment.deposit}` : <span style={{ color: 'red' }}>Not Given</span>}
//             </td>
//             <td>
//                 {value.job_support_record_payment?.target_amount_external || value.job_support_record_payment?.target_amount_internal ?
//                     `$${value.job_support_record_payment.target_amount_external || value.job_support_record_payment.target_amount_internal}`
//                     : <span style={{ color: 'red' }}>Not given</span>}
//             </td>
//             <td>
//                 {value.job_support_record_payment?.paid_amount ? `$${value.job_support_record_payment.paid_amount}` : <span style={{ color: 'red' }}>Not Given</span>}
//             </td>
//             <td>
//                 {value.job_support_record_payment?.balance_remaining ? `$${value.job_support_record_payment.balance_remaining}` : <span style={{ color: 'red' }}>Not Given</span>}
//             </td>
//             <td>
//                 {value.job_support_record_payment?.refund_amount ? `$${value.job_support_record_payment.refund_amount}` : <span style={{ color: 'red' }}>Not Given</span>}
//             </td>

//             <td>
//                 {value.job_support_record_payment?.first_payment_status ? `$${value.job_support_record_payment.first_payment_status}` : <span style={{ color: 'red' }}>Not Given</span>}
//             </td>
//             <td>
//                 {value.job_support_record_payment?.second_payment_status ? `$${value.job_support_record_payment.second_payment_status}` : <span style={{ color: 'red' }}>Not Given</span>}
//             </td>
//             <td>
//                 {value.job_support_record_payment?.third_payment_status ? `$${value.job_support_record_payment.third_payment_status}` : <span style={{ color: 'red' }}>Not Given</span>}
//             </td>
//             {/* <td>
//         {Math.abs(
//             (parseFloat(value.job_support_record_payment?.paid_amount) || 0) -
//             (parseFloat(value.job_support_record_payment?.target_amount_external) || 0) -
//             (parseFloat(value.job_support_record_payment?.target_amount_internal) || 0)
//         ).toFixed(2)}
//     </td> */}
//         </>
//     );



//     const handlePageChange = (event, newPage) => {
//         if (tabs[0].active) {
//             setSoftwareTrainingCurrentPage(newPage);
//         } else if (tabs[1].active) {
//             setJobSupportCurrentPage(newPage);
//         }
//     };

//     const handleFilterClick = () => {
//         setSoftwareTrainingCurrentPage(1);
//         setJobSupportCurrentPage(1);
//     };

//     const handleKeyPress = (e) => {
//         if (e.key === 'Enter') {
//             handleFilterClick(nameQuery);
//         }
//     };


//     const showTotal = () => {
//         let totalTargetAmount = 0;
//         let totalPaidAmount = 0;
//         let totalRemainingAmount = 0;

//         if (tabs[0].active) {
//             filteredSoftwareTrainingData.forEach((item) => {
//                 const targetAmount = parseFloat(item.payment_information?.target_amount);
//                 if (!isNaN(targetAmount)) {
//                     totalTargetAmount += targetAmount;
//                 }
//                 const paidAmount = parseFloat(item.payment_information?.paid_amount);
//                 if (!isNaN(paidAmount)) {
//                     totalPaidAmount += paidAmount;
//                 }
//                 // const remainingAmount = targetAmount - paidAmount;
//                 // if (!isNaN(remainingAmount)) {
//                 //     totalRemainingAmount += remainingAmount;
//                 // }
//                 const remainingAmount = parseFloat(item.payment_information?.balance_remaining);
//                 if (!isNaN(remainingAmount)) {
//                     totalRemainingAmount += remainingAmount;
//                 }
//             });
//         } else {
//             filteredJobSupportData.forEach((item) => {
//                 const targetAmountExternal = parseFloat(item.job_support_record_payment?.target_amount_external);
//                 const targetAmountInternal = parseFloat(item.job_support_record_payment?.target_amount_internal);
//                 const totalTargetAmountValue = isNaN(targetAmountExternal) ? 0 : targetAmountExternal;
//                 totalTargetAmount += isNaN(targetAmountInternal) ? totalTargetAmountValue : targetAmountInternal + totalTargetAmountValue;

//                 const paidAmount = parseFloat(item.job_support_record_payment?.paid_amount);
//                 if (!isNaN(paidAmount)) {
//                     totalPaidAmount += paidAmount;
//                 }

//                 // const remainingAmount = totalTargetAmount - totalPaidAmount;
//                 // if (!isNaN(remainingAmount)) {
//                 //     totalRemainingAmount = remainingAmount;
//                 // }
//                 const remainingAmount = parseFloat(item.job_support_record_payment?.balance_remaining);
//                 if (!isNaN(remainingAmount)) {
//                     totalRemainingAmount += remainingAmount;
//                 }
//             });
//         }

//         return (
//             <>
//                 {tableHeader.map((col, key) => (
//                     col.active ? (
//                         <td key={key}>
//                             {col.data_key === 'target_amount' ? <p className="highlight-text">{`Total Target Amount: ${totalTargetAmount.toFixed(2)}`}</p> : ""}
//                             {col.data_key === 'paid_amount' ? <p className="highlight-text">{`Total Paid Amount: ${totalPaidAmount.toFixed(2)}`}</p> : ""}
//                             {col.data_key === 'remaining_amount' ? <p className="highlight-text">{`Total Remaining Amount: ${totalRemainingAmount.toFixed(2)}`}</p> : ""}
//                         </td>
//                     ) : null
//                 ))}
//             </>
//         );
//     };


// const inputStyle = {
//     backgroundColor: '#EAEFF4',
//     boxSizing: 'border-box',
//     border: '2px solid #CDCDCD',
//     borderRadius: '5px',
//     padding: '0.5rem',
//     margin: '0.5rem',
//     fontSize: '1rem',
//     zIndex: 0,
//     position: 'relative'
// };

// const labelStyle = {
// //     position: 'absolute',
// //     top: '0px',
// //     left: '10px',
// //     padding: '0 1px',
// //     backgroundColor: '#EAEFF4',
// //     color: '#DADCE0',
// //     fontSize: '14px',
// //     transition: '0.5s',
// //     borderRadius: '4px',
// //     zIndex: 0,
// //     fontWeight: 600,
// //     color: '#006CB0'
// };


//     return (
//         <div>
//             <div className="layout-title">
//                 <p>Income</p>
//             </div>


//             <div >
//                 <div className="align-left border border-radius">
//                     <FilledRoundedButton name="Filters" size="medium" onClickHandler={toggleFilter} color="secondary" />
//                 </div>
//                 {filterOpen && (
//                     <div>
//                         <div className="filter-body-left">
//                             <TextInput id="from" name="from" placeholder="From" type="date" size="small" onChangeHandler={(e) => setStartDate(e.target.value)} value={startDate} />
//                             <TextInput id="to" name="to" placeholder="To" type="date" size="small" onChangeHandler={(e) => setEndDate(e.target.value)} value={endDate} />

//                             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//                                 <div style={{ position: 'relative' }}>
//                                     <input
//                                         type="text"
//                                         style={inputStyle}
//                                         value={searchQuery}
//                                         onChange={(e) => setSearchQuery(e.target.value)}
//                                         onKeyPress={handleKeyPress}
//                                     />
//                                     <label style={labelStyle}>Search</label>

//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 )}
//             </div>

//             <div style={{ margin: '1rem' }}>
//                 <Tab tabList={tabs} onClickHandler={tabChangeHandler} />

//                 <div style={{ display: 'flex' }}>
//                     {loading ? (
//                         <Loading />
//                     ) : (
//                         <div style={{ flex: 3, overflowX: 'auto', width: '50rem' }}>
//                             <SimpleTable
//                                 header={tableHeader.filter((header) => header.active === true)}
//                                 values={tabs[0].active ? filteredSoftwareTrainingData.slice(softwareTrainingStartIndex, softwareTrainingEndIndex) : filteredJobSupportData.slice(jobSupportStartIndex, jobSupportEndIndex)}
//                                 activeRow={openCommentBox}
//                                 tableBody={tabs[0].active ? softwareTrainingTableBody : jobSupportTableBody}
//                                 page={tabs[0].active ? softwareTrainingCurrentPage : jobSupportCurrentPage}
//                                 handlePage={handlePageChange}
//                                 totalPage={tabs[0].active ? Math.ceil(filteredSoftwareTrainingData.length / itemsPerPage) : Math.ceil(filteredJobSupportData.length / itemsPerPage)}
//                                 total={filterOpen ? showTotal : null}
//                             />
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Income;

























import React, { useState, useEffect, useMemo } from 'react';
import './income.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Tab from '../../components/Tab';
import Loading from '../../components/Loading';
import Pagination from '@mui/material/Pagination';
import EditOtherIncome from './EditOtherIncome';
import AddOtherIncome from './AddOtherIncome';
import TextInput from '../../components/TextInput';
import FilledRoundedButton from "../../components/Button/FilledRoundedButton"


const filterByDateAndName = (data, start, end, searchQuery) => {
    const filteredByDate = filterByDate(data, start, end);

    if (!searchQuery) {
        return filteredByDate;
    }

    // Filter data based on date range, name query, and ID query
    return filteredByDate.filter((item) => {
        const id = String(item.id);
        const name = item.name ? item.name.toLowerCase() : '';
        const firstName = item.student_record?.first_name ? item.student_record.first_name.toLowerCase() : '';
        const customerName = item.customer_name ? item.customer_name.toLowerCase() : '';
        const email = item.email ? item.email.toLowerCase() : '';
        const studentEmail = item.student_record?.email ? item.student_record.email.toLowerCase() : '';
        const batchName = item.batch ? item.batch.toLowerCase() : '';

        const itemName = id + name + firstName + email + customerName + studentEmail + batchName;

        return itemName.includes(searchQuery.toLowerCase());
    });
};


const filterByDate = (data, start, end) => {
    if (!start && !end) {
        return data;
    }

    // Filter data based on date range
    return data.filter((item) => {
        const itemDate = new Date(item.date || item.job_support_record_payment?.deposit_payment_date || item.job_support_record_payment?.first_payment_date || item.job_support_record_payment?.second_payment_date || item.job_support_record_payment?.third_payment_date || item.job_support_record_payment?.fourth_payment_date);
        return (
            (!start || itemDate >= new Date(start)) &&
            (!end || itemDate <= new Date(end))
        );
    });
};



const jobSupportTableHeader = [
    { name: 'ID', size: 'small', active: true, data_key: 'id' },
    { name: 'Name', size: 'medium', active: true, data_key: 'name' },
    { name: 'Email', size: 'medium', active: true, data_key: 'email' },
    { name: 'Deposit', size: 'small', active: true, data_key: 'deposit' },
    { name: 'Target Amount', size: 'small', active: true, data_key: 'target_amount' },
    { name: 'Paid Amount', size: 'small', active: true, data_key: 'paid_amount' },
    { name: 'Remaining', size: 'small', active: true, data_key: 'remaining_amount' },
    { name: 'Refund Amount', size: 'small', active: true, data_key: 'refund_amount' },
    { name: "1st Installment", size: "medium", active: true, data_key: "first_installment" },
    { name: "2nd Installment", size: "medium", active: true, data_key: "second_installment" },
    { name: "3rd Installment", size: "medium", active: true, data_key: "third_installment" },
];

const softTrainingTableHeader = [
    { name: 'ID', size: 'small', active: true, data_key: 'id' },
    { name: 'Date', size: 'medium', active: true, data_key: 'created_at' },
    { name: 'Name', size: 'medium', active: true, data_key: 'name' },
    { name: 'Email', size: 'medium', active: true, data_key: 'email' },
    { name: 'Referred By', size: 'medium', active: true, data_key: 'referred_by' },
    { name: 'Batch', size: 'medium', active: true, data_key: 'batch' },
    { name: 'Enrollment Fee', size: 'small', active: true, data_key: 'enrollment_fee' },
    { name: 'Target Amount', size: 'small', active: true, data_key: 'target_amount' },
    { name: 'Paid Amount', size: 'small', active: true, data_key: 'paid_amount' },
    { name: 'Remaining', size: 'small', active: true, data_key: 'remaining_amount' },
    { name: 'Refund Amount', size: 'small', active: true, data_key: 'refund_amount' },
    { name: "1st Installment", size: "medium", active: true, data_key: "first_installment" },
    { name: "2nd Installment", size: "medium", active: true, data_key: "second_installment" },
    { name: "3rd Installment", size: "medium", active: true, data_key: "third_installment" },
    { name: "4th Installment", size: "medium", active: true, data_key: "fourth_installment" },
];

const otherIncomeTableHeader = [
    { name: 'Actions', size: 'medium', active: true, data_key: 'actions' },
    { name: 'Payment Method', size: 'medium', active: true, data_key: 'payment_method' },
    { name: 'Date', size: 'medium', active: true, data_key: 'date' },
    { name: 'Detail', size: 'medium', active: true, data_key: 'detail' },
    { name: 'Category', size: 'medium', active: true, data_key: 'category' },
    // { name: 'Amount', size: 'medium', active: true, data_key: 'amount' },
    { name: 'Target Amount', size: 'medium', active: true, data_key: 'target_amount' },
    { name: 'Paid Amount', size: 'medium', active: true, data_key: 'paid_amount' },
    { name: 'Remaining Amount', size: 'medium', active: true, data_key: 'remaining_amount' },
    { name: 'Customer Name', size: 'medium', active: true, data_key: 'customer_name' },
    { name: 'Receipt', size: 'medium', active: true, data_key: 'receipt' },
];


function Income() {
    const role = useSelector((state) => state.auth.role);
    const [tabs, setTabs] = useState([
        { name: 'Software Training', active: true },
        { name: 'Job Support', active: false },
        { name: 'Other Income', active: false },
    ]);
    const [tableHeader, setTableHeader] = useState(
        tabs[0].active ? softTrainingTableHeader : jobSupportTableHeader
    );
    const [jobSupportData, setJobSupportData] = useState([]);
    const [softwareTrainingData, setSoftwareTrainingData] = useState([]);
    const [otherIncomeData, setOtherIncomeData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [softwareTrainingCurrentPage, setSoftwareTrainingCurrentPage] = useState(1);
    const [jobSupportCurrentPage, setJobSupportCurrentPage] = useState(1);
    const [otherIncomeCurrentPage, setOtherIncomeCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 10;

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [nameQuery, setNameQuery] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filterOpen, setFilterOpen] = useState(false);
    const [slicedData, setSlicedData] = useState([]);


    useEffect(() => {
        fetchIncomeData(tabs, role, setSoftwareTrainingData, setJobSupportData, setOtherIncomeData);
    }, [tabs, role]);

    useEffect(() => {
        setTableHeader(
            tabs[0].active ? softTrainingTableHeader : tabs[1].active ? jobSupportTableHeader : otherIncomeTableHeader
        );
    }, [tabs]);

    const fetchData = async (apiUrl, setDataCallback) => {
        setLoading(true);
        try {
            const response = await axios.get(apiUrl, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.data.records) {
                setDataCallback(response.data.records); // For softwareTrainingData and jobSupportData
            } else {
                setDataCallback(response.data); // For otherIncomeData
            }
        } catch (error) {
            console.error('Error during data fetching:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchIncomeData = async () => {
        const softwareTrainingApiUrl = 'https://stms.qatekinternal.com/records/partner-api/enrolled-student-list/';
        const jobSupportApiUrl = 'https://stms.qatekinternal.com/job-support/partner-api/job-support-list/';
        const otherIncomeApiUrl = 'https://ems-api.qatekinternal.com/income/other-incomes/';

        if (tabs[0].active && role.can_see_income && !softwareTrainingData.length) {
            // Fetch software training data only if it's not already fetched
            fetchData(softwareTrainingApiUrl, setSoftwareTrainingData);
        } else if (tabs[1].active && role.can_see_income && !jobSupportData.length) {
            // Fetch job support data only if it's not already fetched
            fetchData(jobSupportApiUrl, setJobSupportData);
        } else if (tabs[2].active && role.can_see_income && !otherIncomeData.length) {
            // Fetch other income data only if it's not already fetched
            fetchData(otherIncomeApiUrl, setOtherIncomeData);
        }
    }



    const handlePageChange = (event, newPage) => {
        if (tabs[0].active) {
            setSoftwareTrainingCurrentPage(newPage);
        } else if (tabs[1].active) {
            setJobSupportCurrentPage(newPage);
        } else if (tabs[2].active) {
            setOtherIncomeCurrentPage(newPage);
        }
    };

    const renderSoftwareTrainingTableBody = (data) => {
        return (
            <>
                {data.map((item, i) => (
                    <React.Fragment key={i}>
                        <tr>
                            <td>{item.id}</td>
                            <td>{item.date ? item.date.slice(0, 10) : 'Not Given'}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td style={{ color: item.referred_by ? 'black' : 'red' }}>{item.referred_by || 'Not Given'}</td>
                            <td>{item.batch}</td>
                            <td>{item.payment_information?.enrollment_payment ? `$${item.payment_information.enrollment_payment}` : 'Not Given'}</td>
                            <td>{item.payment_information?.target_amount ? `$${item.payment_information.target_amount}` : 'Not Given'}</td>
                            <td>{item.payment_information?.paid_amount ? `$${item.payment_information.paid_amount}` : 'Not Given'}</td>
                            <td style={{ color: item.payment_information?.balance_remaining ? 'black' : 'red' }}>{item.payment_information?.balance_remaining ? `$${item.payment_information.balance_remaining}` : 'Not Given'}</td>
                            <td style={{ color: item.payment_information?.refund_amount ? 'black' : 'red' }}>{item.payment_information?.refund_amount ? `$${item.payment_information.refund_amount}` : 'Not Given'}</td>
                            <td style={{ color: item.payment_information?.first_payment_status ? 'black' : 'red' }}>{item.payment_information?.first_payment_status ? `$${item.payment_information.first_payment_status}` : 'Not Given'}</td>
                            <td style={{ color: item.payment_information?.second_payment_status ? 'black' : 'red' }}>{item.payment_information?.second_payment_status ? `$${item.payment_information.second_payment_status}` : 'Not Given'}</td>
                            <td style={{ color: item.payment_information?.third_payment_status ? 'black' : 'red' }}>{item.payment_information?.third_payment_status ? `$${item.payment_information.third_payment_status}` : 'Not Given'}</td>
                            <td style={{ color: item.payment_information?.fourth_payment_status ? 'black' : 'red' }}>{item.payment_information?.fourth_payment_status ? `$${item.payment_information.fourth_payment_status}` : 'Not Given'}</td>
                        </tr>
                        {item.new_payment_information && item.new_payment_information.map((paymentInfo, index) => (
                            <React.Fragment key={`${i}-new-payment-${index}`}>
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.date ? item.date.slice(0, 10) : 'Not Given'}</td>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td style={{ color: item.referred_by ? 'black' : 'red' }}>{item.referred_by || 'Not Given'}</td>
                                    <td>{paymentInfo.batch_name || 'Not Given'}</td>
                                    <td>{paymentInfo.enrollment_payment ? `$${paymentInfo.enrollment_payment}` : 'Not Given'}</td>
                                    <td>{paymentInfo.target_amount ? `$${paymentInfo.target_amount}` : 'Not Given'}</td>
                                    <td>{paymentInfo.paid_amount ? `$${paymentInfo.paid_amount}` : 'Not Given'}</td>
                                    <td>{paymentInfo.balance_remaining ? `$${paymentInfo.balance_remaining}` : 'Not Given'}</td>
                                    <td>{paymentInfo.refund_amount ? `$${paymentInfo.refund_amount}` : 'Not Given'}</td>
                                    <td>{paymentInfo.first_payment_status ? `$${paymentInfo.first_payment_status}` : 'Not Given'}</td>
                                    <td>{paymentInfo.second_payment_status ? `$${paymentInfo.second_payment_status}` : 'Not Given'}</td>
                                    <td>{paymentInfo.third_payment_status ? `$${paymentInfo.third_payment_status}` : 'Not Given'}</td>
                                    <td style={{ color: 'red' }}>{'Not Given'}</td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </>
        );
    };


    const renderJobSupportTableBody = (data) => {
        return (
            <>
                {data.map((item, i) => (
                    <React.Fragment key={i}>
                        <tr>
                            <td>{item.id}</td>
                            <td>{item.name} {item.student_record?.first_name} {item.student_record?.last_name}</td>
                            <td>{item.email} {item.student_record?.email}</td>
                            <td>
                                {item.job_support_record_payment?.deposit ? `$${item.job_support_record_payment.deposit}` : <span style={{ color: 'red' }}>Not Given</span>}
                            </td>
                            <td>
                                {item.job_support_record_payment?.target_amount_external || item.job_support_record_payment?.target_amount_internal ?
                                    `$${item.job_support_record_payment.target_amount_external || item.job_support_record_payment.target_amount_internal}`
                                    : <span style={{ color: 'red' }}>Not given</span>}
                            </td>
                            <td>
                                {item.job_support_record_payment?.paid_amount ? `$${item.job_support_record_payment.paid_amount}` : <span style={{ color: 'red' }}>Not Given</span>}
                            </td>
                            <td>
                                {item.job_support_record_payment?.balance_remaining ? `$${item.job_support_record_payment.balance_remaining}` : <span style={{ color: 'red' }}>Not Given</span>}
                            </td>
                            <td>
                                {item.job_support_record_payment?.refund_amount ? `$${item.job_support_record_payment.refund_amount}` : <span style={{ color: 'red' }}>Not Given</span>}
                            </td>

                            <td>
                                {item.job_support_record_payment?.first_payment_status ? `$${item.job_support_record_payment.first_payment_status}` : <span style={{ color: 'red' }}>Not Given</span>}
                            </td>
                            <td>
                                {item.job_support_record_payment?.second_payment_status ? `$${item.job_support_record_payment.second_payment_status}` : <span style={{ color: 'red' }}>Not Given</span>}
                            </td>
                            <td>
                                {item.job_support_record_payment?.third_payment_status ? `$${item.job_support_record_payment.third_payment_status}` : <span style={{ color: 'red' }}>Not Given</span>}
                            </td>
                        </tr>
                        {item.new_job_support_payment && item.new_job_support_payment.map((paymentInfo, index) => (
                            <React.Fragment key={`${i}-new-payment-${index}`}>
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.name} {item.student_record?.first_name} {item.student_record?.last_name}</td>
                                    <td>{item.email} {item.student_record?.email}</td>
                                    <td style={{ color: paymentInfo.deposit ? 'black' : 'red' }}>{paymentInfo.deposit || 'Not Given'}</td>
                                    <td>{paymentInfo.target_amount_external ? `$${paymentInfo.target_amount_external}` : 'Not Given'}</td>
                                    <td>{paymentInfo.paid_amount ? `$${paymentInfo.paid_amount}` : 'Not Given'}</td>
                                    <td>{paymentInfo.balance_remaining ? `$${paymentInfo.balance_remaining}` : 'Not Given'}</td>
                                    <td>{paymentInfo.refund_amount ? `$${paymentInfo.refund_amount}` : 'Not Given'}</td>
                                    <td>{paymentInfo.first_payment_status ? `$${paymentInfo.first_payment_status}` : 'Not Given'}</td>
                                    <td>{paymentInfo.second_payment_status ? `$${paymentInfo.second_payment_status}` : 'Not Given'}</td>
                                    <td>{paymentInfo.third_payment_status ? `$${paymentInfo.third_payment_status}` : 'Not Given'}</td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </>
        );
    };


    const renderOtherIncomeTableBody = (data) => {
        return data.map((income, index) => (
            <tr key={index}>
                <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button
                        onClick={() => handleEdit(income)}
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            color: '#4CAF50',
                            padding: '5px',
                            textAlign: 'center',
                            textDecoration: 'none',
                            display: 'inline-block',
                            fontSize: '14px',
                            margin: '0 10px',
                            cursor: 'pointer'
                        }}
                    >
                        Edit
                    </button>
                    <button
                        onClick={() => openDeleteConfirmation(income)}
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            color: '#f44336',
                            padding: '5px',
                            textAlign: 'center',
                            textDecoration: 'none',
                            display: 'inline-block',
                            fontSize: '14px',
                            margin: '0 10px',
                            cursor: 'pointer'
                        }}
                    >
                        Delete
                    </button>
                </td>


                <td>{income.payment_method}</td>
                <td>{income.date}</td>
                <td>{income.detail}</td>
                <td>{income.category}</td>
                {/* <td>{income.amount}</td> */}
                <td>{income.target_amount}</td>
                <td>{income.paid_amount}</td>
                <td>{income.remaining_amount}</td>
                <td>{income.customer_name}</td>
                <td>
                    {income.receipt ? (
                        <a href={`https://ems-api.qatekinternal.com/${income.receipt}`} target="_blank" rel="noopener noreferrer">
                            View Receipt
                        </a>
                    ) : (
                        <span>Not Given</span>
                    )}
                </td>

            </tr>
        ));
    };


    const filteredSoftwareTrainingData = useMemo(() => {
        return filterByDateAndName(
            softwareTrainingData,
            startDate,
            endDate,
            searchQuery
        );
    }, [softwareTrainingData, startDate, endDate, searchQuery]);

    const filteredJobSupportData = useMemo(() => {
        return filterByDateAndName(
            jobSupportData,
            startDate,
            endDate,
            searchQuery
        );
    }, [jobSupportData, startDate, endDate, searchQuery]);


    const filteredOtherIncomeData = useMemo(() => {
        return filterByDateAndName(
            otherIncomeData,
            startDate,
            endDate,
            searchQuery
        );
    }, [otherIncomeData, startDate, endDate, searchQuery]);



    useEffect(() => {
        const paginatedData = tabs[0].active
            ? filteredSoftwareTrainingData
            : tabs[1].active
                ? filteredJobSupportData
                : filteredOtherIncomeData;

        setTotalPages(Math.ceil(paginatedData.length / itemsPerPage));

        // Update slicedData based on current page and itemsPerPage
        if (tabs[0].active) {
            setSlicedData(paginatedData.slice((softwareTrainingCurrentPage - 1) * itemsPerPage, softwareTrainingCurrentPage * itemsPerPage));
        } else if (tabs[1].active) {
            setSlicedData(paginatedData.slice((jobSupportCurrentPage - 1) * itemsPerPage, jobSupportCurrentPage * itemsPerPage));
        } else {
            setSlicedData(paginatedData.slice((otherIncomeCurrentPage - 1) * itemsPerPage, otherIncomeCurrentPage * itemsPerPage));
        }
    }, [filteredSoftwareTrainingData, filteredJobSupportData, filteredOtherIncomeData, softwareTrainingCurrentPage, jobSupportCurrentPage, otherIncomeCurrentPage, tabs]);



    const toggleFilter = () => {
        setFilterOpen(!filterOpen);
        // Reset filter values
        setStartDate(null);
        setEndDate(null);
        setNameQuery('');
    }

    const handleFilterClick = () => {
        setSoftwareTrainingCurrentPage(1);
        setJobSupportCurrentPage(1);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleFilterClick(nameQuery);
        }
    };


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedIncome, setSelectedIncome] = useState(null);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [incomeToDelete, setIncomeToDelete] = useState(null);

    const openModal = () => {
        setIsModalOpen(true);
        setSelectedIncome(null);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setDeleteConfirmationOpen(false);
    };

    const handleAddIncome = async () => {
        await fetchIncomeData();
        closeModal();
        window.location.reload();
    };

    const handleEditIncome = async () => {
        await fetchIncomeData();
        closeModal();
        window.location.reload();
    };

    const handleDeleteIncome = async () => {
        await fetchIncomeData();
        closeModal();
        window.location.reload();
    };


    const handleEdit = (income) => {
        setSelectedIncome(income);
        setIsModalOpen(true);
    };

    const handleDelete = async () => {
        try {
            const accessToken = localStorage.getItem('access');
            const config = {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            };
            await axios.delete(`https://ems-api.qatekinternal.com/income/other-incomes/${incomeToDelete.id}/`, config);
            console.log('Income deleted successfully');
            handleDeleteIncome();
            setDeleteConfirmationOpen(false);
            setIncomeToDelete(null);
        } catch (error) {
            console.error('Error deleting income:', error);
        }
    };

    const openDeleteConfirmation = (income) => {
        setIncomeToDelete(income);
        setDeleteConfirmationOpen(true);
    };

    const inputStyle = {
        backgroundColor: '#EAEFF4',
        boxSizing: 'border-box',
        border: '2px solid #CDCDCD',
        borderRadius: '5px',
        padding: '0.5rem',
        margin: '0.5rem',
        fontSize: '1rem',
        zIndex: 0,
        position: 'relative'
    };

    const labelStyle = {
        position: 'absolute',
        top: '0px',
        left: '10px',
        padding: '0 1px',
        backgroundColor: '#EAEFF4',
        color: '#DADCE0',
        fontSize: '14px',
        transition: '0.5s',
        borderRadius: '4px',
        zIndex: 0,
        fontWeight: 600,
        color: '#006CB0'
    }

    return (
        <div>
            <div className="layout-title">
                <p style={{ marginTop: '1rem', marginBottom: '.5rem' }}>Income</p>

                {tabs[2].active && (
                    <button
                        onClick={openModal}
                        style={{
                            backgroundColor: '#006CB0',
                            color: 'white',
                            padding: '8px 16px',
                            borderRadius: 4,
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase'
                        }}
                    >
                        Add Other Income
                    </button>

                )}

            </div>
            <div >
                <div className="align-left border border-radius">
                    <FilledRoundedButton name="Filters" size="medium" onClickHandler={toggleFilter} color="secondary" />
                </div>
                {filterOpen && (
                    <div>
                        <div className="filter-body-left">
                            <TextInput id="from" name="from" placeholder="From" type="date" size="small" onChangeHandler={(e) => setStartDate(e.target.value)} value={startDate} />
                            <TextInput id="to" name="to" placeholder="To" type="date" size="small" onChangeHandler={(e) => setEndDate(e.target.value)} value={endDate} />

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style={{ position: 'relative' }}>
                                    <input
                                        type="text"
                                        style={inputStyle}
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                    />
                                    <label style={labelStyle} >Search</label>

                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div>
                <Tab tabList={tabs} onClickHandler={(tab) => setTabs(tabs.map(t => t.name === tab.name ? { ...t, active: true } : { ...t, active: false }))} />
            </div>
            <div style={{ display: 'flex' }}>
                {loading ? (
                    <Loading />
                ) : (
                    <div style={{ flex: 3, overflowX: 'auto', width: '50rem' }}>
                        <div>
                            <div className="table-body">
                                <table style={{ width: '100%' }}>
                                    <thead className="thead">
                                        <tr>
                                            {tableHeader.map((th, i) => (
                                                <th className={`${th.size}-header`} key={i}>{th.name}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tabs[0].active
                                            ? renderSoftwareTrainingTableBody(slicedData)
                                            : tabs[1].active
                                                ? renderJobSupportTableBody(slicedData)
                                                : renderOtherIncomeTableBody(slicedData)}
                                    </tbody>
                                </table>
                                {deleteConfirmationOpen && (
                                    <div className="modal" style={{
                                        position: 'fixed',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        zIndex: 1000,
                                        width: '100%',
                                        height: '100%'
                                    }}>
                                        <div className="modal-content" style={{
                                            backgroundColor: 'white',
                                            borderRadius: 5,
                                            padding: 20,
                                            maxWidth: 300,
                                            textAlign: 'center',
                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                            transition: '0.3s'
                                        }}>
                                            <h2 style={{ marginBottom: 10 }}>Delete Income</h2>
                                            <p style={{ marginBottom: 20 }}>Are you sure you want to delete this income?</p>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <button
                                                    onClick={handleDelete}
                                                    style={{
                                                        backgroundColor: '#f44336',
                                                        color: 'white',
                                                        padding: '10px 20px',
                                                        borderRadius: 5,
                                                        border: 'none',
                                                        cursor: 'pointer',
                                                        marginRight: 10
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                                <button
                                                    onClick={() => setDeleteConfirmationOpen(false)}
                                                    style={{
                                                        backgroundColor: '#ccc',
                                                        color: 'black',
                                                        padding: '10px 20px',
                                                        borderRadius: 5,
                                                        border: 'none',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div style={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    display: isModalOpen ? 'flex' : 'none',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 1000,
                                    opacity: isModalOpen ? 1 : 0,
                                    pointerEvents: isModalOpen ? 'auto' : 'none',
                                    transition: 'opacity 0.3s ease'
                                }}>
                                    <div style={{
                                        backgroundColor: 'white',
                                        borderRadius: 5,
                                        padding: 20,
                                        maxWidth: '80%',
                                        maxHeight: '80%',
                                        overflow: 'auto'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                            <h3>{selectedIncome ? 'Edit Other Income' : 'Add Other Income'}</h3>
                                            <span className="close" style={{ cursor: 'pointer' }} onClick={closeModal}>&times;</span>
                                        </div>
                                        <div style={{ marginTop: 20 }}>
                                            {selectedIncome ? (
                                                <EditOtherIncome incomeId={selectedIncome.id} handleEditIncome={handleEditIncome} />
                                            ) : (
                                                <AddOtherIncome handleAddIncome={handleAddIncome} />
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <Pagination
                                style={{ marginRight: '1rem', display: 'flex', justifyContent: 'right' }}
                                color="primary"
                                count={totalPages}
                                variant="outlined"
                                shape="rounded"
                                page={
                                    tabs[0].active
                                        ? softwareTrainingCurrentPage
                                        : tabs[1].active
                                            ? jobSupportCurrentPage
                                            : otherIncomeCurrentPage
                                }
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Income;
