import React, {useState, useEffect, useRef} from 'react'
import "./alert.css"

import { useDispatch, useSelector } from 'react-redux'
import {REMOVE_MESSAGE} from "./../../actions/types"

function Alert() {
    const loaded = useRef(false)
    const message = useSelector(state => state.message)
    const dispatch = useDispatch()

    const alertStatus = (display) => {
        document.getElementById('alert').style.display = display;
    }

    useEffect(() => {
        if(loaded.current) {
            if(message.msg){
                alertStatus("block")
                const timer = setInterval(() => {
                    alertStatus("none");
                    dispatch({
                        type: REMOVE_MESSAGE
                    })
                }, 5000)
                return () => clearInterval(timer)
            } else alertStatus("none")
        } else loaded.current = true
    }, [message.msg])

    return (
        <div className={`alert ${parseInt(message.status) >= 400 ? "danger" : parseInt(message.status) === 1 ? "warning" : "success"}`} id="alert">
            <span className="closebtn" onClick={event => alertStatus("none")}>&times;</span>  
            {message.msg}
        </div>
    )
}

export default Alert