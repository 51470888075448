import React, { useEffect, useState } from 'react'
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import Login from "./layouts/Login"
import Register from "./layouts/Register"
import WithNav from './WithNav'
import Settings from './layouts/Settings';
import Alert from './components/Alert'
import Profile from './layouts/Profile'
import ExpenseList from './layouts/Expense/ExpenseList'
import Income from './layouts/Income'
import AddIncome from './layouts/Income/AddIncome/AddIncome'
import Vendor from './layouts/Vendor'
import Employee from './layouts/Employee'
import Transaction from './layouts/Transaction'
import Dashboard from './layouts/Dashboard'
import Loading from './components/Loading'
import PublicRoute from "./utility/PublicRoute"
import { useDispatch, useSelector } from "react-redux"

import { loadUser } from "./actions/auths"
import axios from 'axios'
import ResetPassword from './views/ResetPassword';
import ResetPasswordRequestEmail from './views/ResetPasswordRequestEmail';
import Footer from './layouts/Footer/Footer';
import IncomeNew from './layouts/IncomeNew';
import EditIncomeNew from './layouts/EditIncomeNew'
import EditIncomeJobSupportNew from './layouts/EditIncomeJobSupportNew';
import AddOtherIncome from './layouts/Income/AddOtherIncome';
import ConsultantInvoiceForm from './actions/ConsultantInvoiceForm';
import CustomerInvoiceForm from './actions/CustomerInvoiceForm';
import Invoices from './layouts/Invoices/Invoices';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

function App() {
  const [loading, setLoading] = useState(false)
  // const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadUser())
    // .then(response => {
    //   if(response === 400)
    //     navigate('/login')
    // })
  })

  return (
    <>
      <Alert />

      {loading ? <Loading /> :
        <Router>
          <Routes>
            <Route exact path="/login" element={<PublicRoute><Login /></PublicRoute>} />
            <Route exact path="/register" element={<PublicRoute><Register /></PublicRoute>} />
            <Route element={<WithNav />}>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/setting" element={<Settings />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/expense" element={<ExpenseList />} />
              <Route exact path="/income" element={<Income />} />
              <Route exact path="/income/create" element={<AddIncome />} />
              <Route exact path="/income/update/:id" element={<AddIncome />} />

              <Route exact path="/other-income/add" element={<AddOtherIncome />} />

              <Route exact path="/vendors" element={<Vendor />} />
              <Route exact path="/employee" element={<Employee />} />
              <Route exact path="/transaction" element={<Transaction />} />

              <Route exact path="/invoices" element={<Invoices />} />

              <Route exact path="income/income-new" element={<IncomeNew />} />
              <Route exact path="income/edit-income-new/:incomeId" element={<EditIncomeNew />} />
              <Route exact path="income/edit-income-job-support-new/:incomeId" element={<EditIncomeJobSupportNew />} />
            </Route>
            <Route exact path='/password/reset/confirm/:uid/:token' element={<ResetPassword />} />
            <Route exact path='/reset-password-request' element={<ResetPasswordRequestEmail />} />



            <Route exact path="/consultant-invoice" element={<ConsultantInvoiceForm />} />
            <Route exact path="/customer-invoice" element={<CustomerInvoiceForm />} />


          </Routes>

        </Router>
      }
      {/* <Footer /> */}
    </>
  );
}

export default App;
