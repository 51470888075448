import React, { useState, useEffect, useRef } from 'react'
import { FiAlignJustify, FiHome, FiBookOpen, FiSettings, FiUsers } from 'react-icons/fi'
import { BiMoney, BiDollarCircle, BiUserCircle } from 'react-icons/bi'
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { MdAutoGraph } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useNavigate, NavLink } from 'react-router-dom'

import Navbar from "../Navbar/Navbar"

import SidebarMenuItemButton from "./../../components/Button/SidebarMenuItemButton"

import "./index.css"
import logo from "./../../images/qatek-logo.png"
import Footer from '../Footer/Footer'
import { BsShopWindow } from 'react-icons/bs'

function Sidebar() {
    // const ref = useRef()
    const user = useSelector(state => state.auth.user)
    const role = useSelector(state => state.auth.role)
    const navigate = useNavigate()
    const [expand, setExpand] = useState(false)
    const navLinkStyle = { display: "flex", justifyContent: "flex-start", width: "7rem", textDecoration: "none", color: "inherit" }

    // useEffect(() => {
    //     const checkIfClickedOutside = e => {
    //         if (expand && ref.current && !ref.current.contains(e.target)) {
    //             setExpand(false)
    //         }
    //     }
    //     document.addEventListener("mousedown", checkIfClickedOutside)

    //     return () => {
    //         document.removeEventListener("mousedown", checkIfClickedOutside)
    //     }
    // }, [expand])

    return (
        <div>
            <Navbar />
            <div className={`sidebar ${expand ? "hide" : ""}`}>
                <div className="sidebar-content">
                    <FiAlignJustify style={{ paddingLeft: '8px' }} size="30" className="expand-button" onClick={() => setExpand(!expand)} />
                    <p className="user-icon" onClick={() => navigate('/profile')} style={{ cursor: 'pointer' }}>{user.username[0].toUpperCase()}</p>
                    <p style={{ fontSize: ".7rem", color: "#797979", textAlign: 'center', marginTop: '4px' }}>{role?.role_name}</p>
                    <br></br>
                    {(role.level <= 2) && <FiHome size="30" className="side-item" onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />}
                    <BiMoney size="30" className="side-item" onClick={() => navigate('/expense')} />
                    {role.can_see_income && <BiDollarCircle size="30" className="side-item" onClick={() => navigate('/income')} />}
                    {/* <FaBalanceScale size="30" className="side-item"/> */}
                    {role.can_manage_vendor && <BsShopWindow size="30" className="side-item" onClick={() => navigate('/vendors')} />}
                    {role.can_manage_employee && <FiUsers size="30" className="side-item" onClick={() => navigate('/employee')} />}

                    {role.can_see_transaction && <MdAutoGraph size="30" className="side-item" onClick={() => navigate('/transaction')} />}


                    {<LiaFileInvoiceDollarSolid size="30" className="side-item" onClick={() => navigate('/invoices')} />}


                    {(role.can_manage_user || role.can_manage_branch || role.can_manage_role || role.can_invite_user) && <FiSettings size="30" className="side-item" onClick={() => navigate('/setting')} />}
                </div>

                <div className="sidebar-content" style={{ marginBottom: "1rem" }}>
                    <img src={logo} alt="QATEK Logo" width="60" height="50" style={{ paddingBottom: '5px' }}></img>
                    <p style={{ color: "#000000" }}>EMS</p>
                </div>
            </div>
            <div className={`sidebar ${!expand ? "hide" : ""}`}>
                <div className="sidebar-content">
                    <FiAlignJustify style={{ paddingLeft: '8px' }} size="30" className="expand-button" onClick={() => setExpand(!expand)} />
                    <p className="user-icon" onClick={() => navigate('/profile')} style={{ cursor: 'pointer' }}>{user.username[0].toUpperCase()}</p>
                    <p style={{ fontWeight: "bold", paddingTop: ".5rem", textAlign: 'center' }}>{user?.name}</p>
                    <p style={{ fontSize: ".7rem", color: "#797979", textAlign: 'center' }}>{user?.email}</p>
                    <p style={{ fontSize: ".7rem", color: "#797979", textAlign: 'center' }}>{role?.role_name}</p>

                    {(role.level <= 2) && <div>
                        <SidebarMenuItemButton name={
                            <span style={{ display: "flex", justifyContent: "flex-start", width: "7rem" }} onClick={() => navigate('/')}>
                                <FiHome size="20" className="details-side-item" />
                                <p style={{ paddingTop: "0.3rem", paddingLeft: ".6rem" }}>Dashboard</p>
                            </span>
                        }
                        />
                    </div>}

                    <div>
                        <SidebarMenuItemButton name={
                            <span style={{ display: "flex", justifyContent: "flex-start", width: "7rem" }} onClick={() => navigate('/expense')}>
                                <BiMoney size="20" className="details-side-item" />
                                <p style={{ paddingTop: "0.3rem", paddingLeft: ".6rem" }}>Expenses</p>
                            </span>
                        }
                        />
                    </div>
                    {role.can_see_income && <div>
                        <SidebarMenuItemButton name={
                            <span style={{ display: "flex", justifyContent: "flex-start", width: "7rem" }} onClick={() => navigate('/income')}>
                                <BiDollarCircle size="20" className="details-side-item" />
                                <p style={{ paddingTop: "0.3rem", paddingLeft: ".6rem" }}>Income</p>
                            </span>
                        }
                        />
                    </div>}
                    {/* <div>
                <SidebarMenuItemButton name={
                        <span style={{display: "flex", justifyContent: "flex-start", width:"7rem"}}>
                            <FaBalanceScale size="20" className="details-side-item"/>
                            <p style={{paddingTop:"0.3rem", paddingLeft:".6rem"}}>Accounting</p>
                        </span>
                    }
                />
            </div> */}
                    {role.can_manage_vendor &&
                        <SidebarMenuItemButton name={
                            <span style={{ display: "flex", justifyContent: "flex-start", width: "7rem" }} onClick={() => navigate('/vendors')}>
                                <BsShopWindow size="20" className="details-side-item" />
                                <p style={{ paddingTop: "0.3rem", paddingLeft: ".6rem" }}>Vendors</p>
                            </span>
                        } />
                    }

                    {role.can_manage_employee && <div>
                        <SidebarMenuItemButton name={
                            <span style={{ display: "flex", justifyContent: "flex-start", width: "7rem" }} onClick={() => navigate('/employee')}>
                                <FiUsers size="20" className="details-side-item" />
                                <p style={{ paddingTop: "0.3rem", paddingLeft: ".6rem" }}>Employees</p>
                            </span>
                        }
                        />
                    </div>}
                    {role.can_see_transaction && <div>
                        <SidebarMenuItemButton name={
                            <span style={{ display: "flex", justifyContent: "flex-start", width: "7rem" }} onClick={() => navigate('/transaction')}>
                                <MdAutoGraph size="20" className="details-side-item" />
                                <p style={{ paddingTop: "0.3rem", paddingLeft: ".6rem" }}>Transaction</p>
                            </span>
                        }
                        />
                    </div>}

                    {<div>
                        <SidebarMenuItemButton name={
                            <span style={{ display: "flex", justifyContent: "flex-start", width: "7rem" }} onClick={() => navigate('/invoices')}>
                                <LiaFileInvoiceDollarSolid size="20" className="details-side-item" />
                                <p style={{ paddingTop: "0.3rem", paddingLeft: ".6rem" }}>Invoices</p>
                            </span>
                        }
                        />
                    </div>}


                    {(role.can_manage_user || role.can_manage_branch || role.can_manage_role || role.can_invite_user) &&
                        <div>
                            <SidebarMenuItemButton name={
                                <span style={{ display: "flex", justifyContent: "flex-start", width: "7rem", cursor: "pointer" }} onClick={() => navigate('/setting')}>
                                    <FiSettings size="20" className="details-side-item" />
                                    <p style={{ paddingTop: "0.3rem", paddingLeft: ".6rem" }}>Settings</p>
                                </span>
                            }
                            />
                        </div>
                    }


                </div>

                <div className="sidebar-content" style={{ marginBottom: "1rem" }}>
                    <img src={logo} alt="QATEK Logo" width="90" height="70" style={{ paddingBottom: '5px' }} />
                    {/* <p style={{fontWeight:"Bold",color:"#C4C4C4", marginRight:".8rem"}}>QATEK</p> */}
                    <p style={{ color: "#000000", fontSize: ".8rem", textAlign: "center" }}>Expense Management System</p>
                </div>
            </div>

        </div>
    )
}

export default Sidebar