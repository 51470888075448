import React from 'react'
import "./tab.css"

function Tab({tabList, onClickHandler}) {
    return (
        <div className="tab">
            {tabList.map((tab, i) => (
                <div className={`tab-item ${tab.active ? "active" : ""}`} key={i}
                        onClick={() => onClickHandler(tab)}>
                            {tab.name}
                </div>
            ))}
        </div>
    )
}

export default Tab