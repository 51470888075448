import React from 'react';
import styled from 'styled-components';
import COLORS from '../utility/styles';

const H5 = styled.h5`
    color: ${COLORS.primaryColor};
    font-size: 1.5rem;
    margin: 0;
`

const Header = (props) => {
  return (
    <H5>{props.text}</H5>
  )
}


export default Header