import axios from "axios";
import Cookies from 'js-cookie'
import moment from 'moment'
import 'moment-timezone'

export const getHeader = (getState) => {
    //Get Token from state
    const token = getState().auth.access

    //Header of request
    const csrftoken = Cookies.get('csrftoken');
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken
        }
    }

    //IF token exists the add to the header
    if (token){
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
}

export const utcTime = (time, format) => {
    return moment(time, format).utc().format(format)
}

export const getLocalDateFormat = (time, format) => {
    return moment(time).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).toDate()
}

export const userTime = (time, format) => {
    return moment(time).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(format)
}

export const currencies = {
    ADP: {
      code: 'ADP',
      name: 'Andorran peseta',
      currencySymbol: 'ADP',
      symbol: 'ADP',
    },
    AED: {
      code: 'AED',
      name: 'UAE dirham',
      currencySymbol: 'AED',
      symbol: 'AED',
    },
    AFA: {
      code: 'AFA',
      name: 'Afghan afghani (1927–2002)',
      currencySymbol: 'AFA',
      symbol: 'AFA',
    },
    AFN: {
      code: 'AFN',
      name: 'Afghan Afghani',
      currencySymbol: 'AFN',
      symbol: '؋',
    },
    ALK: {
      code: 'ALK',
      name: 'Albanian lek (1946–1965)',
      currencySymbol: 'ALK',
      symbol: 'ALK',
    },
    ALL: {
      code: 'ALL',
      name: 'Albanian lek',
      currencySymbol: 'ALL',
      symbol: 'LEK',
    },
    AMD: {
      code: 'AMD',
      name: 'Armenian dram',
      currencySymbol: 'AMD',
      symbol: 'AMD',
    },
    ANG: {
      code: 'ANG',
      name: 'Netherlands Antillean guilder',
      currencySymbol: 'ANG',
      symbol: 'ƒ',
    },
    AOA: {
      code: 'AOA',
      name: 'Angolan kwanza',
      currencySymbol: 'Kz',
      symbol: 'Kz',
    },
    AOK: {
      code: 'AOK',
      name: 'Angolan kwanza (1977–1991)',
      currencySymbol: 'AOK',
      symbol: 'AOK',
    },
    AON: {
      code: 'AON',
      name: 'Angolan new kwanza (1990–2000)',
      currencySymbol: 'AON',
      symbol: 'AON',
    },
    AOR: {
      code: 'AOR',
      name: 'Angolan readjusted kwanza (1995–1999)',
      currencySymbol: 'AOR',
      symbol: 'AOR',
    },
    ARA: {
      code: 'ARA',
      name: 'Argentine austral',
      currencySymbol: 'ARA',
      symbol: 'ARA',
    },
    ARL: {
      code: 'ARL',
      name: 'Argentine peso ley (1970–1983)',
      currencySymbol: 'ARL',
      symbol: 'ARL',
    },
    ARM: {
      code: 'ARM',
      name: 'Argentine peso (1881–1970)',
      currencySymbol: 'ARM',
      symbol: 'ARM',
    },
    ARP: {
      code: 'ARP',
      name: 'Argentine peso (1983–1985)',
      currencySymbol: 'ARP',
      symbol: 'ARP',
    },
    ARS: {
      code: 'ARS',
      name: 'Argentine peso',
      currencySymbol: '$',
      symbol: '$',
    },
    ATS: {
      code: 'ATS',
      name: 'Austrian schilling',
      currencySymbol: 'ATS',
      symbol: 'ATS',
    },
    AUD: {
      code: 'AUD',
      name: 'Australian dollar',
      currencySymbol: 'A$',
      symbol: '$',
    },
    AWG: {
      code: 'AWG',
      name: 'Aruban florin',
      currencySymbol: 'AWG',
      symbol: 'ƒ',
    },
    AZM: {
      code: 'AZM',
      name: 'Azerbaijani manat (1993–2006)',
      currencySymbol: 'AZM',
      symbol: 'AZM',
    },
    AZN: {
      code: 'AZN',
      name: 'Azerbaijani manat',
      currencySymbol: 'AZN',
      symbol: 'ман',
    },
    BAD: {
      code: 'BAD',
      name: 'Bosnia-Herzegovina dinar (1992–1994)',
      currencySymbol: 'BAD',
      symbol: 'BAD',
    },
    BAM: {
      code: 'BAM',
      name: 'Bosnia-Herzegovina convertible mark',
      currencySymbol: 'KM',
      symbol: 'KM',
    },
    BAN: {
      code: 'BAN',
      name: 'Bosnia-Herzegovina new dinar (1994–1997)',
      currencySymbol: 'BAN',
      symbol: 'BAN',
    },
    BBD: {
      code: 'BBD',
      name: 'Barbadian dollar',
      currencySymbol: '$',
      symbol: '$',
    },
    BDT: {
      code: 'BDT',
      name: 'Bangladeshi taka',
      currencySymbol: '৳',
      symbol: '৳',
    },
    BEC: {
      code: 'BEC',
      name: 'Belgian franc (convertible)',
      currencySymbol: 'BEC',
      symbol: 'BEC',
    },
    BEF: {
      code: 'BEF',
      name: 'Belgian franc',
      currencySymbol: 'BEF',
      symbol: 'BEF',
    },
    BEL: {
      code: 'BEL',
      name: 'Belgian franc (financial)',
      currencySymbol: 'BEL',
      symbol: 'BEL',
    },
    BGL: {
      code: 'BGL',
      name: 'Bulgarian hard lev',
      currencySymbol: 'BGL',
      symbol: 'BGL',
    },
    BGM: {
      code: 'BGM',
      name: 'Bulgarian socialist lev',
      currencySymbol: 'BGM',
      symbol: 'BGM',
    },
    BGN: {
      code: 'BGN',
      name: 'Bulgarian lev',
      currencySymbol: 'BGN',
      symbol: 'лв',
    },
    BGO: {
      code: 'BGO',
      name: 'Bulgarian lev (1879–1952)',
      currencySymbol: 'BGO',
      symbol: 'BGO',
    },
    BHD: {
      code: 'BHD',
      name: 'Bahraini dinar',
      currencySymbol: 'BHD',
      symbol: 'BHD',
    },
    BIF: {
      code: 'BIF',
      name: 'Burundian franc',
      currencySymbol: 'BIF',
      symbol: 'BIF',
    },
    BMD: {
      code: 'BMD',
      name: 'Bermudan dollar',
      currencySymbol: '$',
      symbol: '$',
    },
    BND: {
      code: 'BND',
      name: 'Brunei dollar',
      currencySymbol: '$',
      symbol: '$',
    },
    BOB: {
      code: 'BOB',
      name: 'Bolivian boliviano',
      currencySymbol: 'Bs',
      symbol: '$b',
    },
    BOL: {
      code: 'BOL',
      name: 'Bolivian boliviano (1863–1963)',
      currencySymbol: 'BOL',
      symbol: 'BOL',
    },
    BOP: {
      code: 'BOP',
      name: 'Bolivian peso',
      currencySymbol: 'BOP',
      symbol: 'BOP',
    },
    BOV: {
      code: 'BOV',
      name: 'Bolivian mvdol',
      currencySymbol: 'BOV',
      symbol: 'BOV',
    },
    BRB: {
      code: 'BRB',
      name: 'Brazilian new cruzeiro (1967–1986)',
      currencySymbol: 'BRB',
      symbol: 'BRB',
    },
    BRC: {
      code: 'BRC',
      name: 'Brazilian cruzado (1986–1989)',
      currencySymbol: 'BRC',
      symbol: 'BRC',
    },
    BRE: {
      code: 'BRE',
      name: 'Brazilian cruzeiro (1990–1993)',
      currencySymbol: 'BRE',
      symbol: 'BRE',
    },
    BRL: {
      code: 'BRL',
      name: 'Brazilian real',
      currencySymbol: 'R$',
      symbol: 'R$',
    },
    BRN: {
      code: 'BRN',
      name: 'Brazilian new cruzado (1989–1990)',
      currencySymbol: 'BRN',
      symbol: 'BRN',
    },
    BRR: {
      code: 'BRR',
      name: 'Brazilian cruzeiro (1993–1994)',
      currencySymbol: 'BRR',
      symbol: 'BRR',
    },
    BRZ: {
      code: 'BRZ',
      name: 'Brazilian cruzeiro (1942–1967)',
      currencySymbol: 'BRZ',
      symbol: 'BRZ',
    },
    BSD: {
      code: 'BSD',
      name: 'Bahamian dollar',
      currencySymbol: '$',
      symbol: '$',
    },
    BTN: {
      code: 'BTN',
      name: 'Bhutanese ngultrum',
      currencySymbol: 'BTN',
      symbol: 'BTN',
    },
    BUK: {
      code: 'BUK',
      name: 'Burmese kyat',
      currencySymbol: 'BUK',
      symbol: 'BUK',
    },
    BWP: {
      code: 'BWP',
      name: 'Botswanan pula',
      currencySymbol: 'P',
      symbol: 'P',
    },
    BYB: {
      code: 'BYB',
      name: 'Belarusian new ruble (1994–1999)',
      currencySymbol: 'BYB',
      symbol: 'BYB',
    },
    BYR: {
      code: 'BYR',
      name: 'Belarusian ruble',
      currencySymbol: 'р.',
      symbol: 'p.',
    },
    BZD: {
      code: 'BZD',
      name: 'Belize dollar',
      currencySymbol: '$',
      symbol: 'BZ$',
    },
    CAD: {
      code: 'CAD',
      name: 'Canadian dollar',
      currencySymbol: 'CA$',
      symbol: '$',
    },
    CDF: {
      code: 'CDF',
      name: 'Congolese franc',
      currencySymbol: 'CDF',
      symbol: 'CDF',
    },
    CHE: {
      code: 'CHE',
      name: 'WIR euro',
      currencySymbol: 'CHE',
      symbol: 'CHE',
    },
    CHF: {
      code: 'CHF',
      name: 'Swiss franc',
      currencySymbol: 'CHF',
      symbol: 'CHF',
    },
    CHW: {
      code: 'CHW',
      name: 'WIR franc',
      currencySymbol: 'CHW',
      symbol: 'CHW',
    },
    CLE: {
      code: 'CLE',
      name: 'Chilean escudo',
      currencySymbol: 'CLE',
      symbol: 'CLE',
    },
    CLF: {
      code: 'CLF',
      name: 'Chilean unit of account (UF)',
      currencySymbol: 'CLF',
      symbol: 'CLF',
    },
    CLP: {
      code: 'CLP',
      name: 'Chilean peso',
      currencySymbol: '$',
      symbol: '$',
    },
    CNX: {
      code: 'CNX',
      name: 'Chinese People’s Bank dollar',
      currencySymbol: 'CNX',
      symbol: 'CNX',
    },
    CNY: {
      code: 'CNY',
      name: 'Chinese yuan',
      currencySymbol: 'CN¥',
      symbol: '¥',
    },
    COP: {
      code: 'COP',
      name: 'Colombian peso',
      currencySymbol: '$',
      symbol: '$',
    },
    COU: {
      code: 'COU',
      name: 'Colombian real value unit',
      currencySymbol: 'COU',
      symbol: 'COU',
    },
    CRC: {
      code: 'CRC',
      name: 'Costa Rican colón',
      currencySymbol: '₡',
      symbol: '₡',
    },
    CSD: {
      code: 'CSD',
      name: 'Serbian dinar (2002–2006)',
      currencySymbol: 'CSD',
      symbol: 'CSD',
    },
    CSK: {
      code: 'CSK',
      name: 'Czechoslovak hard koruna',
      currencySymbol: 'CSK',
      symbol: 'CSK',
    },
    CUC: {
      code: 'CUC',
      name: 'Cuban convertible peso',
      currencySymbol: '$',
      symbol: '$',
    },
    CUP: {
      code: 'CUP',
      name: 'Cuban peso',
      currencySymbol: '$',
      symbol: '₱',
    },
    CVE: {
      code: 'CVE',
      name: 'Cape Verdean escudo',
      currencySymbol: 'CVE',
      symbol: 'CVE',
    },
    CYP: {
      code: 'CYP',
      name: 'Cypriot pound',
      currencySymbol: 'CYP',
      symbol: 'CYP',
    },
    CZK: {
      code: 'CZK',
      name: 'Czech Republic koruna',
      currencySymbol: 'Kč',
      symbol: 'Kč',
    },
    DDM: {
      code: 'DDM',
      name: 'East German mark',
      currencySymbol: 'DDM',
      symbol: 'DDM',
    },
    DEM: {
      code: 'DEM',
      name: 'German mark',
      currencySymbol: 'DEM',
      symbol: 'DEM',
    },
    DJF: {
      code: 'DJF',
      name: 'Djiboutian franc',
      currencySymbol: 'DJF',
      symbol: 'DJF',
    },
    DKK: {
      code: 'DKK',
      name: 'Danish krone',
      currencySymbol: 'kr',
      symbol: 'kr',
    },
    DOP: {
      code: 'DOP',
      name: 'Dominican peso',
      currencySymbol: '$',
      symbol: 'RD$',
    },
    DZD: {
      code: 'DZD',
      name: 'Algerian dinar',
      currencySymbol: 'DZD',
      symbol: 'DZD',
    },
    ECS: {
      code: 'ECS',
      name: 'Ecuadorian sucre',
      currencySymbol: 'ECS',
      symbol: 'ECS',
    },
    ECV: {
      code: 'ECV',
      name: 'Ecuadorian unit of constant value',
      currencySymbol: 'ECV',
      symbol: 'ECV',
    },
    EEK: {
      code: 'EEK',
      name: 'Estonian kroon',
      currencySymbol: 'EEK',
      symbol: 'kr',
    },
    EGP: {
      code: 'EGP',
      name: 'Egyptian pound',
      currencySymbol: 'E£',
      symbol: '£',
    },
    ERN: {
      code: 'ERN',
      name: 'Eritrean nakfa',
      currencySymbol: 'ERN',
      symbol: 'ERN',
    },
    ESA: {
      code: 'ESA',
      name: 'Spanish peseta (A account)',
      currencySymbol: 'ESA',
      symbol: 'ESA',
    },
    ESB: {
      code: 'ESB',
      name: 'Spanish peseta (convertible account)',
      currencySymbol: 'ESB',
      symbol: 'ESB',
    },
    ESP: {
      code: 'ESP',
      name: 'Spanish Peseta',
      currencySymbol: '₧',
      symbol: '₧',
    },
    ETB: {
      code: 'ETB',
      name: 'Ethiopian Birr',
      currencySymbol: 'ETB',
      symbol: 'ETB',
    },
    EUR: { code: 'EUR', name: 'Euro', currencySymbol: '€', symbol: '€' },
    FIM: {
      code: 'FIM',
      name: 'Finnish Markka',
      currencySymbol: 'FIM',
      symbol: 'FIM',
    },
    FJD: { code: 'FJD', name: 'Fijian Dollar', currencySymbol: '$', symbol: '$' },
    FKP: {
      code: 'FKP',
      name: 'Falkland Islands Pound',
      currencySymbol: '£',
      symbol: '£',
    },
    FRF: {
      code: 'FRF',
      name: 'French Franc',
      currencySymbol: 'FRF',
      symbol: 'FRF',
    },
    GBP: {
      code: 'GBP',
      name: 'British Pound Sterling',
      currencySymbol: '£',
      symbol: '£',
    },
    GEK: {
      code: 'GEK',
      name: 'Georgian kupon larit',
      currencySymbol: 'GEK',
      symbol: 'GEK',
    },
    GEL: {
      code: 'GEL',
      name: 'Georgian lari',
      currencySymbol: 'GEL',
      symbol: 'GEL',
    },
    GHC: {
      code: 'GHC',
      name: 'Ghanaian cedi (1979–2007)',
      currencySymbol: 'GHC',
      symbol: 'GHC',
    },
    GHS: {
      code: 'GHS',
      name: 'Ghanaian cedi',
      currencySymbol: 'GHS',
      symbol: '¢',
    },
    GIP: {
      code: 'GIP',
      name: 'Gibraltar pound',
      currencySymbol: '£',
      symbol: '£',
    },
    GMD: {
      code: 'GMD',
      name: 'Gambian dalasi',
      currencySymbol: 'GMD',
      symbol: 'GMD',
    },
    GNF: {
      code: 'GNF',
      name: 'Guinean franc',
      currencySymbol: 'FG',
      symbol: 'FG',
    },
    GNS: {
      code: 'GNS',
      name: 'Guinean syli',
      currencySymbol: 'GNS',
      symbol: 'GNS',
    },
    GQE: {
      code: 'GQE',
      name: 'Equatorial Guinean ekwele',
      currencySymbol: 'GQE',
      symbol: 'GQE',
    },
    GRD: {
      code: 'GRD',
      name: 'Greek drachma',
      currencySymbol: 'GRD',
      symbol: 'GRD',
    },
    GTQ: {
      code: 'GTQ',
      name: 'Guatemalan quetzal',
      currencySymbol: 'Q',
      symbol: 'Q',
    },
    GWE: {
      code: 'GWE',
      name: 'Portuguese Guinea escudo',
      currencySymbol: 'GWE',
      symbol: 'GWE',
    },
    GWP: {
      code: 'GWP',
      name: 'Guinea-Bissau peso',
      currencySymbol: 'GWP',
      symbol: 'GWP',
    },
    GYD: {
      code: 'GYD',
      name: 'Guyanaese dollar',
      currencySymbol: '$',
      symbol: '$',
    },
    HKD: {
      code: 'HKD',
      name: 'Hong Kong dollar',
      currencySymbol: 'HK$',
      symbol: '$',
    },
    HNL: {
      code: 'HNL',
      name: 'Honduran lempira',
      currencySymbol: 'L',
      symbol: 'L',
    },
    HRD: {
      code: 'HRD',
      name: 'Croatian dinar',
      currencySymbol: 'HRD',
      symbol: 'HRD',
    },
    HRK: {
      code: 'HRK',
      name: 'Croatian kuna',
      currencySymbol: 'kn',
      symbol: 'kn',
    },
    HTG: {
      code: 'HTG',
      name: 'Haitian gourde',
      currencySymbol: 'HTG',
      symbol: 'HTG',
    },
    HUF: {
      code: 'HUF',
      name: 'Hungarian forint',
      currencySymbol: 'Ft',
      symbol: 'Ft',
    },
    IDR: {
      code: 'IDR',
      name: 'Indonesian Rupiah',
      currencySymbol: 'Rp',
      symbol: 'Rp',
    },
    IEP: {
      code: 'IEP',
      name: 'Irish pound',
      currencySymbol: 'IEP',
      symbol: 'IEP',
    },
    ILP: {
      code: 'ILP',
      name: 'Israeli Pound',
      currencySymbol: 'ILP',
      symbol: 'ILP',
    },
    ILR: {
      code: 'ILR',
      name: 'Israeli Sheqel (1980–1985)',
      currencySymbol: 'ILR',
      symbol: 'ILR',
    },
    ILS: {
      code: 'ILS',
      name: 'Israeli New Sheqel',
      currencySymbol: '₪',
      symbol: '₪',
    },
    INR: {
      code: 'INR',
      name: 'Indian Rupee',
      currencySymbol: '₹',
      symbol: '₨',
    },
    IQD: {
      code: 'IQD',
      name: 'Iraqi dinar',
      currencySymbol: 'IQD',
      symbol: 'IQD',
    },
    IRR: {
      code: 'IRR',
      name: 'Iranian rial',
      currencySymbol: 'IRR',
      symbol: '﷼',
    },
    ISJ: {
      code: 'ISJ',
      name: 'Icelandic króna (1918–1981)',
      currencySymbol: 'ISJ',
      symbol: 'ISJ',
    },
    ISK: {
      code: 'ISK',
      name: 'Icelandic króna',
      currencySymbol: 'kr',
      symbol: 'kr',
    },
    ITL: {
      code: 'ITL',
      name: 'Italian lira',
      currencySymbol: 'ITL',
      symbol: 'ITL',
    },
    JMD: {
      code: 'JMD',
      name: 'Jamaican dollar',
      currencySymbol: '$',
      symbol: '$',
    },
    JOD: {
      code: 'JOD',
      name: 'Jordanian dinar',
      currencySymbol: 'JOD',
      symbol: 'JOD',
    },
    JPY: { code: 'JPY', name: 'Japanese yen', currencySymbol: '¥', symbol: '¥' },
    KES: {
      code: 'KES',
      name: 'Kenyan shilling',
      currencySymbol: 'KES',
      symbol: 'KES',
    },
    KGS: {
      code: 'KGS',
      name: 'Kyrgystani som',
      currencySymbol: 'KGS',
      symbol: 'лв',
    },
    KHR: {
      code: 'KHR',
      name: 'Cambodian riel',
      currencySymbol: '៛',
      symbol: '៛',
    },
    KMF: {
      code: 'KMF',
      name: 'Comorian franc',
      currencySymbol: 'CF',
      symbol: 'CF',
    },
    KPW: {
      code: 'KPW',
      name: 'North Korean won',
      currencySymbol: '₩',
      symbol: '₩',
    },
    KRH: {
      code: 'KRH',
      name: 'South Korean hwan (1953–1962)',
      currencySymbol: 'KRH',
      symbol: 'KRH',
    },
    KRO: {
      code: 'KRO',
      name: 'South Korean won (1945–1953)',
      currencySymbol: 'KRO',
      symbol: 'KRO',
    },
    KRW: {
      code: 'KRW',
      name: 'South Korean won',
      currencySymbol: '₩',
      symbol: '₩',
    },
    KWD: {
      code: 'KWD',
      name: 'Kuwaiti dinar',
      currencySymbol: 'KWD',
      symbol: 'KWD',
    },
    KYD: {
      code: 'KYD',
      name: 'Cayman Islands dollar',
      currencySymbol: '$',
      symbol: '$',
    },
    KZT: {
      code: 'KZT',
      name: 'Kazakhstani tenge',
      currencySymbol: '₸',
      symbol: 'лв',
    },
    LAK: { code: 'LAK', name: 'Laotian kip', currencySymbol: '₭', symbol: '₭' },
    LBP: {
      code: 'LBP',
      name: 'Lebanese Pound',
      currencySymbol: 'L£',
      symbol: '£',
    },
    LKR: {
      code: 'LKR',
      name: 'Sri Lankan rupee',
      currencySymbol: 'Rs',
      symbol: '₨',
    },
    LRD: {
      code: 'LRD',
      name: 'Liberian dollar',
      currencySymbol: '$',
      symbol: '$',
    },
    LSL: {
      code: 'LSL',
      name: 'Lesotho loti',
      currencySymbol: 'LSL',
      symbol: 'LSL',
    },
    LTL: {
      code: 'LTL',
      name: 'Lithuanian litas',
      currencySymbol: 'Lt',
      symbol: 'Lt',
    },
    LTT: {
      code: 'LTT',
      name: 'Lithuanian talonas',
      currencySymbol: 'LTT',
      symbol: 'LTT',
    },
    LUC: {
      code: 'LUC',
      name: 'Luxembourgian convertible franc',
      currencySymbol: 'LUC',
      symbol: 'LUC',
    },
    LUF: {
      code: 'LUF',
      name: 'Luxembourgian franc',
      currencySymbol: 'LUF',
      symbol: 'LUF',
    },
    LUL: {
      code: 'LUL',
      name: 'Luxembourg financial franc',
      currencySymbol: 'LUL',
      symbol: 'LUL',
    },
    LVL: {
      code: 'LVL',
      name: 'Latvian lats',
      currencySymbol: 'Ls',
      symbol: 'Ls',
    },
    LVR: {
      code: 'LVR',
      name: 'Latvian ruble',
      currencySymbol: 'LVR',
      symbol: 'LVR',
    },
    LYD: {
      code: 'LYD',
      name: 'Libyan dinar',
      currencySymbol: 'LYD',
      symbol: 'LYD',
    },
    MAD: {
      code: 'MAD',
      name: 'Moroccan dirham',
      currencySymbol: 'MAD',
      symbol: 'MAD',
    },
    MAF: {
      code: 'MAF',
      name: 'Moroccan franc',
      currencySymbol: 'MAF',
      symbol: 'MAF',
    },
    MCF: {
      code: 'MCF',
      name: 'Monegasque franc',
      currencySymbol: 'MCF',
      symbol: 'MCF',
    },
    MDC: {
      code: 'MDC',
      name: 'Moldovan cupon',
      currencySymbol: 'MDC',
      symbol: 'MDC',
    },
    MDL: {
      code: 'MDL',
      name: 'Moldovan leu',
      currencySymbol: 'MDL',
      symbol: 'MDL',
    },
    MGA: {
      code: 'MGA',
      name: 'Malagasy Ariary',
      currencySymbol: 'Ar',
      symbol: 'Ar',
    },
    MGF: {
      code: 'MGF',
      name: 'Malagasy franc',
      currencySymbol: 'MGF',
      symbol: 'MGF',
    },
    MKD: {
      code: 'MKD',
      name: 'Macedonian denar',
      currencySymbol: 'MKD',
      symbol: 'MKD',
    },
    MKN: {
      code: 'MKN',
      name: 'Macedonian denar (1992–1993)',
      currencySymbol: 'MKN',
      symbol: 'MKN',
    },
    MLF: {
      code: 'MLF',
      name: 'Malian franc',
      currencySymbol: 'MLF',
      symbol: 'MLF',
    },
    MMK: { code: 'MMK', name: 'Myanmar kyat', currencySymbol: 'K', symbol: 'K' },
    MNT: {
      code: 'MNT',
      name: 'Mongolian tugrik',
      currencySymbol: '₮',
      symbol: '₮',
    },
    MOP: {
      code: 'MOP',
      name: 'Macanese pataca',
      currencySymbol: 'MOP',
      symbol: 'MOP',
    },
    MRO: {
      code: 'MRO',
      name: 'Mauritanian ouguiya',
      currencySymbol: 'MRO',
      symbol: 'MRO',
    },
    MTL: {
      code: 'MTL',
      name: 'Maltese lira',
      currencySymbol: 'MTL',
      symbol: 'MTL',
    },
    MTP: {
      code: 'MTP',
      name: 'Maltese pound',
      currencySymbol: 'MTP',
      symbol: 'MTP',
    },
    MUR: {
      code: 'MUR',
      name: 'Mauritian rupee',
      currencySymbol: 'Rs',
      symbol: '₨',
    },
    MVP: {
      code: 'MVP',
      name: 'Maldivian rupee (1947–1981)',
      currencySymbol: 'MVP',
      symbol: 'MVP',
    },
    MVR: {
      code: 'MVR',
      name: 'Maldivian rufiyaa',
      currencySymbol: 'MVR',
      symbol: 'MVR',
    },
    MWK: {
      code: 'MWK',
      name: 'Malawian Kwacha',
      currencySymbol: 'MWK',
      symbol: 'MWK',
    },
    MXN: {
      code: 'MXN',
      name: 'Mexican peso',
      currencySymbol: 'MX$',
      symbol: '$',
    },
    MXP: {
      code: 'MXP',
      name: 'Mexican silver peso (1861–1992)',
      currencySymbol: 'MXP',
      symbol: 'MXP',
    },
    MXV: {
      code: 'MXV',
      name: 'Mexican investment unit',
      currencySymbol: 'MXV',
      symbol: 'MXV',
    },
    MYR: {
      code: 'MYR',
      name: 'Malaysian ringgit',
      currencySymbol: 'RM',
      symbol: 'RM',
    },
    MZE: {
      code: 'MZE',
      name: 'Mozambican escudo',
      currencySymbol: 'MZE',
      symbol: 'MZE',
    },
    MZM: {
      code: 'MZM',
      name: 'Mozambican metical (1980–2006)',
      currencySymbol: 'MZM',
      symbol: 'MZM',
    },
    MZN: {
      code: 'MZN',
      name: 'Mozambican metical',
      currencySymbol: 'MZN',
      symbol: 'MT',
    },
    NAD: {
      code: 'NAD',
      name: 'Namibian dollar',
      currencySymbol: '$',
      symbol: '$',
    },
    NGN: {
      code: 'NGN',
      name: 'Nigerian naira',
      currencySymbol: '₦',
      symbol: '₦',
    },
    NIC: {
      code: 'NIC',
      name: 'Nicaraguan córdoba (1988–1991)',
      currencySymbol: 'NIC',
      symbol: 'NIC',
    },
    NIO: {
      code: 'NIO',
      name: 'Nicaraguan córdoba',
      currencySymbol: 'C$',
      symbol: 'C$',
    },
    NLG: {
      code: 'NLG',
      name: 'Dutch guilder',
      currencySymbol: 'NLG',
      symbol: 'NLG',
    },
    NOK: {
      code: 'NOK',
      name: 'Norwegian krone',
      currencySymbol: 'kr',
      symbol: 'kr',
    },
    NPR: {
      code: 'NPR',
      name: 'Nepalese rupee',
      currencySymbol: 'Rs',
      symbol: '₨',
    },
    NZD: {
      code: 'NZD',
      name: 'New Zealand dollar',
      currencySymbol: 'NZ$',
      symbol: '$',
    },
    OMR: {
      code: 'OMR',
      name: 'Omani rial',
      currencySymbol: 'OMR',
      symbol: '﷼',
    },
    PAB: {
      code: 'PAB',
      name: 'Panamanian balboa',
      currencySymbol: 'PAB',
      symbol: 'B/.',
    },
    PEI: {
      code: 'PEI',
      name: 'Peruvian inti',
      currencySymbol: 'PEI',
      symbol: 'PEI',
    },
    PEN: {
      code: 'PEN',
      name: 'Peruvian nuevo sol',
      currencySymbol: 'PEN',
      symbol: 'S/.',
    },
    PES: {
      code: 'PES',
      name: 'Peruvian sol (1863–1965)',
      currencySymbol: 'PES',
      symbol: 'PES',
    },
    PGK: {
      code: 'PGK',
      name: 'Papua New Guinean kina',
      currencySymbol: 'PGK',
      symbol: 'PGK',
    },
    PHP: {
      code: 'PHP',
      name: 'Philippine peso',
      currencySymbol: '₱',
      symbol: 'Php',
    },
    PKR: {
      code: 'PKR',
      name: 'Pakistani rupee',
      currencySymbol: 'Rs',
      symbol: '₨',
    },
    PLN: {
      code: 'PLN',
      name: 'Polish zloty',
      currencySymbol: 'zł',
      symbol: 'zł',
    },
    PLZ: {
      code: 'PLZ',
      name: 'Polish zloty (PLZ)',
      currencySymbol: 'PLZ',
      symbol: 'PLZ',
    },
    PTE: {
      code: 'PTE',
      name: 'Portuguese escudo',
      currencySymbol: 'PTE',
      symbol: 'PTE',
    },
    PYG: {
      code: 'PYG',
      name: 'Paraguayan guarani',
      currencySymbol: '₲',
      symbol: 'Gs',
    },
    QAR: {
      code: 'QAR',
      name: 'Qatari rial',
      currencySymbol: 'QAR',
      symbol: '﷼',
    },
    RHD: {
      code: 'RHD',
      name: 'Rhodesian dollar',
      currencySymbol: 'RHD',
      symbol: 'RHD',
    },
    ROL: {
      code: 'ROL',
      name: 'Romanian leu (1952–2006)',
      currencySymbol: 'ROL',
      symbol: 'ROL',
    },
    RON: {
      code: 'RON',
      name: 'Romanian leu',
      currencySymbol: 'RON',
      symbol: 'lei',
    },
    RSD: {
      code: 'RSD',
      name: 'Serbian dinar',
      currencySymbol: 'RSD',
      symbol: 'Дин.',
    },
    RUB: {
      code: 'RUB',
      name: 'Russian ruble',
      currencySymbol: '₽',
      symbol: 'руб',
    },
    RUR: {
      code: 'RUR',
      name: 'Russian ruble (1991–1998)',
      currencySymbol: 'р.',
      symbol: 'р.',
    },
    RWF: {
      code: 'RWF',
      name: 'Rwandan franc',
      currencySymbol: 'RF',
      symbol: 'RF',
    },
    SAR: {
      code: 'SAR',
      name: 'Saudi riyal',
      currencySymbol: 'SAR',
      symbol: '﷼',
    },
    SBD: {
      code: 'SBD',
      name: 'Solomon Islands dollar',
      currencySymbol: '$',
      symbol: '$',
    },
    SCR: {
      code: 'SCR',
      name: 'Seychellois rupee',
      currencySymbol: 'SCR',
      symbol: '₨',
    },
    SDD: {
      code: 'SDD',
      name: 'Sudanese dinar (1992–2007)',
      currencySymbol: 'SDD',
      symbol: 'SDD',
    },
    SDG: {
      code: 'SDG',
      name: 'Sudanese pound',
      currencySymbol: 'SDG',
      symbol: 'SDG',
    },
    SDP: {
      code: 'SDP',
      name: 'Sudanese pound (1957–1998)',
      currencySymbol: 'SDP',
      symbol: 'SDP',
    },
    SEK: {
      code: 'SEK',
      name: 'Swedish krona',
      currencySymbol: 'kr',
      symbol: 'kr',
    },
    SGD: {
      code: 'SGD',
      name: 'Singapore dollar',
      currencySymbol: '$',
      symbol: '$',
    },
    SHP: {
      code: 'SHP',
      name: 'St. Helena pound',
      currencySymbol: '£',
      symbol: '£',
    },
    SIT: {
      code: 'SIT',
      name: 'Slovenian tolar',
      currencySymbol: 'SIT',
      symbol: 'SIT',
    },
    SKK: {
      code: 'SKK',
      name: 'Slovak koruna',
      currencySymbol: 'SKK',
      symbol: 'SKK',
    },
    SLL: {
      code: 'SLL',
      name: 'Sierra Leonean leone',
      currencySymbol: 'SLL',
      symbol: 'SLL',
    },
    SOS: {
      code: 'SOS',
      name: 'Somali shilling',
      currencySymbol: 'SOS',
      symbol: 'S',
    },
    SRD: {
      code: 'SRD',
      name: 'Surinamese dollar',
      currencySymbol: '$',
      symbol: '$',
    },
    SRG: {
      code: 'SRG',
      name: 'Surinamese guilder',
      currencySymbol: 'SRG',
      symbol: 'SRG',
    },
    SSP: {
      code: 'SSP',
      name: 'South Sudanese pound',
      currencySymbol: '£',
      symbol: '£',
    },
    STD: {
      code: 'STD',
      name: 'São Tomé \u0026 Príncipe dobra',
      currencySymbol: 'Db',
      symbol: 'Db',
    },
    SUR: {
      code: 'SUR',
      name: 'Soviet rouble',
      currencySymbol: 'SUR',
      symbol: 'SUR',
    },
    SVC: {
      code: 'SVC',
      name: 'Salvadoran colón',
      currencySymbol: 'SVC',
      symbol: 'SVC',
    },
    SYP: { code: 'SYP', name: 'Syrian pound', currencySymbol: '£', symbol: '£' },
    SZL: {
      code: 'SZL',
      name: 'Swazi lilangeni',
      currencySymbol: 'SZL',
      symbol: 'SZL',
    },
    THB: { code: 'THB', name: 'Thai baht', currencySymbol: '฿', symbol: '฿' },
    TJR: {
      code: 'TJR',
      name: 'Tajikistani ruble',
      currencySymbol: 'TJR',
      symbol: 'TJR',
    },
    TJS: {
      code: 'TJS',
      name: 'Tajikistani somoni',
      currencySymbol: 'TJS',
      symbol: 'TJS',
    },
    TMM: {
      code: 'TMM',
      name: 'Turkmenistani manat (1993–2009)',
      currencySymbol: 'TMM',
      symbol: 'TMM',
    },
    TMT: {
      code: 'TMT',
      name: 'Turkmenistani manat',
      currencySymbol: 'TMT',
      symbol: 'TMT',
    },
    TND: {
      code: 'TND',
      name: 'Tunisian dinar',
      currencySymbol: 'TND',
      symbol: 'TND',
    },
    TOP: {
      code: 'TOP',
      name: 'Tongan paʻanga',
      currencySymbol: 'T$',
      symbol: 'T$',
    },
    TPE: {
      code: 'TPE',
      name: 'Timorese escudo',
      currencySymbol: 'TPE',
      symbol: 'TPE',
    },
    TRL: {
      code: 'TRL',
      name: 'Turkish lira (1922–2005)',
      currencySymbol: 'TRL',
      symbol: 'TRL',
    },
    TRY: {
      code: 'TRY',
      name: 'Turkish lira',
      currencySymbol: '₺',
      symbol: 'TL',
    },
    TTD: {
      code: 'TTD',
      name: 'Trinidad \u0026 Tobago dollar',
      currencySymbol: '$',
      symbol: '$',
    },
    TWD: {
      code: 'TWD',
      name: 'New Taiwan dollar',
      currencySymbol: 'NT$',
      symbol: 'NT$',
    },
    TZS: {
      code: 'TZS',
      name: 'Tanzanian shilling',
      currencySymbol: 'TZS',
      symbol: 'TZS',
    },
    UAH: {
      code: 'UAH',
      name: 'Ukrainian hryvnia',
      currencySymbol: '₴',
      symbol: '₴',
    },
    UAK: {
      code: 'UAK',
      name: 'Ukrainian karbovanets',
      currencySymbol: 'UAK',
      symbol: 'UAK',
    },
    UGS: {
      code: 'UGS',
      name: 'Ugandan shilling (1966–1987)',
      currencySymbol: 'UGS',
      symbol: 'UGS',
    },
    UGX: {
      code: 'UGX',
      name: 'Ugandan shilling',
      currencySymbol: 'UGX',
      symbol: 'UGX',
    },
    USD: { code: 'USD', name: 'US Dollar', currencySymbol: '$', symbol: '$' },
    USN: {
      code: 'USN',
      name: 'US dollar (next day)',
      currencySymbol: 'USN',
      symbol: 'USN',
    },
    USS: {
      code: 'USS',
      name: 'US dollar (same day)',
      currencySymbol: 'USS',
      symbol: 'USS',
    },
    UYI: {
      code: 'UYI',
      name: 'Uruguayan peso (indexed units)',
      currencySymbol: 'UYI',
      symbol: 'UYI',
    },
    UYP: {
      code: 'UYP',
      name: 'Uruguayan peso (1975–1993)',
      currencySymbol: 'UYP',
      symbol: 'UYP',
    },
    UYU: {
      code: 'UYU',
      name: 'Uruguayan peso',
      currencySymbol: '$',
      symbol: '$U',
    },
    UZS: {
      code: 'UZS',
      name: 'Uzbekistan som',
      currencySymbol: 'UZS',
      symbol: 'лв',
    },
    VEB: {
      code: 'VEB',
      name: 'Venezuelan bolívar (1871–2008)',
      currencySymbol: 'VEB',
      symbol: 'VEB',
    },
    VEF: {
      code: 'VEF',
      name: 'Venezuelan bolívar',
      currencySymbol: 'Bs',
      symbol: 'Bs',
    },
    VND: {
      code: 'VND',
      name: 'Vietnamese dong',
      currencySymbol: '₫',
      symbol: '₫',
    },
    VNN: {
      code: 'VNN',
      name: 'Vietnamese dong (1978–1985)',
      currencySymbol: 'VNN',
      symbol: 'VNN',
    },
    VUV: {
      code: 'VUV',
      name: 'Vanuatu vatu',
      currencySymbol: 'VUV',
      symbol: 'VUV',
    },
    WST: {
      code: 'WST',
      name: 'Samoan tala',
      currencySymbol: 'WST',
      symbol: 'WST',
    },
    XAF: {
      code: 'XAF',
      name: 'CFA franc BEAC',
      currencySymbol: 'FCFA',
      symbol: 'FCFA',
    },
    XAG: {
      code: 'XAG',
      name: 'troy ounce of silver',
      currencySymbol: 'XAG',
      symbol: 'XAG',
    },
    XAU: {
      code: 'XAU',
      name: 'troy ounce of gold',
      currencySymbol: 'XAU',
      symbol: 'XAU',
    },
    XBA: {
      code: 'XBA',
      name: 'European composite unit',
      currencySymbol: 'XBA',
      symbol: 'XBA',
    },
    XBB: {
      code: 'XBB',
      name: 'European monetary unit',
      currencySymbol: 'XBB',
      symbol: 'XBB',
    },
    XBC: {
      code: 'XBC',
      name: 'European unit of account (XBC)',
      currencySymbol: 'XBC',
      symbol: 'XBC',
    },
    XBD: {
      code: 'XBD',
      name: 'European unit of account (XBD)',
      currencySymbol: 'XBD',
      symbol: 'XBD',
    },
    XCD: {
      code: 'XCD',
      name: 'East Caribbean dollar',
      currencySymbol: 'EC$',
      symbol: '$',
    },
    XDR: {
      code: 'XDR',
      name: 'special drawing rights',
      currencySymbol: 'XDR',
      symbol: 'XDR',
    },
    XEU: {
      code: 'XEU',
      name: 'European code unit',
      currencySymbol: 'XEU',
      symbol: 'XEU',
    },
    XFO: {
      code: 'XFO',
      name: 'French gold franc',
      currencySymbol: 'XFO',
      symbol: 'XFO',
    },
    XFU: {
      code: 'XFU',
      name: 'French UIC-franc',
      currencySymbol: 'XFU',
      symbol: 'XFU',
    },
    XOF: {
      code: 'XOF',
      name: 'CFA franc BCEAO',
      currencySymbol: 'CFA',
      symbol: 'CFA',
    },
    XPD: {
      code: 'XPD',
      name: 'troy ounce of palladium',
      currencySymbol: 'XPD',
      symbol: 'XPD',
    },
    XPF: {
      code: 'XPF',
      name: 'CFP franc',
      currencySymbol: 'CFPF',
      symbol: 'CFPF',
    },
    XPT: {
      code: 'XPT',
      name: 'troy ounce of platinum',
      currencySymbol: 'XPT',
      symbol: 'XPT',
    },
    XRE: {
      code: 'XRE',
      name: 'RINET Funds Unit',
      currencySymbol: 'XRE',
      symbol: 'XRE',
    },
    XSU: {
      code: 'XSU',
      name: 'Sucre',
      currencySymbol: 'XSU',
      symbol: 'XSU',
    },
    XTS: {
      code: 'XTS',
      name: 'Testing Currency Unit',
      currencySymbol: 'XTS',
      symbol: 'XTS',
    },
    XUA: {
      code: 'XUA',
      name: 'ADB Unit Of Account',
      currencySymbol: 'XUA',
      symbol: 'XUA',
    },
    XXX: {
      code: 'XXX',
      name: '(unknown unit of code)',
      currencySymbol: 'XXX',
      symbol: 'XXX',
    },
    YDD: {
      code: 'YDD',
      name: 'Yemeni Dinar',
      currencySymbol: 'YDD',
      symbol: 'YDD',
    },
    YER: {
      code: 'YER',
      name: 'Yemeni Rial',
      currencySymbol: 'YER',
      symbol: '﷼',
    },
    YUD: {
      code: 'YUD',
      name: 'Yugoslavian Hard Dinar (1966–1990)',
      currencySymbol: 'YUD',
      symbol: 'YUD',
    },
    YUM: {
      code: 'YUM',
      name: 'Yugoslavian New Dinar (1994–2002)',
      currencySymbol: 'YUM',
      symbol: 'YUM',
    },
    YUN: {
      code: 'YUN',
      name: 'Yugoslavian Convertible Dinar (1990–1992)',
      currencySymbol: 'YUN',
      symbol: 'YUN',
    },
    YUR: {
      code: 'YUR',
      name: 'Yugoslavian Reformed Dinar (1992–1993)',
      currencySymbol: 'YUR',
      symbol: 'YUR',
    },
    ZAL: {
      code: 'ZAL',
      name: 'South African Rand (financial)',
      currencySymbol: 'ZAL',
      symbol: 'ZAL',
    },
    ZAR: {
      code: 'ZAR',
      name: 'South African Rand',
      currencySymbol: 'R',
      symbol: 'R',
    },
    ZMK: {
      code: 'ZMK',
      name: 'Zambian Kwacha (1968–2012)',
      currencySymbol: 'ZMK',
      symbol: 'ZMK',
    },
    ZMW: {
      code: 'ZMW',
      name: 'Zambian Kwacha',
      currencySymbol: 'ZK',
      symbol: 'ZK',
    },
    ZRN: {
      code: 'ZRN',
      name: 'Zairean New Zaire (1993–1998)',
      currencySymbol: 'ZRN',
      symbol: 'ZRN',
    },
    ZRZ: {
      code: 'ZRZ',
      name: 'Zairean Zaire (1971–1993)',
      currencySymbol: 'ZRZ',
      symbol: 'ZRZ',
    },
    ZWD: {
      code: 'ZWD',
      name: 'Zimbabwean Dollar (1980–2008)',
      currencySymbol: 'ZWD',
      symbol: 'Z$',
    },
    ZWL: {
      code: 'ZWL',
      name: 'Zimbabwean Dollar (2009)',
      currencySymbol: 'ZWL',
      symbol: 'ZWL',
    },
    ZWR: {
      code: 'ZWR',
      name: 'Zimbabwean Dollar (2008)',
      currencySymbol: 'ZWR',
      symbol: 'ZWR',
    },
  };