import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import "./setting.css"

import Tab from './../../components/Tab';

import Employees from "./Employees"
import RoleManagement from './RoleManagement'
import Branches from './Branches'
import Accounts from './Accounts'

const role_tab = "Role Management"
const employees_tab = "Users"
const branch_tab = "Branches"
const charts_of_account = "Charts of Account"

function Settings() {
    const role = useSelector((state) => state.auth.role)

    const allTab = {
        role:{name: role_tab, active: true}, employees:{name: employees_tab, active: false}, branch:{name: branch_tab, active: false}, 
    }
    const [tabs, setTabs] = useState([])
    const [activeTab, setActiveTab] = useState("")

    useEffect(() => {
        let x= []
        if(role.can_manage_role){
            x.push(allTab.role)
        } if(role.can_manage_branch){
            x.push(allTab.branch)
        } if(role.can_manage_user || role.can_invite_user)
            x.push(allTab.employees)
        setTabs(x)
        x.length && setActiveTab(x[0].name)
    }, [])

    const tabChangeHandler = (tab) => {
        const flag = tabs.map(t => t.name === tab.name ? {...t, active: true} : {...t, active: false})
        setTabs(flag)
        setActiveTab(tab.name)
    }

    return (
        <div>
            <div className="layout-title">
                Settings
            </div>
            <Tab tabList={tabs} onClickHandler={tabChangeHandler}/>
            {activeTab === role_tab && <RoleManagement role={role}/>}
            {activeTab === employees_tab && <Employees role={role}/>}
            {activeTab === branch_tab && <Branches role={role}/>}
            {/* {activeTab === charts_of_account && <Accounts role={role}/>} */}
        </div>
    )
}

export default Settings