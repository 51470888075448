import React, { useState, useEffect } from 'react'
import './index.css'

import { currencies } from '../../utility/index'
import { getEmployeesContructor, createEmployee, deleteEmployee, retrieveEmployee, updateEmployee } from '../../actions/employee'
import { useDispatch, useSelector } from "react-redux"


import Dropdown from '../../components/Dropdown'
import TextInput from '../../components/TextInput'
import ButtonOulined from '../../components/Button/ButtonOulined'
import TextArea from '../../components/TextArea'
import Modal from '../../components/Modal'
import FilledRoundedButton from "../../components/Button/FilledRoundedButton"
import SimpleTable from "../../components/Table/SimpleTable"
import MuiAlert from '@mui/material/Alert';
import { FiSearch } from 'react-icons/fi'


const tableHeader = [
  { name: "Action", size: "small" },
  { name: "Employee ID", size: "small" },
  { name: "Name", size: "large" },
  { name: "Email", size: "large" },
  { name: "Country", size: "large" },
  { name: "State", size: "large" },
]

const staffType = [
  { name: "1099 Contractor", value: "contractor" },
  { name: "Consultant and Non 1099 Contractor", value: "consultant" },
  { name: "Employee", value: "employee" }
];

function Employee() {
  const dispatch = useDispatch()
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [value, setValue] = useState([])
  const [newEmployeeData, setNewEmployeeData] = useState({ staff: "consultant" })
  const [isUpdate, setIsUpdate] = useState(false)
  const [currency, setCurrency] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [searchKey, setSearchKey] = useState('')
  const searchIcon = () => (
    <FiSearch style={{ position: "absolute", top: "1rem", right: "1rem", zIndex: "10" }} size="20" />
  )

  useEffect(() => {
    let x = []
    for (const cur in currencies) {
      x.push({ name: cur, value: cur })
    }
    setCurrency(x)
  }, [])

  useEffect(() => {
    let query_params = {}
    query_params['page'] = page
    if (searchKey) {
      query_params['search'] = searchKey
    }
    dispatch(getEmployeesContructor(query_params))
      .then((response) => {
        if (response.status === 200) {
          setValue(response.data)
          setTotalPage(response.data.total)
        }
      })
  }, [page])

  useEffect(() => {
    dispatch(getEmployeesContructor({ page: page }))
      .then(response => {
        if (response.status === 200)
          setValue(response.data)
        setTotalPage(response.totalPage)
      })
  }, [page])

  const handlePage = (event, value) => {
    setPage(value)
  }
  const onEnterPress = (event) => {
    if (event.key === 'Enter') {
      let query_params = {}
      query_params['page'] = page
      if (searchKey) {
        query_params['search'] = searchKey
      }
      console.log(query_params);
      dispatch(getEmployeesContructor(query_params))
        .then((response) => {
          if (response.status === 200) {
            setValue(response.data)
            setTotalPage(response.data.total)
          }
        })
    }
  }

  const createNewEmployeeBody = () => (
    <>
      <div className="add-expense-body">
        <TextInput id='employee_id' name='employee_id' placeholder="Employee ID" type="text"
          onChangeHandler={handleNewEmployeeData} value={newEmployeeData.employee_id} />
        <div className="add-expense-row">
          <Dropdown id="staff" name="staff" placeholder="Staff Type" onChangeHandler={handleNewEmployeeData}
            size="medium" options={staffType} defaultValue={newEmployeeData.staff} />
          {(newEmployeeData.staff === "contractor" || newEmployeeData.staff === "employee") && <Dropdown id="employee_type" name="employee_type" placeholder="Employee Type" size="medium"
            options={[{ name: "Admin", value: "admin" }, { name: "Instructor", value: "instructor" }, { name: "Admin & Instructor", value: "both" }]}
            defaultValue={newEmployeeData.employee_type || "DEFAULT"} onChangeHandler={handleNewEmployeeData} />}
        </div>
        <div className="add-expense-row">
          <TextInput id="first_name" name="first_name" placeholder="First Name" type="text"
            onChangeHandler={handleNewEmployeeData} value={newEmployeeData.first_name} />
          <TextInput id="last_name" name="last_name" placeholder="Last Name" type="text"
            onChangeHandler={handleNewEmployeeData} value={newEmployeeData.last_name} />
        </div>
        <div className="add-expense-row">
          <TextInput id="email" name="email" placeholder="Email" type="email"
            onChangeHandler={handleNewEmployeeData} value={newEmployeeData.email} />
          <TextInput id="phone" name="phone" placeholder="Phone Number" type="text"
            onChangeHandler={handleNewEmployeeData} value={newEmployeeData.phone} />
        </div>
        {(newEmployeeData.staff === "contractor" || newEmployeeData.staff === "employee") && <div className="add-expense-row">
          <Dropdown id="currency" name="currency" options={currency} placeholder="Currency"
            onChangeHandler={handleNewEmployeeData} size="medium" defaultValue={newEmployeeData.currency || "DEFAULT"} />

          <TextInput id="date_of_birth" type="date" name="date_of_birth" placeholder="Date of Birth"
            onChangeHandler={handleNewEmployeeData} size="medium" value={newEmployeeData.date_of_birth} />

        </div>}
        {/* <div className="add-expense-row">
              <Dropdown id="vendor" name="vendor" placeholder="Vendors" onChangeHandler={expenseDataHandler} size="medium" options={[]}/>
          </div> */}
        {(newEmployeeData.staff === "contractor" || newEmployeeData.staff === "employee") && <div className="add-expense-row">
          <TextInput id="ssn" name="ssn" placeholder="Social Security Number"
            onChangeHandler={handleNewEmployeeData} size="medium" value={newEmployeeData.ssn} />
          <TextInput id="compensation" name="compensation" placeholder="Nonemployee Compensation"
            onChangeHandler={handleNewEmployeeData} size="medium" value={newEmployeeData.compensation} />
        </div>}
        <div className="add-expense-row">
          <TextInput id="city" name="city" placeholder="City" onChangeHandler={handleNewEmployeeData}
            size="small" value={newEmployeeData.city} />
          <TextInput id="state" name="state" placeholder="State" onChangeHandler={handleNewEmployeeData}
            size="small" value={newEmployeeData.state} />
          <TextInput id="country" name="country" placeholder="Country" onChangeHandler={handleNewEmployeeData}
            size="small" value={newEmployeeData.country} />
          <TextInput id="zipcode" name="zipcode" placeholder="Zip Code/Postal Code" onChangeHandler={handleNewEmployeeData}
            size="small" value={newEmployeeData.zipcode} />
        </div>
        <div className="add-expense-row">
          <TextArea id="street" name="street" rows="1" placeholder="Street Address"
            onChangeHandler={handleNewEmployeeData} size="extra-large" value={newEmployeeData.street} />
        </div>
        {(newEmployeeData.staff === "contractor" || newEmployeeData.staff === "employee") && <div className="add-expense-row">
          <TextInput id="date_of_hire" name="date_of_hire" type="date" placeholder="Date of Hire" onChangeHandler={handleNewEmployeeData}
            size="small" value={newEmployeeData.date_of_hire} />
          <TextInput id="work_location" name="work_location" placeholder="Work Location" onChangeHandler={handleNewEmployeeData}
            size="small" value={newEmployeeData.work_location} />
          <Dropdown id="wage_type" name="wage_type" placeholder="Wage Type" onChangeHandler={handleNewEmployeeData}
            size="small" options={[{ name: 'Hour', value: "hour" }, { name: "Annually", value: "annually" }]} defaultValue={newEmployeeData.wage_type || "DEFAULT"} />
          <TextInput id="amount" name="amount" placeholder="Wage Amount" type="text" onChangeHandler={handleNewEmployeeData}
            size="small" value={newEmployeeData.amount} />
        </div>}


        <div className="add-expense-row center-item">
          <ButtonOulined name="Cancel" onClickHandler={closeModalBody} />
          <FilledRoundedButton name={isUpdate ? "Update" : "Save"} color="green" size="small" onClickHandler={isUpdate ? handleUpdateEmployee : handleSaveEmployee} />
        </div>

      </div>
    </>
  )

  const makeModalClose = () => { }

  const handleNewEmployeeData = (event) => {
    setNewEmployeeData(prevState => ({ ...prevState, [event.target.name]: event.target.value }))
  }

  const handleDeleteEmployee = (emp) => {
    dispatch(deleteEmployee(emp.id))
      .then((response) => {
        if (response.status === 204)
          setValue(prevState => prevState.filter(e => e.id !== emp.id))
      })
  }
  const handleEditEmployee = (emp) => {
    dispatch(retrieveEmployee(emp.id))
      .then(response => {
        if (response.status === 200) {
          setNewEmployeeData(response.data)
          setOpenCreateModal(true)
          setIsUpdate(emp.id)
        }
      })
  }

  const handleUpdateEmployee = () => {
    dispatch(updateEmployee(newEmployeeData, isUpdate))
  }

  const handleSaveEmployee = () => {
    dispatch(createEmployee(newEmployeeData))
      .then(response => {
        if (response.status === 201) {
          setValue([response.data, ...value])
          setOpenCreateModal(false)
          window.location.reload();
          setNewEmployeeData({})
        }
      })
  }



  const tableBody = (exp) => (

    <>
      {tableHeader.map((col, key, array) => 0 !== key &&
        <td key={key}>
          {exp[col.name.replace(" ", "_").toLowerCase()] ? exp[col.name.replace(" ", "_").toLowerCase()] : 'Not Given'}
        </td>)}
    </>
  )

  const closeModalBody = () => {
    setOpenCreateModal(false)
    setIsUpdate(false)
    setNewEmployeeData({})
  }

  return (
    <div>
      <div className="layout-title">
        <p>Employees</p>
        <div className="align-right">
          <FilledRoundedButton onClickHandler={() => setOpenCreateModal(true)} name="Add Employee" color="green" size="small" />
        </div>
      </div>

      <div>
        <Modal header="Create New Employee" body={createNewEmployeeBody} isOpen={openCreateModal} modalHandler={closeModalBody} />
        <TextInput id="search" name="search" placeholder="Search" value={searchKey} onKeyPress={onEnterPress}
          icon={searchIcon} onChangeHandler={(event) => setSearchKey(event.target.value)} />
        <SimpleTable header={tableHeader} values={value} onDelete={handleDeleteEmployee}
          onEdit={handleEditEmployee} tableBody={tableBody}
          page={page} handlePage={handlePage} totalPage={totalPage} />
      </div>
    </div>
  )
}

export default Employee