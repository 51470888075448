import React, { useState } from 'react'
import styled from 'styled-components'
import FilledSquareButton from '../components/Button/FilledSquareButton'
import Header from '../components/Header'
import TextInput from '../components/TextInput'
import COLORS from '../utility/styles'
import api from '../services/api'
import { useSelector } from 'react-redux'
import { ADD_MESSAGE } from './../actions/types'
import axios from 'axios'
import { sendEmailClick } from '../actions/auths'
import { useDispatch } from 'react-redux'

const Container = styled.div`
    height:100%;
    width:100%;
    position:absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${COLORS.secondaryColor}
`

const Body = styled.div`
    background-color: white;
    width:55%;
    height:70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`

const ResetPasswordRequestEmail = () => {
    const [email, setEmail] = useState('');

    const dispatch = useDispatch();
    
    const emailChangeHandler = (event) => {
        setEmail(event.target.value)
    }

    const onClick = (event) => {
        event.preventDefault();
        dispatch(sendEmailClick(email))
    }

    return (
        <Container>
            <Body>
                <Header text='Request Reset Password'></Header>
                <TextInput id='sendEmail' name='sendEmail' placeholder='Email' value={email} onChangeHandler={emailChangeHandler} type='email'></TextInput>
                <FilledSquareButton onClickHandler={onClick} name='Send'></FilledSquareButton>
            </Body>
        </Container>
    )
}

export default ResetPasswordRequestEmail