import {ADD_BRANCH} from '../actions/types';

const initialState = {
    branches: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ADD_BRANCH:
            return {
                ...state,
                branches: payload.branches
            }
        default:
            return state;
    }
}