import React from 'react'
import "./textarea.css"

function TextArea({id, name, placeholder, onChangeHandler, errorMessage, size="medium", type="text",
error=false, value="", readOnly=false, rows=5 }) {
    return (
        <div style={{display: 'flex', flexDirection:"column", position: "relative"}}>
            <textarea 
                type={type} 
                className={`text-input ${size}-inp ${error ? "error" : ""}`} 
                id={id} 
                name={name} 
                value={value}
                readOnly={readOnly}
                placeholder={placeholder}
                rows={rows}
                onChange={onChangeHandler}/>
            <label className="label" htmlFor={id}>{placeholder}</label>
            {error && <span className="error-message">{errorMessage}</span>}
        </div>
    )
}

export default TextArea