import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Navbar from './layouts/Navbar/Navbar';
import Sidebar from "./layouts/Sidebar";
import Loading from "./components/Loading";

function WithNav() {
    const {isAuthenticated, isLoading} = useSelector((state) => state.auth)

    return isLoading ? <Loading/> : isAuthenticated ? (
    <React.Fragment>
        {/* <Navbar /> */}
        <div style={{display:"flex"}}>
        <Sidebar />
        <div style={{marginTop:"3rem", width:"100%"}}>
            <Outlet/>
            </div>
        </div>
    </React.Fragment>
    ) : <Navigate to='/login' />
}

export default WithNav