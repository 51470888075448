import React, {useState} from 'react'
import moment from 'moment'
import TextInput from '../../../components/TextInput'
import Dropdown from '../../../components/Dropdown'
import { FiCheck } from 'react-icons/fi'


import RecordPayment from './RecordPayment'
import AddDueDate from './AddDueDate'

function TrainingPaymentDetails({handleIncomeData, recordPayment, incomeInformation, handleInstallmentData, installmentInformation, type, error}) {
    const [isUpdate, setIsUpdate] = useState(type==='update');
    const [dueDateInformation, setDueDateInformation] = useState({})

    const handleInstallmentInformation = (event) => {
        setDueDateInformation(prevState => ({...prevState, [event.target.name]: event.target.value}))
    }

    const saveDueDate = (info) => {
        // if(type === "record") dueDateInformation['paid_date']=new Date().toISOString().split('T')[0]
        handleInstallmentData(info)
        setDueDateInformation({})
    }

    const getInstallmentValue = (installment) => {
        let value = "";
        if(installment?.target_amount)
            value += `Due: ${installment.target_amount}$ `
        if(installment?.paid_amount)
            value += `Paid ${installment.paid_amount}$`
        return value
    }

    const paidIcon = (installment) => (
        (installment?.paid_amount !== undefined && installment?.paid_amount !== null) && <FiCheck style={{position:"absolute", top:"1rem", right:"10rem", zIndex: "10"}} size="20" color="green"/> 
    )

    return (
        <div className="information-block">
            <p className="information-block-header">Payment Details</p>
            <div className="information-row">
                {/* { isUpdate ?
                    <RecordPayment recordInstallment={saveDueDate} installmentupdate={() => setIsUpdate(false)}/> :
                    <AddDueDate recordInstallment={saveDueDate} installmentupdate={() => setIsUpdate(true)} type={type}/>
                    
                } */}
                <div>
                <TextInput id="first_installment" name="first_installment" placeholder="First Installment" value={incomeInformation.first_installment} type="number" 
                        onChangeHandler={handleIncomeData} size="small" />
                <TextInput id="second_installment" name="second_installment" placeholder="Second Installment" value={incomeInformation.second_installment} type="number" 
                        onChangeHandler={handleIncomeData} size="small" />
                <TextInput id="third_installment" name="third_installment" placeholder="Third Installment" value={incomeInformation.third_installment} type="number" 
                        onChangeHandler={handleIncomeData} size="small" />
                <TextInput id="fourth_installment" name="fourth_installment" placeholder="Fourth Installment" value={incomeInformation.fourth_installment} type="number" 
                        onChangeHandler={handleIncomeData} size="small" />
                </div>
                <div style={{width: "24%"}}>
                    <TextInput id="target_amount" name="target_amount" placeholder="Target Amount" value={incomeInformation.target_amount} type="number" 
                        onChangeHandler={handleIncomeData} size="small" error={error.target_amount || false} errorMessage={error.target_amount && error.target_amount[0]}/>
                </div>
                <div style={{width: "24%"}}>
                    <TextInput id="previous_paid_amount" name="previous_paid_amount" value={incomeInformation.previous_paid_amount} placeholder="Previous Paid" 
                        type="number" onChangeHandler={handleIncomeData} size="small" error={error.previous_paid_amount || false} errorMessage={error.previous_paid_amount && error.previous_paid_amount[0]}/>
                </div>
                <div style={{width: "24%"}}>
                    <TextInput id="paid_amount" name="paid_amount" placeholder="Paid Amount" value={incomeInformation.paid_amount} type="number" 
                    onChangeHandler={handleIncomeData} size="small" error={error.paid_amount || false} errorMessage={error.paid_amount && error.paid_amount[0]}/>
                </div>
                <div style={{width: "24%"}}>
                    <TextInput id="remaining_amount" name="remaining_amount" placeholder="Remaining" type="number" value={incomeInformation.remaining_amount} 
                        onChangeHandler={handleIncomeData} size="small" error={error.remaining_amount || false} errorMessage={error.remaining_amount && error.remaining_amount[0]}/>
                </div>
                <div style={{width: "49%"}}>
                    <TextInput id="enrollment_fee" name="enrollment_fee" placeholder={`Enrollment Fee`} type="text" value={incomeInformation.enrollment_fee} 
                        onChangeHandler={handleIncomeData} size="large" error={error.enrollment_fee || false} errorMessage={error.enrollment_fee && error.enrollment_fee[0]}/>
                </div>
                <div style={{width: "49%"}}>
                    {installmentInformation.first_installment && <TextInput id="first_installment" name="first_installment" readOnly={true}
                        placeholder={`1st Installment Fee (Due: ${installmentInformation.first_installment !== undefined ? moment(installmentInformation.first_installment.due_date).format("MMM Do YYYY") :""}) $`} 
                        value={getInstallmentValue(installmentInformation.first_installment)} type="text" onChangeHandler={handleIncomeData} size="large"
                        icon={() => paidIcon(installmentInformation.first_installment)}  healperText={installmentInformation.first_installment.paid_date && `Paid on - ${moment(installmentInformation.first_installment.paid_date).format("MMM Do YYYY")}`}/>}
                </div>
                <div style={{width: "49%"}}>
                    {installmentInformation.second_installment && <TextInput id="second_installment" name="second_installment" readOnly={true} 
                        placeholder={`2nd Installment Fee (Due: ${installmentInformation.second_installment !== undefined ? moment(installmentInformation.second_installment.due_date).format("MMM Do YYYY") :""}) $`} 
                        value={getInstallmentValue(installmentInformation.second_installment)} type="text" onChangeHandler={handleIncomeData} size="large"
                        icon={() => paidIcon(installmentInformation.second_installment)} healperText={installmentInformation.second_installment.paid_date && `Paid on - ${moment(installmentInformation.second_installment.paid_date).format("MMM Do YYYY")}`} /> }
                </div>
                <div style={{width: "49%"}}>
                {installmentInformation.third_installment && <TextInput id="third_installment" name="third_installment" readOnly={true} 
                        placeholder={`3rd Installment Fee (Due: ${installmentInformation.third_installment !== undefined ? moment(installmentInformation.third_installment.due_date).format("MMM Do YYYY") :""}) $`} 
                        value={getInstallmentValue(installmentInformation.third_installment)} type="text" onChangeHandler={handleIncomeData} size="large"
                        icon={() => paidIcon(installmentInformation.third_installment)} healperText={installmentInformation.third_installment.paid_date && `Paid on - ${moment(installmentInformation.third_installment.paid_date).format("MMM Do YYYY")}`}/>}
                </div>
                <div style={{width: "49%"}}>
                    {installmentInformation.fourth_installment && <TextInput id="fourth_installment" name="fourth_installment" 
                        placeholder={`4th Installment Fee (Due: ${installmentInformation.fourth_installment !== undefined ? moment(installmentInformation.fourth_installment.due_date).format("MMM Do YYYY") :""}) $`} 
                        value={getInstallmentValue(installmentInformation.fourth_installment)} type="text" onChangeHandler={handleIncomeData} size="large"
                        icon={() => paidIcon(installmentInformation.fourth_installment)} healperText={installmentInformation.fourth_installment.paid_date && `Paid on - ${moment(installmentInformation.fourth_installment.paid_date).format("MMM Do YYYY")}`}/>}
                </div>
            </div>
        </div>
    )
}

export default TrainingPaymentDetails