import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import {register} from "./../../actions/auths"
import { useDispatch, useSelector } from 'react-redux'
import "./register.css"
import CircularProgress from '@mui/material/CircularProgress';
import { FiEye } from "react-icons/fi"

import TextInput from "./../../components/TextInput"
import FilledSquareButton from "./../../components/Button/FilledSquareButton";

import logo from "./../../images/logo.png"

function Register() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [userCredintial, setUserCredintial] = useState({
        username: "", first_name: "", last_name: "", email: "", password: "", confirm_password: ""
    })
    const [loading, setLoading] = useState(false)
    const [userCredintialError, setUserCredintialError] = useState({password:false, email: false, username:false})
    const [passwordType, setPasswordType] = useState(true)
    const eyeIcon = () => (
        <FiEye style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer", zIndex: "10"}} size="18" onClick={() => setPasswordType(!passwordType)}/> 
    )
    const onRegister = (event) => {
        setLoading(true)
        setUserCredintialError({})
        event.preventDefault();
        if(userCredintial.password !== userCredintial.confirm_password){
            setUserCredintialError({...userCredintialError, password:true})
        } else {
            dispatch(register({...userCredintial}))
                .then(response => {
                    if(response.status === 201){
                        navigate("/login")
                    } else {
                        let errorMessage = {}
                        if("email" in response.message) errorMessage["email"] = response.message.email
                        else errorMessage["email"] = false
                        if("username" in response.message) errorMessage["username"] = response.message.username
                        else errorMessage["username"] = false
                        if("password" in response.message) errorMessage["password"] = true
                        else errorMessage["password"] = false
                        setUserCredintialError(errorMessage)
                        console.log("Something Wrong happened")
                    }
                    setLoading(false)
                })
            setUserCredintialError({...userCredintialError, password:false})
        }
    }

    const userCredentialsHandler = (event) => {
        setUserCredintial({...userCredintial, [event.target.name]:event.target.value})
    }

    return (
        <div className="login-page">
            <div className="login-body">
                <img src={logo} alt="QATEK Logo" style={{padding: "5px"}}/>
                <p style={{fontSize:'1.25rem', margin:'1rem 0rem'}}>QATEK Expense Management System</p>
                <div>
                    <TextInput id="username" name="username" placeholder="Username" onChangeHandler={userCredentialsHandler} error={userCredintialError.username && true}
                                errorMessage={userCredintialError.username} value={userCredintial.username}/>
                    <div className="inline-form">
                        <TextInput id="first_name" name="first_name" placeholder="First Name" size="small" onChangeHandler={userCredentialsHandler}
                            value={userCredintial.first_name}/>
                        <TextInput id="last_name" name="last_name" placeholder="Last Name" size="small" onChangeHandler={userCredentialsHandler}
                            value={userCredintial.last_name}/>
                    </div>
                    <TextInput id="email" name="email" placeholder="Email" onChangeHandler={userCredentialsHandler} error={userCredintialError.email && true}
                                errorMessage={userCredintialError.email} value={userCredintial.email}/>
                    
                    <TextInput id="password" name="password" placeholder="Password" type={passwordType ? "password" : "text" } value={userCredintial.password}
                                onChangeHandler={userCredentialsHandler} error={userCredintialError.password} icon={eyeIcon}/>
                    <TextInput id="confirm_password" name="confirm_password" placeholder="Confirm Password" value={userCredintial.confirm_password}
                                type={passwordType ? "password" : "text" } onChangeHandler={userCredentialsHandler} error={userCredintialError.password}
                                errorMessage="Password don't match" icon={eyeIcon}/>
                    {loading ? 
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress size={27}/>
                        </div> :
                    <FilledSquareButton onClickHandler={onRegister} name="SignUp" size="large"/>
                    }
                    <p className="normal-text" style={{fontSize:".8rem"}}>Already have an account? 
                        <span>
                            <Link to="/login" style={{color: "#006CB0",textDecoration: "none"}}>Please Sign in</Link>
                        </span>
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Register