import React, {useState, useEffect} from 'react'
import './branches.css'

import { getBranches, createNewBranch, deleteBranch, updateBranch } from '../../../actions/role'
import { useDispatch } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress';

import SimpleTable from "./../../../components/Table/SimpleTable"
import FilledRoundedButton from "./../../../components/Button/FilledRoundedButton"
import Modal from '../../../components/Modal'
import TextInput from "../../../components/TextInput"
import Loading from "../../../components/Loading"
import axios from 'axios'

function Branches({role}) {
    const dispatch = useDispatch()

    const [tableHeader, setTableHeader] = useState([
        {name:"Action", size:"small"},
        {name:"ID", size:"small"},
        {name:"Name", size:"medium"}, 
        {name:"Location", size:"medium"}, 
    ])

    const [value, setValue] = useState([])
    const [newBranch, setNewBranch] = useState({})
    const [openBranchCreateModal, setOpenBranchCreateModal] = useState(false)
    const [error, setError] = useState({})
    const [isUpdate, setIsUpdate] = useState(false)
    const [loading, setLoading] = useState(false)

    // useEffect(() => {
    //     setNewBranch({name:"", location:""})
    // }, [openBranchCreateModal])

    useEffect(() => {
        dispatch(getBranches())
            .then(response => {
                if(response.status === 200){
                    setValue(response.data)
                }
            })
    }, [])

    const deleteBranchHandler = (id) => {
        setLoading(true);
        dispatch(deleteBranch(id.id))
            .then(response => {
                if(response.status === 204){
                    setValue(prevState => prevState.filter(branch => branch.id !== id.id))
                }
                setLoading(false)
            })
    }

    const editBranchHandler = (branch) => {
        setNewBranch(branch);
        setIsUpdate(true);
        setOpenBranchCreateModal(true);
    }

    const updateBranchHandler = () => {
        setLoading(true);
        dispatch(updateBranch(newBranch))
        .then(response => {
            if(response.status === 200) {
                setValue(prevState => prevState.map(branch => branch.id === newBranch.id ? newBranch : branch))
                setError({})
            } else {
                setError(prevState => ({...prevState, ...response.data}))
            }
            setLoading(false)
        })
    }

    const newBranchDataHandler = (event) => {
        setNewBranch(prevState => ({...prevState, [event.target.name]: event.target.value}))
    }

    const createBranchHandler = () => {
        setLoading(true);
        dispatch(createNewBranch(newBranch))
            .then(response => {
                if(response.status === 201){
                    setValue(prevState => [...prevState, response.data])
                    setOpenBranchCreateModal(false)
                    setError({})
                } else {
                    
                    setError(response.data)
                }
                setLoading(false)
            })
    }

    const createBranchModalBody = () => (
        <>
            <TextInput id="name" name="name" placeholder="Branch Name" onChangeHandler={newBranchDataHandler} size="large"
                value={newBranch.name} error={error.name || false} errorMessage={error.name && error.name[0]}/>
            <TextInput id="location" name="location" placeholder="Enter Location" onChangeHandler={newBranchDataHandler} 
                size="large" value={newBranch.location} error={error.location || false} errorMessage={error.location && error.location[0]}/>
            {loading ? <CircularProgress size={27}/> :
            <FilledRoundedButton onClickHandler={isUpdate ? updateBranchHandler : createBranchHandler} 
                name={`${isUpdate ? "Update" : "Create"} Branch`} color="green" size="large"/>}

        </>
    )

    const tableBody = (branch) => (
        <>
            {Object.keys(branch).map((v, j) => <td key={j}>{branch[v]}</td>)}
        </>
    )

    return role.can_manage_branch ? (
        <div>
            <Modal header={`${isUpdate ? "Update" : "Create a new"} branch`} body={createBranchModalBody} isOpen={openBranchCreateModal} 
                    modalHandler={() => {
                        setOpenBranchCreateModal(false)
                        setError({})
                        setNewBranch({})
                        setIsUpdate(false)
                }}/>
            <div className="align-right">
                <FilledRoundedButton onClickHandler={() => setOpenBranchCreateModal(true)} 
                    name="Create Branch" color="green" size="small"/>
            </div>
            {loading ? <Loading/> :
                <SimpleTable header={tableHeader} values={value} onDelete={deleteBranchHandler} onEdit={editBranchHandler} tableBody={tableBody}/>
            }
        </div>
    ) : <></>
}

export default Branches