import React, { useState, useEffect } from 'react'

import { FiFilter, FiChevronDown } from 'react-icons/fi';
import {FiSearch} from 'react-icons/fi'
import TextInput from '../../../components/TextInput';
import Dropdown from '../../../components/Dropdown';

function Filter({filterOpen, setOpen, branches, vendors, filterParameter, filterDataHandler, paidByUsers, classification}) {
    const [textData, setTextData] = useState({})

    useEffect(() => {setTextData({})}, [filterOpen])

    const searchIcon = () => (
        <FiSearch style={{position:"absolute", top:"1rem", right:"1rem", zIndex: "10"}} size="20"/> 
    )

    const onEnterPress = (event) => {
        if(event.key === "Enter") {
            filterDataHandler(event)
        }
    }
    
    return (
        <>
            {/* <div className="filter" onClick={setOpen}>
                <FiFilter color="#006CB0" size="25" style={{margin: ".5rem"}}/>
                <FiChevronDown color="#006CB0" size="25" style={{margin: ".5rem"}}/>
            </div> */}

            <div className={`filter-body ${filterOpen ? "filter-body-open" : ""}`}>
                <div className="filter-body-left">
                    <TextInput id="from" name="from" placeholder="From" value={filterParameter.from} 
                        type="date" onChangeHandler={filterDataHandler}/>
                    <TextInput id="to" name="to" placeholder="To" value={filterParameter.to} 
                        type="date" onChangeHandler={filterDataHandler}/>
                    {/* <TextInput id="classification" name="classification" placeholder="Classification" onChangeHandler={(event) => setTextData(prevState => ({...prevState, [event.target.name]:event.target.value}))}
                        value={textData.classification} size="medium" list={true} onKeyPress={onEnterPress} /> */}
                    <datalist id="options">
                        {classification.map(option => (
                            <option value={option.classification}>{option.classification}</option>
                        ))}
                    </datalist>
                        <Dropdown id="paid_by" name="paid_by" placeholder="Paid By" onChangeHandler={filterDataHandler} 
                        size="medium" options={paidByUsers} defaultValue={filterParameter.paid_by || "DEFAULT"} />
                    <Dropdown id="paid_to" name="paid_to" placeholder="Paid To" onChangeHandler={filterDataHandler} 
                        size="medium" options={vendors} defaultValue={filterParameter.paid_to || "DEFAULT"} />
                </div>
                <div className="filter-body-right">
                    <TextInput id="search" name="search" placeholder="Search" onKeyPress={onEnterPress} 
                        icon={searchIcon} value={textData.search} onChangeHandler={(event) => setTextData(prevState => ({...prevState, [event.target.name]:event.target.value}))}/>
                    <TextInput id="classification" name="classification" placeholder="Classification" onChangeHandler={(event) => setTextData(prevState => ({...prevState, [event.target.name]:event.target.value}))}
                        value={textData.classification} size="medium" list={true} onKeyPress={onEnterPress} />
                    {/* <Dropdown id="branch" name="branch" placeholder="Branch" onChangeHandler={filterDataHandler} 
                        size="medium" options={branches} defaultValue={filterParameter.branch || "DEFAULT"} /> */}
                </div>
            </div>
            
        </>
    )
}

export default Filter