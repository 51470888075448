import axios from "axios";
import {LOGIN_FAIL, LOGIN_SUCCESS, SIGNUP_SUCCESS, SIGNUP_FAIL, USER_LOADED_SUCCESS, USER_LOADED_FAIL,
        ADD_MESSAGE, LOADING, LOGOUT} from "./types";
import {getHeader} from "./../utility"

export const login = (username, password) => (dispatch, getState) => {
    const body = JSON.stringify({username, password});
    return axios
    .post(`/auth/token/`, body, getHeader(getState))
    .then(response => {
        if(response.status === 200){
            dispatch({
                type: LOGIN_SUCCESS,
                payload: response.data
            })
            dispatch(loadUser());
        }
        return response.status
    })
    .catch(err => {
        console.error("###### Failed to login ############")
        dispatch({
            type: LOGIN_FAIL,
            payload: {msg: err.response.data.error, status: err.response.status}
        })
        return err.response.status
    })
}

export const register = (bodyData) => (dispatch, getState) => {
    const body = JSON.stringify(bodyData)
    return axios
        .post(`/auth/user/`, body, getHeader(getState))
        .then(response => {
            if(response.status === 201){
                dispatch({
                    type: SIGNUP_SUCCESS,
                })
                return {status: response.status, message: "Successful. Please login."}
            }
        })
        .catch(err => {
            console.error("###### Failed to create user ############")
            dispatch({
                type: SIGNUP_FAIL,
            })
            let message = {}
            if("username" in err.response.data) message["username"] = err.response.data?.username[0]
            if( "email" in err.response.data) message["email"] = err.response.data?.email[0]
            if ("password" in err.response.data) message["password"] = err.response.data?.password
            if("error" in err.response.data){
                dispatch({
                    type: ADD_MESSAGE,
                    payload: {msg: err.response.data.error, status: 400},
                })
            }
            return {status: err.response.status, message:message}
        })
}

export const loadUser = () => (dispatch, getState) => {
    dispatch({type: LOADING})
    
    axios
    .get(`/auth/user-details/`, getHeader(getState))
    .then(response => {
        if(response.status === 200) {
            // const user_role = response.data.role
            const {role, ...user} = response.data
            if(role.role_name === ""){
                dispatch({
                    type: ADD_MESSAGE,
                    payload: {status: 400, msg: "You have no role to perform. Loging out..."}
                })
                dispatch({type:LOGOUT})
                return 400
            }
            dispatch({
                type: USER_LOADED_SUCCESS,
                payload:{user:user, role:role}
            })
        }
    })
    .catch(err => {
        console.error("###### Failed to load user ############")
        dispatch({
            type: USER_LOADED_FAIL
        })
    })
}

export const getEmployees = () => (dispatch,getState) => {
    return axios
            .get(`/auth/employee/`, getHeader(getState))
            .then(response => {
                return response.data
            })
            .catch(err => console.error("##### Failed to load employee list #####"))
}

export const updateEmployee = (user, id) => (dispatch, getState) => {
    const body = JSON.stringify(user)
    return axios
        .put(`/auth/employee/${id}/`, body, getHeader(getState))
        .then(response => {
            dispatch({
                type: ADD_MESSAGE,
                payload: {msg: "User updated successfully.", status: response.status}
            })
            return {status: response.status, data: response.data}
        })
        .catch(err => {
            console.error("##### Failed to update user #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: {status: err.response.status, msg: "Failed to update user."}
            })
        })
}

export const deleteEmployee = (id) => (dispatch,getState) => {
    return axios
            .delete(`/auth/employee/${id}/`, getHeader(getState))
            .then(response => {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: {msg: "Employee deleted successfully.", status: response.status}
                })
                return {status: response.status, data: response.data}
            })
            .catch(err => {
                console.error("##### Failed to delete employee #####")
                dispatch({
                    type: ADD_MESSAGE,
                    payload: {status: err.response.status, msg: "Failed to delete employee."}
                })
            })
}

export const getRoles = (details="") => (dispatch,getState) => {
    return axios
            .get(`/auth/role/?details=${details}`, getHeader(getState))
            .then(response => {
                return {status: response.status, data: response.data}
            })
            .catch(err => console.error("##### Failed to load employee role #####"))
}

export const inviteNewUser = (data) => (dispatch,getState) => {
    const body = JSON.stringify(data)
    return axios
            .post(`/auth/invite/`, body, getHeader(getState))
            .then(response => {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: {msg: "User invited successfully.", status: response.status}
                })
                return {status: response.status, message:""}
            })
            .catch(err => {
                console.error("##### Failed to invite new employee #####")
                if(err.response.status === 400){
                    return {status: err.response.status, data: err.response.data}
                }
            })
}

export const userProfileGet = () => (dispatch, getState) => {
    return axios
        .get(`/auth/profile/`, getHeader(getState))
        .then(response => {
            return {status: response.status, data: response.data}
        })
        .catch(err => {
            console.error("##### Failed to load user information #####")
        })
}

export const userProfileUpdate = (data) => (dispatch, getState) => {
    const body = JSON.stringify(data)
    return axios
        .put(`/auth/profile/`, body, getHeader(getState))
        .then(response => {
            dispatch({
                type: ADD_MESSAGE,
                payload: {status:response.status, msg: "Update Successfully"}
            })
            return {status: response.status, data: response.data}
        })
        .catch(err => {
            console.error("##### Failed to update user information #####")
            dispatch({
                type: ADD_MESSAGE,
                payload:{status: err.response.status, msg: "Failed to update user information"}
            })
            return {status: err.response.status, data:err.response.data}
        })
}

export  const sendEmailClick = (email) => (dispatch) => {
   return axios({
        method: 'POST',
        url: 'auth-reset/users/reset_password/',
        data: {
            email: email
        }
    })
    .then(response =>  {
        dispatch({
            type: ADD_MESSAGE,
            payload: {msg: "Password reset request sent.", status: response.status}
        })
        return {status: response.status, message:""}
    })
    .catch((error) => {
        console.log(error);
    })
}

export const confirmResetPassword = (uid, token, new_password, re_new_password) => (dispatch) => {

    return axios({
        method: 'POST',
        url: '/auth-reset/users/reset_password_confirm/',
        data: {
            uid: uid,
            token: token,
            new_password: new_password,
            re_new_password: re_new_password
        }
    })
    .then(response => {
        dispatch({
            type: ADD_MESSAGE,
            payload: {msg: "Password reset request sent.", status: response.status}
        })
        return {status: response.status, message:""}
    })
    .catch((error) => {
        console.log(error);
        return {status: error.response.status, data: error.response.data}
    })
}
