import React from 'react'
import { useSelector } from "react-redux"
import Loading from "../../components/Loading"
import { useNavigate } from 'react-router-dom'

function PublicRoute({children}) {
    const {isAuthenticated, isLoading} = useSelector((state) => state.auth)
    const navigate = useNavigate()

    return isLoading ? <Loading/> : !isAuthenticated ? children : navigate(-1)
}

export default PublicRoute