import React, {useState, useEffect, useRef} from 'react'
import TextInput from '../../../components/TextInput'
import Dropdown from '../../../components/Dropdown'

import { useDispatch } from 'react-redux'

import {getCourseBatch} from "../../../actions/comments"

function Contact({handleIncomeData, handleStudentData, softTraining, referencePersons, incomeInformation, studentInformation, error }) {
    const dispatch = useDispatch()
    const focusDiv = useRef()
    const [courseName, setCourseName] = useState([])

    useEffect(() => {
        courseName.length === 0 && getCourseName()
    }, [])

    const getCourseName = () => {
        dispatch(getCourseBatch('course'))
        .then(response => {
            if(response.status === 200){
                setCourseName(response.data.map( course => course.course_name))
            }
        })
    }

    return (
        <div className="information-block">
        <p className="information-block-header">Other Information</p>
            <div className="information-row">
                <div>
                    <TextInput id="source" name="source" placeholder="Source" value={studentInformation.source} type="text" onChangeHandler={handleStudentData} 
                        size="large" error={error.source || false} errorMessage={error.source && error.source[0]}/>
                </div>
                {softTraining && <>
                    <div>
                        <TextInput id="referred_by" name="referred_by" placeholder="Referred By" value={incomeInformation.referred_by} type="text" 
                            onChangeHandler={handleIncomeData} size="large" error={error.referred_by || false} errorMessage={error.referred_by && error.referred_by[0]}/>
                    </div>
                    <div>
                        <TextInput id="course_name" name="course_name" placeholder="Course" type="text" list={true} ref={focusDiv} value={incomeInformation.course_name} 
                            onChangeHandler={handleIncomeData} required={true} size="large" error={error.course_name || false}
                            errorMessage={error.course_name && error.course_name[0]}/>
                        <datalist id="options">
                            {courseName.map(option => (
                                <option value={option}>{option}</option>
                            ))}
                        </datalist>
                    </div>
                </>}
                <div>
                    <Dropdown id="follow_up_person" name="follow_up_person" placeholder="Person to Follow Up" onChangeHandler={handleStudentData} 
                        size="large" options={referencePersons} defaultValue={studentInformation.follow_up_person} error={error.follow_up_person || false}/>
                </div>
            </div>
        </div>
    )
}

export default Contact