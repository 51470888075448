import axios from "axios";
import { getHeader } from "./../utility"
import { ADD_MESSAGE } from './../actions/types'
import { relativeTimeRounding } from "moment";

export const getExpense = (params) => (dispatch, getState) => {
    let config = getHeader(getState)
    config['params'] = params
    return axios
        .get(`expense/`, config)
        .then(response => {
            if (response.status === 200) {
                return { status: response.status, totalPage: response.data.total_pages, data: response.data.results }
            }
        })
        .catch(err => {
            console.error("##### Failed to load expense data ######")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Failed to load expense data", status: err.response.status }
            })
        })
}

export const createExpense = (expense, view) => (dispatch, getState) => {
    let config = getHeader(getState)
    config['Content-Type'] = 'multipart/form-data'
    let split = expense.split
    delete expense.split
    let body = new FormData()
    for (const key in expense) {
        expense[key] !== null && body.append(key, expense[key])
    }
    if (split) body.append("split", JSON.stringify(split))

    return axios
        .post(`/expense/?view=${view}`, body, config)
        .then(response => {
            if (response.status === 201) {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Expense successfully created", status: response.status }
                })
                return { status: response.status, data: response.data }
            }
        })
        .catch(err => {
            console.error("#####Failed to create expense#####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Failed to create expense", status: err.response.status }
            })
            return { status: err.response.status, data: err.response.data }
        })
}

export const updateExpense = (update, expense, query_params) => (dispatch, getState) => {
    let config = getHeader(getState)
    config['Content-Type'] = 'multipart/form-data'
    config['params'] = query_params
    let split = expense.split
    delete expense.split
    let body = new FormData()
    for (const key in expense) {
        expense[key] !== null && body.append(key, expense[key])
    }

    if (split) body.append("split", JSON.stringify(split))

    return axios
        .patch(`/expense/${update}/`, body, config)
        .then(response => {
            if (response.status === 200) {
                let msg = "Expense successfully updated"
                if (Object.keys(expense).length === 1) {
                    if (expense.reviewed)
                        msg = "Expense marked as reviewed"
                    else msg = "Expense unreviewed"
                }
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: msg, status: response.status }
                })
                return { status: response.status, data: response.data }
            }
        })
        .catch(err => {
            console.error("#####Failed to update expense#####")
            if (err.response.status === 401) {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: err.response.data, status: err.response.status }
                })
            } else {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Failed to update expense", status: err.response.status }
                })
            }
            return { status: err.response.status }
        })
}

export const getSingleExpense = (id, view) => (dispatch, getState) => {
    return axios
        .get(`/expense/${id}/?view=${view}`, getHeader(getState))
        .then(response => {
            if (response.status === 200) {
                return { status: response.status, data: response.data }
            }
        })
        .catch(err => {
            console.error("##### Failed to load Expense #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Something went wrong", status: err.response.status }
            })
        });
}

export const deleteExpense = (id, query_params) => (dispatch, getState) => {
    let config = getHeader(getState)
    config['params'] = query_params

    return axios
        .delete(`/expense/${id}/`, config)
        .then(response => {
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Successfully Deleted", status: response.status }
            })
            return { status: response.status }
        })
        .catch(err => {
            console.error("##### Failed to delete expense #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Something went wrong", status: 400 }
            })
        })
}

export const deleteMultipleExpense = (ids, query_params) => (dispatch, getState) => {

}

export const deleteFile = (id) => (dispatch, getState) => {

    return axios
        .delete(`/expense/file/${id}/`, getHeader(getState))
        .then(response => {
            if (response.status === 204) {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "successfully Deleted", status: 204 }
                })
                return { status: response.status }
            }
        })
        .catch(error => {
            console.error("##### Failed to Delete #####")
            if (error.response.status === 401) {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: error.response.data.message, status: error.response.status }
                })
            }
            else {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Something went wrong, Failed to delete.", status: 400 }
                })
            }
        })
}

export const getClassification = () => (dispatch, getState) => {

    return axios
        .get(`/expense/classification/`, getHeader(getState))
        .then(response => {
            if (response.status === 200) {
                return { status: response.status, data: response.data }
            }
        })
        .catch(error => {
            console.error("##### Failed to Load expense classification #####")
        })
}

export const importFile = (file) => (dispatch, getState) => {
    let body = new FormData()
    body.append('file', file)
    return axios
        .post('/expense/import/', body, getHeader(getState))
        .then(response => {

            if (response.status === 201) {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Successfully Import Data", status: 201 }
                })
                return response.status
            }
        })
        .catch(err => {
            console.error("##### Failed to import expense data #####")
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: err.response.data.error, status: 400 }
            })
            return err.response.status
        })
}