import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EditOtherIncome = ({ incomeId, handleEditIncome }) => {
    const [formData, setFormData] = useState({
        payment_method: '',
        date: '',
        detail: '',
        category: '',
        target_amount: '',
        paid_amount: '',
        remaining_amount: '',
        customer_name: '',
        receipt: null,
    });

    useEffect(() => {
        if (incomeId) {
            fetchIncome();
        }
    }, [incomeId]);

    const fetchIncome = async () => {
        try {
            const accessToken = localStorage.getItem('access');
            const config = {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            };
            const res = await axios.get(`https://ems-api.qatekinternal.com/income/other-incomes/${incomeId}/`, config);
            setFormData(res.data);
        } catch (error) {
            console.error('Error fetching income:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        // If the target name is 'receipt' and files are present, update the receipt file
        if (name === 'receipt' && files.length > 0) {
            const file = files[0];
            setFormData({ ...formData, receipt: file });
        } else {
            // For other fields, update the form data as usual
            setFormData({ ...formData, [name]: value });
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const accessToken = localStorage.getItem('access');
            const formDataToSend = new FormData();
            Object.keys(formData).forEach(key => {
                if (key === 'receipt') {
                    return;
                }
                formDataToSend.append(key, formData[key]);
            });

            console.log("Request Payload:", formDataToSend);

            const config = {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data',
                }
            };

            const res = await axios.put(`https://ems-api.qatekinternal.com/income/other-incomes/${incomeId}/`, formDataToSend, config);

            console.log('Income updated:', res.data);
            handleEditIncome();
        } catch (error) {
            console.error('Error updating income:', error);
            // Log specific error data
            console.error('Error Response Data:', error.response.data);
            console.error('Error Response Status:', error.response.status);
        }
    };


    const inputStyle = {
        backgroundColor: '#EAEFF4',
        boxSizing: 'border-box',
        border: '2px solid #CDCDCD',
        borderRadius: '5px',
        padding: '0.5rem',
        margin: '0.5rem',
        fontSize: '1rem',
        zIndex: 0,
        position: 'relative'
    };

    const labelStyle = {
        // position: 'absolute',
        top: '0px',
        left: '10px',
        padding: '0 1px',
        color: '#DADCE0',
        fontSize: '14px',
        transition: '0.5s',
        borderRadius: '4px',
        zIndex: 0,
        fontWeight: 600,
        color: '#006CB0'
    };

    return (

        <div>
            {/* <h2>Edit Other Income</h2> */}
            <hr />
            <form onSubmit={handleSubmit} style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Payment Method:</label>
                    <input type="text" name="payment_method" value={formData.payment_method} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Date:</label>
                    <input type="date" name="date" value={formData.date} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Detail:</label>
                    <input type="text" name="detail" value={formData.detail} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Category:</label>
                    <input type="text" name="category" value={formData.category} onChange={handleChange} style={inputStyle} />
                </div>

                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Target Amount:</label>
                    <input type="number" name="target_amount" value={formData.target_amount} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Paid Amount:</label>
                    <input type="number" name="paid_amount" value={formData.paid_amount} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Remaining Amount:</label>
                    <input type="number" name="remaining_amount" value={formData.remaining_amount} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Customer Name:</label>
                    <input type="text" name="customer_name" value={formData.customer_name} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={{ display: 'grid', alignItems: 'center' }}>
                    <label style={labelStyle}>Receipt:</label>
                    <input type="file" name="receipt" onChange={handleChange} style={inputStyle} />
                </div>
                <button type="submit" style={{ ...inputStyle, gridColumn: 'span 2', justifySelf: 'center' }}>update</button>
            </form>
        </div>
    );
};

export default EditOtherIncome;















// // must need to upload file for editing

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const EditOtherIncome = ({ incomeId, closeModal, handleEditIncome }) => {
//     const [formData, setFormData] = useState({
//         payment_method: '',
//         date: '',
//         detail: '',
//         category: '',
//         target_amount: '',
//         paid_amount: '',
//         remaining_amount: '',
//         customer_name: '',
//         receipt: null,
//     });

//     useEffect(() => {
//         if (incomeId) {
//             fetchIncome();
//         }
//     }, [incomeId]);

//     const fetchIncome = async () => {
//         try {
//             const accessToken = localStorage.getItem('access');
//             const config = {
//                 headers: {
//                     'Authorization': `Bearer ${accessToken}`,
//                 }
//             };
//             const res = await axios.get(`https://ems-api.qatekinternal.com/income/other-incomes/${incomeId}/`, config);
//             setFormData(res.data);
//         } catch (error) {
//             console.error('Error fetching income:', error);
//         }
//     };

//     const handleChange = (e) => {
//         const { name, value, files } = e.target;
//         if (name === 'receipt' && files.length > 0) {
//             const file = files[0];
//             setFormData({ ...formData, receipt: file });
//         } else {
//             setFormData({ ...formData, [name]: value });
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const accessToken = localStorage.getItem('access');
//             const formDataToSend = new FormData();

//             // Append all form data except the receipt
//             Object.keys(formData).forEach(key => {
//                 if (key !== 'receipt') {
//                     formDataToSend.append(key, formData[key]);
//                 }
//             });

//             // Append the receipt only if a new file is selected
//             if (formData.receipt) {
//                 formDataToSend.append('receipt', formData.receipt);
//             } else {
//                 // If no new file is selected, send the existing receipt value
//                 formDataToSend.append('receipt', formData.receipt);
//             }

//             console.log("Request Payload:", formDataToSend);

//             const config = {
//                 headers: {
//                     'Authorization': `Bearer ${accessToken}`,
//                     'Content-Type': 'multipart/form-data',
//                 }
//             };

//             const res = await axios.put(`https://ems-api.qatekinternal.com/income/other-incomes/${incomeId}/`, formDataToSend, config);

//             console.log('Income updated:', res.data);

//             handleEditIncome();
//             closeModal();

//         } catch (error) {
//             console.error('Error updating income:', error);
//             // Log specific error data
//             console.error('Error Response Data:', error.response.data);
//             console.error('Error Response Status:', error.response.status);
//         }
//     };


//     return (
//         <div>
//             <h2>Edit Other Income</h2>
//             <form onSubmit={handleSubmit}>
//                 <div>
//                     <label>Payment Method:</label>
//                     <input type="text" name="payment_method" value={formData.payment_method} onChange={handleChange} />
//                 </div>
//                 <div>
//                     <label>Date:</label>
//                     <input type="date" name="date" value={formData.date} onChange={handleChange} />
//                 </div>
//                 <div>
//                     <label>Detail:</label>
//                     <input type="text" name="detail" value={formData.detail} onChange={handleChange} />
//                 </div>
//                 <div>
//                     <label>Category:</label>
//                     <input type="text" name="category" value={formData.category} onChange={handleChange} />
//                 </div>

//                 <div>
//                     <label>Target Amount:</label>
//                     <input type="number" name="target_amount" value={formData.target_amount} onChange={handleChange} />
//                 </div>
//                 <div>
//                     <label>Paid Amount:</label>
//                     <input type="number" name="paid_amount" value={formData.paid_amount} onChange={handleChange} />
//                 </div>
//                 <div>
//                     <label>Remaining Amount:</label>
//                     <input type="number" name="remaining_amount" value={formData.remaining_amount} onChange={handleChange} />
//                 </div>
//                 <div>
//                     <label>Customer Name:</label>
//                     <input type="text" name="customer_name" value={formData.customer_name} onChange={handleChange} />
//                 </div>
//                 <div>
//                     <label>Receipt:</label>
//                     <input type="file" name="receipt" onChange={handleChange} />
//                 </div>
//                 <button type="submit">Save Changes</button>
//             </form>
//         </div>
//     );
// };

// export default EditOtherIncome;
