import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { FiEye } from "react-icons/fi"

import FilledSquareButton from '../components/Button/FilledSquareButton'
import Header from '../components/Header'
import TextInput from '../components/TextInput'
import COLORS from '../utility/styles'
import { confirmResetPassword } from '../actions/auths'
import { useNavigate } from 'react-router'

const Container = styled.div`
    height:100%;
    width:100%;
    position:absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${COLORS.secondaryColor}
`

const Body = styled.div`
    background-color: white;
    width:55%;
    height:70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { uid } = useParams()
  const { token } = useParams()
  const [passwordType, setPasswordType] = useState(true)
  const [error, setError] = useState({})

  const dispatch = useDispatch()
  const [redirectLogin, setRedirectLogin] = useState(false)
  const navigate = useNavigate()
  
  const onClick = (event) => {
    event.preventDefault();
    dispatch(confirmResetPassword(uid, token, newPassword, confirmPassword))
    .then(response => {
      if(response.status === 200)
        setRedirectLogin(true)
      else{
        setError({...response.data})
      }
    })
  }

  if (redirectLogin) {
    navigate("/login")
  }

  const eyeIcon = () => (
    <FiEye style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer", zIndex: "10"}} size="18" onClick={() => setPasswordType(!passwordType)}/> 
)

  return (
    <Container>
        <Body>
            <Header text='Set New Password'></Header>
            <TextInput id='new-password' name='new-password' placeholder='New Password' value={newPassword} 
              onChangeHandler={(event) => setNewPassword(event.target.value)} 
              type={passwordType ? "password" : "text" } icon={eyeIcon}
              error={error.new_password || false} errorMessage={error.new_password && error.new_password[0]}></TextInput>
            <TextInput id='confirm-password' name='confirm-password' placeholder='Confirm Password' 
                value={confirmPassword} onChangeHandler={(event) => setConfirmPassword(event.target.value)} 
                type={passwordType ? "password" : "text" } icon={eyeIcon}
                error={error.confirm_password || false} errorMessage={error.confirm_password && error.confirm_password[0]}></TextInput>
            <FilledSquareButton name='Submit' onClickHandler={onClick}></FilledSquareButton>
        </Body>
    </Container>
  )
}

export default ResetPassword