import React, {useState} from 'react'
import {FiChevronDown, FiSquare, FiCheckSquare} from "react-icons/fi"
import "./accordion.css"

function Accordion({ roleList, showDetails, content }) {
    const [isOpen, setOpen] = useState(-1)
    return (
        <div className="accordion">
            {/* <div className="accordion-header" onClick={(event) => (event.target.classList.toggle('open'))}>
                <p>Test</p>
                <FiChevronDown color="#006CB0"/>
            </div>
            <div className="accordion-body">
                {content()}
            </div> */}
            {roleList.map((role, i) => (
                <div key={i}>
                    <div className={`accordion-header`} onClick={(event) => (event.target.classList.toggle('open'))}>
                        <p>{role.role_name}</p>
                        <FiChevronDown color="#006CB0"/>
                    </div>
                    <div className="accordion-body">
                        {content(role)}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Accordion