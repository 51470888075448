import React, { useState, useEffect } from 'react'

import { useSearchParams, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { addIncome, getEmployees, getStudentList, getIncome, updateIncome, installmentUpdate, getEnrolledStudents, getBatchList, retrieveStudent, addIncomeSubmit }
    from '../../../actions/income'
import { ADD_MESSAGE } from '../../../actions/types'

import TextInput from '../../../components/TextInput'
import Dropdown from '../../../components/Dropdown'
import ButtonOulined from '../../../components/Button/ButtonOulined'
import FilledRoundedButton from "../../../components/Button/FilledRoundedButton"
import CircularProgress from '@mui/material/CircularProgress';
import { getBranches } from '../../../actions/role'

import StudentInformation from './StudentInformation'
import Address from './Address'
import Contact from './Contact'
import TrainingPaymentDetails from './TrainingPaymentDetails'
import JobSupportPaymentDetails from './JobSupportPaymentDetails';

function AddIncome() {
    const [formType] = useSearchParams()
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { state } = useLocation()
    const [incomeInformation, setIncomeInformation] = useState({})
    const [studentInformation, setStudentInformation] = useState({})
    const [installmentInformation, setInstallmentInformation] = useState({})

    const [updateIncomeInformation, setUpdateIncomeInformation] = useState({})
    const [updateStudentInformation, setUpdateStudentInformation] = useState({})
    const [updateInstallmentInformation, setUpdateInstallmentInformation] = useState({})
    const [error, setError] = useState({})

    const [referencePersons, setReferencePersons] = useState([])
    const [existingStudentList, setExistngStudentList] = useState([])
    const [enrolledStudentList, setEnrolledStudentList] = useState([])
    const [openDetails, setOpenDetails] = useState(false)
    const [readOnly, setReadOnly] = useState(false)
    const [batch, setBatch] = useState([])
    const [branches, setBranches] = useState([])
    const [loading, setLoading] = useState({})
    const [isCreate, setIsCreate] = useState(false)

    const studentTypeOptions = [{ name: "From Software Training", value: "Existing" }, { name: "Existing", value: "Internal" }, { name: "New", value: "New" }]

    useEffect(() => {
        if (branches.length === 0) {
            dispatch(getBranches())
                .then(response => {
                    if (response.status === 200) {
                        let x = response.data.map(branch => ({ name: branch.name, value: branch.id }))
                        setBranches(x)
                    }
                })
        }
        if (id !== undefined && state.type === "update") {
            let incomeType = formType.get('type') === 'training' ? "software-training" : "job-support"
            setIsCreate(true)
            dispatch(getIncome(id, incomeType))
                .then(response => {
                    if (response.status === 200) {
                        let { student, first_installment, second_installment, third_installment, fourth_installment, ...income } = response.data
                        setStudentInformation(student)
                        setIncomeInformation(income)
                        let x = {}
                        if (first_installment !== undefined && first_installment !== null) x = { ...x, first_installment }
                        if (second_installment !== undefined && second_installment !== null) x = { ...x, second_installment }
                        if (third_installment !== undefined && third_installment !== null) x = { ...x, third_installment }
                        if (fourth_installment !== undefined && fourth_installment !== null) x = { ...x, fourth_installment }
                        setInstallmentInformation(x)
                        setOpenDetails(true)
                    }
                    setIsCreate(false)
                })
        }
    }, [])

    useEffect(() => {
        dispatch(getEmployees())
            .then((response) => {
                if (response.status === 200)
                    setReferencePersons(response.data)
                else
                    dispatch({
                        type: ADD_MESSAGE,
                        payload: { status: 500, msg: "Something went wrong." }
                    })
            })
    }, [])

    const handleIncomeData = (event) => {
        if (batch.length === 0) {
            setLoading(prevState => ({ ...prevState, batch: true }))
            dispatch(getBatchList())
                .then(response => {
                    if (response.status === 200) {
                        let x = response.data.filter(b => b.batch_name !== null).map(bn => ({ name: bn.batch_name, value: bn.batch_name }))
                        setBatch(x)
                    }
                    setLoading(prevState => ({ ...prevState, batch: false }))
                })
        }
        if (event.target.name === "student_type" && event.target.value === "New") {
            setOpenDetails(true)
            setReadOnly(false)
            setStudentInformation({})
        }
        if (event.target.name === "student_type" && event.target.value === "Existing") {
            setOpenDetails(false)
            dispatch(getStudentList())
                .then(response => {
                    if (response.status === 200)
                    console.log(response.data);
                        setExistngStudentList(response.data)
                })
        }
        if (event.target.name === "student_type" && event.target.value === "Internal") {
            setLoading(prevState => ({ ...prevState, student: true }))
            setOpenDetails(false)
            dispatch(getEnrolledStudents(null, formType.get('type')))
                .then(response => {
                    if (response.status === 200) {
                        setEnrolledStudentList(response.data)
                        let x;
                        x = response.data.map(st => (st.is_internal ? { name: st.student_record.email, value: st.id } : { name: st.email, value: st.id }))
                        setExistngStudentList(x)
                    }
                    setLoading(prevState => ({ ...prevState, student: false }))
                })
        }

        if (incomeInformation.student_type === "Internal" && event.target.name === 'batch') {
            setLoading(prevState => ({ ...prevState, student: true }))
            dispatch(getEnrolledStudents(event.target.value, formType.get('type')))
                .then(response => {
                    if (response.status === 200) {
                        setEnrolledStudentList(response.data)
                        let x;
                        if (formType.get('type') === "training") {
                            x = response.data.map(st => ({ name: st.email, value: st.student_id }))
                        } else {
                            x = response.data.map(st => (st.is_internal ? { name: st.student_record.email, value: st.id } : { name: st.email, value: st.id }))
                        }
                        setExistngStudentList(x)
                    }
                    setLoading(prevState => ({ ...prevState, student: false }))
                })

        }
        setIncomeInformation(prevState => ({ ...prevState, [event.target.name]: event.target.value }))
        state.type === "update" && setUpdateIncomeInformation(prevState => ({ ...prevState, [event.target.name]: event.target.value }))
    }

    const handleInstallmentData = (data, event) => {
        let { installment, ...ins } = data

        if (state.type === "update") {
            if (ins.paid_amount) {
                setIncomeInformation(prevState => ({
                    ...prevState, paid_amount: parseFloat(prevState.paid_amount) + parseFloat(ins.paid_amount),
                    remaining_amount: parseFloat(prevState.remaining_amount) - parseFloat(ins.paid_amount)
                }))
                setUpdateIncomeInformation(prevState => ({
                    ...prevState, paid_amount: parseFloat(incomeInformation.paid_amount) + parseFloat(ins.paid_amount),
                    remaining_amount: parseFloat(incomeInformation.remaining_amount) - parseFloat(ins.paid_amount)
                }))
                setUpdateInstallmentInformation(prevState => ({
                    ...prevState, [installment]: {
                        paid_amount: parseFloat(installmentInformation[installment].paid_amount || 0) + parseFloat(ins.paid_amount), paid_date: ins.paid_date
                    }
                }))
                setInstallmentInformation(prevState => ({
                    ...prevState, [installment]: {
                        ...prevState[installment],
                        paid_amount: parseFloat(prevState[installment].paid_amount || 0) + parseFloat(ins.paid_amount),
                        paid_date: ins.paid_date
                    }
                }))
            } else {
                setUpdateInstallmentInformation(prevState => ({ ...prevState, [installment]: ins }))
                setInstallmentInformation(prevState => ({ ...prevState, [installment]: { ...prevState[installment], ...ins } }))
            }
        } else {
            let student_data = enrolledStudentList.filter(student => student.id === parseInt(event.target.value))[0]
            setInstallmentInformation(prevState => ({ ...prevState, first_installment: parseInt(student_data.payment_information.first_payment_status), second_installment: parseInt(student_data.payment_information.second_payment_status), third_installment: parseInt(student_data.payment_information.third_payment_status) }))
        }
    }

    const handleStudentInformation = (event) => {
        if (event.target.name === "stms_id") {
            if (formType.get('type') === "training") {
                let student_data = enrolledStudentList.filter(student => student.id === parseInt(event.target.value))[0]
                let student_branch = branches.filter(branch => branch.name === student_data.location)[0]
                let batch_type = student_data.batch.split("-")[1] || ""
                setStudentInformation(prevState => ({
                    ...prevState, email: student_data.email, name: student_data.name, phone_number: student_data.phone_number,
                    batch_type: batch_type.toLowerCase(), batch: student_data.batch.split("-")[0], country: student_data.student_address.country,
                    state: student_data.student_address.state, city: student_data.student_address.city, post_code: student_data.student_address.post_code,
                    street_address: student_data.student_address.street_address, source: student_data.source, [event.target.name]: event.target.value, branch: student_branch
                }))
                console.log(student_data);
                setIncomeInformation(prevState => ({
                    ...prevState, referred_by: student_data.referred_by, remaining_amount: parseInt(student_data.payment_information.balance_remaining),
                    enrollment_fee: parseInt(student_data.payment_information.enrollment_payment), paid_amount: parseInt(student_data.payment_information.paid_amount), first_installment: parseInt(student_data.payment_information.first_payment_status), second_installment: parseInt(student_data.payment_information.second_payment_status), third_installment: parseInt(student_data.payment_information.third_payment_status), fourth_installment: parseInt(student_data.payment_information.fourth_payment_status),
                    target_amount: parseInt(student_data.payment_information.target_amount),
                    first_payment_date: student_data.payment_information.first_payment_date,
                    second_payment_date: student_data.payment_information.second_payment_date,
                    third_payment_date: student_data.payment_information.third_payment_date,
                    fourth_payment_date: student_data.payment_information.fourth_payment_date,
                    
                    refund_amount: parseInt(student_data.payment_information.refund_amount)
                }))
                setOpenDetails(true)
            } if (formType.get('type') === "support") {
                
                let student_data = enrolledStudentList.filter(student => student.id === parseInt(event.target.value))[0]
                let student_record, student_address;
                if (student_data.is_internal) {
                    student_record = student_data.student_record
                    student_record['phone'] = student_record.phone_number
                    student_record['name'] = student_record.first_name + " " + student_record.last_name
                    student_address = student_data.student_address
                } else {
                    student_record = student_data
                    student_address = student_data.job_support_record_address
                }
                let job_support_record_payment = student_data.job_support_record_payment
                setStudentInformation(prevState => ({
                    ...prevState, email: student_record.email, name: student_record.name,
                    phone_number: student_record.phone, country: student_address.country, state: student_address.state,
                    post_code: student_address.post_code, street_address: student_address.street_address, city: student_address.city,
                    source: student_record.source, [event.target.name]: event.target.value,
                }))
                setIncomeInformation(prevState => ({
                    ...prevState, referred_by: student_record.referred_by, remaining_amount: job_support_record_payment.balance_remaining,
                    hourly_rate: job_support_record_payment.hourly_rate, gross_salary: job_support_record_payment.gross_salary, paid_amount: job_support_record_payment.paid_amount,
                    target_amount: job_support_record_payment.target_amount_external || job_support_record_payment.target_amount_internal, deposit: job_support_record_payment.deposit,
                    first_payment_date: job_support_record_payment.first_payment_date,
                    second_payment_date: job_support_record_payment.second_payment_date,
                    third_payment_date: job_support_record_payment.third_payment_date,
                    fourth_payment_date: job_support_record_payment.fourth_payment_date,
                    refund_amount: job_support_record_payment.refund_amount,
                    deposit_payment_date: job_support_record_payment.deposit_payment_date,
                    first_installment: job_support_record_payment.first_payment_status,
                    second_installment: job_support_record_payment.second_payment_status,
                    third_installment: job_support_record_payment.third_payment_status,
                    fourth_installment: job_support_record_payment.fourth_payment_status,
                }))
                setOpenDetails(true);
            }
        }

        if (event.target.name === "internel_student") {
            dispatch(retrieveStudent(event.target.value))
                .then((response) => {
                    if (response.status === 200) {
                        setStudentInformation(response.data)
                        setOpenDetails(true)
                        setReadOnly(true)
                    }
                })
        }

        else setStudentInformation(prevState => ({ ...prevState, [event.target.name]: event.target.value }))
        state.type === "update" && setUpdateStudentInformation(prevState => ({ ...prevState, [event.target.name]: event.target.value }))

    }

    const recordPayment = (data) => {
        let dataType = formType.get('type') === 'training' ? "software-training" : "job-support"
        dispatch(installmentUpdate(data, id, dataType))
            .then(response => {
                if (response.status === 201) {
                    setInstallmentInformation(prevState => ({ ...prevState, [data.installment]: response.data }))
                }
                else {
                    setError(response.data)
                }

            })
    }

    const updatePaymentHandler = () => {
        let dataType = formType.get('type') === 'training' ? "software-training" : "job-support"
        dispatch(updateIncome({ ...updateIncomeInformation, student: updateStudentInformation, ...updateInstallmentInformation }, id, dataType))
            .then(response => {
                if (response.status === 200) {
                    let { student, first_installment, second_installment, third_installment, fourth_installment, ...income } = response.data
                    setStudentInformation(student)
                    setIncomeInformation(income)
                    let x = {}
                    if (first_installment !== undefined && first_installment !== null) x = { ...x, first_installment }
                    if (second_installment !== undefined && second_installment !== null) x = { ...x, second_installment }
                    if (third_installment !== undefined && third_installment !== null) x = { ...x, third_installment }
                    if (fourth_installment !== undefined && fourth_installment !== null) x = { ...x, fourth_installment }
                    setInstallmentInformation(x)

                    setUpdateStudentInformation({})
                    setUpdateInstallmentInformation({})
                    setUpdateIncomeInformation({})
                }
            })

    }

    const savePaymentHandler = () => {
        let dataType = formType.get('type') === 'training' ? "software-training" : "job-support"
        setIsCreate(true)
        dispatch(addIncomeSubmit({ ...incomeInformation, student: studentInformation, ...installmentInformation }, dataType))
            .then(response => {
                if (response.status === 201) {
                    setError({})
                    setIsCreate(false)
                    navigate('/income')
                }
                else {
                    let { student, ...data } = response.data
                    setIsCreate(false)
                    setError({ ...student, ...data })
                }
            })
    }

    return (
        <div>
            <div className="layout-title">
                <p> Add Income ({formType.get('type') === 'training' ? "Software Training" : "Job Support"})</p>
            </div>

            {state.type !== "update" && <div className="information-block">
                <p className="information-block-header">Student Type</p>
                <div className="information-row">
                    <div>
                        <Dropdown id="student_type" name="student_type" placeholder="Select Student Type" onChangeHandler={handleIncomeData} size="large" error={error.student_type}
                            options={formType.get('type') === 'training' ? studentTypeOptions.slice(1) : studentTypeOptions} defaultValue={incomeInformation.student_type || "DEFAULT"} />
                    </div>

                    {(incomeInformation.student_type === "Internal" && formType.get('type') === 'training') &&
                        <div>
                            <Dropdown id="batch" name="batch" placeholder={"Select Batch"} loading={loading.batch} onChangeHandler={handleIncomeData}
                                size="large" options={batch} defaultValue={incomeInformation.batch || "DEFAULT"} error={error.batch} />
                        </div>
                    }
                    {/* From EMS */}
                    {(incomeInformation.student_type === "Existing") &&
                        <div>
                            <Dropdown id="internel_student" name="internel_student" placeholder={"Select Student"} loading={loading.student} onChangeHandler={handleStudentInformation}
                                size="large" options={existingStudentList} defaultValue={"DEFAULT"} error={error.internel_student} />
                        </div>
                    }

                    {/* From stms */}
                    {(incomeInformation.student_type === "Internal" && formType.get('type') === 'support') &&
                        <div>
                            <Dropdown id="stms_id" name="stms_id" placeholder={"Select Student"} loading={loading.student} onChangeHandler={handleStudentInformation}
                                size="large" options={existingStudentList} withID={true} defaultValue={studentInformation.stms_id || "DEFAULT"} error={error.internel_student} />
                        </div>
                    }
                </div>

                {incomeInformation.batch && incomeInformation.student_type === "Internal" &&
                    <div className="information-row">
                        <div>
                            <Dropdown id="stms_id" name="stms_id" placeholder={"Select Student"} loading={loading.student} onChangeHandler={handleStudentInformation}
                                size="large" options={existingStudentList} withID={true} defaultValue={studentInformation.stms_id || "DEFAULT"} />
                        </div>
                    </div>
                }
            </div>}

            {openDetails && <>
                <StudentInformation handleIncomeData={handleStudentInformation} incomeInformation={studentInformation} readOnly={readOnly}
                    error={error} branches={branches} />
                <Address handleIncomeData={handleStudentInformation} incomeInformation={studentInformation} readOnly={readOnly} error={error} />
            </>}

            <Contact handleIncomeData={handleIncomeData} handleStudentData={handleStudentInformation} softTraining={formType.get('type') === 'training'} error={error}
                referencePersons={referencePersons} incomeInformation={incomeInformation} studentInformation={studentInformation} />

            {formType.get('type') === 'training' ?
                <TrainingPaymentDetails handleIncomeData={handleIncomeData} recordPayment={recordPayment}
                    incomeInformation={incomeInformation} type={state.type} error={error}
                    handleInstallmentData={handleInstallmentData} installmentInformation={installmentInformation} />
                : <JobSupportPaymentDetails handleIncomeData={handleIncomeData} recordPayment={recordPayment}
                    incomeInformation={incomeInformation} type={state.type} error={error}
                    handleInstallmentData={handleInstallmentData} installmentInformation={installmentInformation} />}


            <div className="information-block">
                <div className="information-row">
                    {formType.get('type') === 'support' ? <>
                        <div style={{ width: '49%' }}>
                            <Dropdown id="reference" name="reference" placeholder={"Select Person for reference"} onChangeHandler={handleIncomeData}
                                size="large" options={referencePersons} defaultValue={incomeInformation.reference} error={error.reference || false}
                                errorMessage={error.reference && error.reference[0]} />
                        </div>
                        <div style={{ width: "50%" }}>
                            <TextInput id="job_support_area" value={incomeInformation.job_support_area} name="job_support_area" placeholder="Job Support Area" type="text" onChangeHandler={handleIncomeData} size="large" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <Dropdown id="acknowledge_form" name="acknowledge_form" placeholder={"Is Acknowledged"} onChangeHandler={handleIncomeData}
                                size="large" options={[{ name: "Yes", value: "Yes" }, { name: "No", value: "No" }]} defaultValue={incomeInformation.acknowledge_form} />
                        </div>
                    </> :
                        <div style={{ width: "100%" }}>
                            <TextInput id="remarks" name="remarks" value={incomeInformation.remarks} placeholder="Remarks" type="text" onChangeHandler={handleIncomeData} size="extra_large" />
                        </div>}

                    {/* <div style={{width: "100%"}}>
                        <TextInput id="comment" name="payment_terms" placeholder="Payment Terms" type="text" onChangeHandler={handleIncomeData} size="extra_large"/>
                    </div> */}
                </div>
            </div>

            <div className="inline-form" style={{ margin: "1rem" }}>
                <ButtonOulined name="Cancel" size="medium" onClickHandler={() => navigate(-1)} />
                {isCreate ? <CircularProgress size={27} /> : <FilledRoundedButton name="Save" color="green" size="medium"
                    onClickHandler={state.type === "update" ? updatePaymentHandler : savePaymentHandler} />}
            </div>
        </div>
    )
}

export default AddIncome