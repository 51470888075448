import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import ConsultantInvoiceForm from '../../actions/ConsultantInvoiceForm';
import CustomerInvoiceForm from '../../actions/CustomerInvoiceForm';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Invoices() {
    const [value, setValue] = useState(() => {
        const savedValue = localStorage.getItem('currentTab');
        return savedValue ? parseInt(savedValue, 10) : 0;
    });

    useEffect(() => {
        localStorage.setItem('currentTab', value);
    }, [value]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>

            <div className="layout-title">
                <p style={{ marginTop: '1rem', marginBottom: '.5rem' }}>Invoice</p>
            </div>

            <Tabs value={value} onChange={handleChange} aria-label="invoice tabs">
                <Tab label="Consultant" {...a11yProps(0)} />
                <Tab label="Customer" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                {/* Invoice Form 1 */}
                <div>
                    <ConsultantInvoiceForm></ConsultantInvoiceForm>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                {/* Invoice Form 2 */}
                <div>
                    <CustomerInvoiceForm></CustomerInvoiceForm>
                </div>
            </TabPanel>
        </div>
    );
}

export default Invoices;
