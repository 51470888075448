import React, {useState, useEffect} from 'react'
import { FiBell, FiLogOut, FiSettings } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { LOGOUT } from '../../actions/types'
import { useNavigate } from 'react-router-dom'
import ButtonOulined from './../../components/Button/ButtonOulined'
import './navbar.css'
import LogoutButton from '../../components/Button/LogoutButton'

function Navbar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userLogout = () => {
    dispatch({
      type: LOGOUT,
    })
    navigate('/login')
  }
  return (
    <nav className="navbar-body">
        <div className="navbar">

            {/* <FiBell className="nav-item" size="20"/>
            <FiSettings className="nav-item" size="20"/> */}
            <div className="nav-item">
              <FiLogOut/>
              <LogoutButton name="Logout" onClickHandler={userLogout}/>
            </div>
            {/* <button className="nav-item">Logout</button> */}
        </div>
    </nav>
  )
}

export default Navbar